/*
 * This CSS file is a copy of `@gaiads/telia-react-component-library/build/index.css`
 * without obsolete embedded `@font-face` rules. Those rules accounted for ≈85% of the
 * bundled `main.css` file in dev at time of writing (2,487,908 bytes → 384,245 bytes),
 * severely delaying first contentful paint, increasing largest contentful paint, and
 * increasing cumulative layout shift browser application performance metrics (7 Jan 2025).
 */

.telia-react-flex {
  display: flex;
  flex-direction: row; }
  .telia-react-flex__occupyVerticalSpace {
    height: 100%; }
  .telia-react-flex__centeredVertically {
    align-items: center; }
  .telia-react-flex__centeredHorizontally {
    justify-content: center; }
  .telia-react-flex__alignTop {
    align-items: flex-start; }
  .telia-react-flex__alignBottom {
    align-items: flex-end; }
  .telia-react-flex__alignBaseline {
    align-items: baseline; }
  .telia-react-flex__alignRight {
    justify-content: flex-end; }
  .telia-react-flex__spaceBetween {
    justify-content: space-between; }
  .telia-react-flex__wrapContent {
    flex-wrap: wrap; }
  .telia-react-flex__stretchToSameHeight {
    align-items: stretch; }
  .telia-react-flex__reverseDirection {
    flex-direction: row-reverse; }
  .telia-react-flex__directionColumn {
    flex-direction: column; }
  @media (max-width: 375px) {
    .telia-react-flex__pileVerticallyToXxxsScreen {
      display: block; } }
  @media (max-width: 480px) {
    .telia-react-flex__pileVerticallyToXxsScreen {
      display: block; } }
  @media (max-width: 600px) {
    .telia-react-flex__pileVerticallyToXsScreen {
      display: block; } }
  @media (max-width: 840px) {
    .telia-react-flex__pileVerticallyToSmScreen {
      display: block; } }
  @media (max-width: 1080px) {
    .telia-react-flex__pileVerticallyToMdScreen {
      display: block; } }
  @media (max-width: 1440px) {
    .telia-react-flex__pileVerticallyToLgScreen {
      display: block; } }
  @media (max-width: 1920px) {
    .telia-react-flex__pileVerticallyToXlgScreen {
      display: block; } }
  .telia-react-flex_flexItem {
    min-width: 1px;
    max-width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%; }
  @media (max-width: 375px) {
    .telia-react-flex__pileVerticallyToXxxsScreen > .telia-react-flex_flexItem {
      width: 100%;
      flex: none; } }
  @media (max-width: 480px) {
    .telia-react-flex__pileVerticallyToXxsScreen > .telia-react-flex_flexItem {
      width: 100%;
      flex: none; } }
  @media (max-width: 600px) {
    .telia-react-flex__pileVerticallyToXsScreen > .telia-react-flex_flexItem {
      width: 100%;
      flex: none; } }
  @media (max-width: 840px) {
    .telia-react-flex__pileVerticallyToSmScreen > .telia-react-flex_flexItem {
      width: 100%;
      flex: none; } }
  @media (max-width: 1080px) {
    .telia-react-flex__pileVerticallyToMdScreen > .telia-react-flex_flexItem {
      width: 100%;
      flex: none; } }
  @media (max-width: 1440px) {
    .telia-react-flex__pileVerticallyToLgScreen > .telia-react-flex_flexItem {
      width: 100%;
      flex: none; } }
  @media (max-width: 1920px) {
    .telia-react-flex__pileVerticallyToXlgScreen > .telia-react-flex_flexItem {
      width: 100%;
      flex: none; } }

.telia-react-color__white {
  color: #ffffff; }

.telia-react-color__whiteHoverable:hover, .telia-react-color__whiteHoverable:active {
  color: #ffffff; }

.telia-react-color_background__white {
  background-color: #ffffff; }

.telia-react-color_background__whiteHoverable:hover, .telia-react-color_background__whiteHoverable:active {
  background-color: #ffffff; }

.telia-react-color__black {
  color: #222222; }

.telia-react-color__blackHoverable:hover, .telia-react-color__blackHoverable:active {
  color: #222222; }

.telia-react-color_background__black {
  background-color: #222222; }

.telia-react-color_background__blackHoverable:hover, .telia-react-color_background__blackHoverable:active {
  background-color: #222222; }

.telia-react-color__overlay {
  color: rgba(255, 255, 255, 0.6); }

.telia-react-color__overlayHoverable:hover, .telia-react-color__overlayHoverable:active {
  color: rgba(255, 255, 255, 0.6); }

.telia-react-color_background__overlay {
  background-color: rgba(255, 255, 255, 0.6); }

.telia-react-color_background__overlayHoverable:hover, .telia-react-color_background__overlayHoverable:active {
  background-color: rgba(255, 255, 255, 0.6); }

.telia-react-color__purple100 {
  color: #fbf4ff; }

.telia-react-color__purple100Hoverable:hover, .telia-react-color__purple100Hoverable:active {
  color: #fbf4ff; }

.telia-react-color_background__purple100 {
  background-color: #fbf4ff; }

.telia-react-color_background__purple100Hoverable:hover, .telia-react-color_background__purple100Hoverable:active {
  background-color: #fbf4ff; }

.telia-react-color__purple200 {
  color: #edc8ff; }

.telia-react-color__purple200Hoverable:hover, .telia-react-color__purple200Hoverable:active {
  color: #edc8ff; }

.telia-react-color_background__purple200 {
  background-color: #edc8ff; }

.telia-react-color_background__purple200Hoverable:hover, .telia-react-color_background__purple200Hoverable:active {
  background-color: #edc8ff; }

.telia-react-color__purple300 {
  color: #de99ff; }

.telia-react-color__purple300Hoverable:hover, .telia-react-color__purple300Hoverable:active {
  color: #de99ff; }

.telia-react-color_background__purple300 {
  background-color: #de99ff; }

.telia-react-color_background__purple300Hoverable:hover, .telia-react-color_background__purple300Hoverable:active {
  background-color: #de99ff; }

.telia-react-color__purple400 {
  color: #c158f8; }

.telia-react-color__purple400Hoverable:hover, .telia-react-color__purple400Hoverable:active {
  color: #c158f8; }

.telia-react-color_background__purple400 {
  background-color: #c158f8; }

.telia-react-color_background__purple400Hoverable:hover, .telia-react-color_background__purple400Hoverable:active {
  background-color: #c158f8; }

.telia-react-color__purple500 {
  color: #990ae3; }

.telia-react-color__purple500Hoverable:hover, .telia-react-color__purple500Hoverable:active {
  color: #990ae3; }

.telia-react-color_background__purple500 {
  background-color: #990ae3; }

.telia-react-color_background__purple500Hoverable:hover, .telia-react-color_background__purple500Hoverable:active {
  background-color: #990ae3; }

.telia-react-color__purple600 {
  color: #7d00bd; }

.telia-react-color__purple600Hoverable:hover, .telia-react-color__purple600Hoverable:active {
  color: #7d00bd; }

.telia-react-color_background__purple600 {
  background-color: #7d00bd; }

.telia-react-color_background__purple600Hoverable:hover, .telia-react-color_background__purple600Hoverable:active {
  background-color: #7d00bd; }

.telia-react-color__purple700 {
  color: #5f008f; }

.telia-react-color__purple700Hoverable:hover, .telia-react-color__purple700Hoverable:active {
  color: #5f008f; }

.telia-react-color_background__purple700 {
  background-color: #5f008f; }

.telia-react-color_background__purple700Hoverable:hover, .telia-react-color_background__purple700Hoverable:active {
  background-color: #5f008f; }

.telia-react-color__purple800 {
  color: #3f005e; }

.telia-react-color__purple800Hoverable:hover, .telia-react-color__purple800Hoverable:active {
  color: #3f005e; }

.telia-react-color_background__purple800 {
  background-color: #3f005e; }

.telia-react-color_background__purple800Hoverable:hover, .telia-react-color_background__purple800Hoverable:active {
  background-color: #3f005e; }

.telia-react-color__purple900 {
  color: #1f002e; }

.telia-react-color__purple900Hoverable:hover, .telia-react-color__purple900Hoverable:active {
  color: #1f002e; }

.telia-react-color_background__purple900 {
  background-color: #1f002e; }

.telia-react-color_background__purple900Hoverable:hover, .telia-react-color_background__purple900Hoverable:active {
  background-color: #1f002e; }

.telia-react-color__gray100 {
  color: #fbfbfb; }

.telia-react-color__gray100Hoverable:hover, .telia-react-color__gray100Hoverable:active {
  color: #fbfbfb; }

.telia-react-color_background__gray100 {
  background-color: #fbfbfb; }

.telia-react-color_background__gray100Hoverable:hover, .telia-react-color_background__gray100Hoverable:active {
  background-color: #fbfbfb; }

.telia-react-color__gray200 {
  color: #f2f2f2; }

.telia-react-color__gray200Hoverable:hover, .telia-react-color__gray200Hoverable:active {
  color: #f2f2f2; }

.telia-react-color_background__gray200 {
  background-color: #f2f2f2; }

.telia-react-color_background__gray200Hoverable:hover, .telia-react-color_background__gray200Hoverable:active {
  background-color: #f2f2f2; }

.telia-react-color__gray300 {
  color: #cccccc; }

.telia-react-color__gray300Hoverable:hover, .telia-react-color__gray300Hoverable:active {
  color: #cccccc; }

.telia-react-color_background__gray300 {
  background-color: #cccccc; }

.telia-react-color_background__gray300Hoverable:hover, .telia-react-color_background__gray300Hoverable:active {
  background-color: #cccccc; }

.telia-react-color__gray400 {
  color: #a0a0a0; }

.telia-react-color__gray400Hoverable:hover, .telia-react-color__gray400Hoverable:active {
  color: #a0a0a0; }

.telia-react-color_background__gray400 {
  background-color: #a0a0a0; }

.telia-react-color_background__gray400Hoverable:hover, .telia-react-color_background__gray400Hoverable:active {
  background-color: #a0a0a0; }

.telia-react-color__gray500 {
  color: #6e6e6e; }

.telia-react-color__gray500Hoverable:hover, .telia-react-color__gray500Hoverable:active {
  color: #6e6e6e; }

.telia-react-color_background__gray500 {
  background-color: #6e6e6e; }

.telia-react-color_background__gray500Hoverable:hover, .telia-react-color_background__gray500Hoverable:active {
  background-color: #6e6e6e; }

.telia-react-color__gray600 {
  color: #5e5e5e; }

.telia-react-color__gray600Hoverable:hover, .telia-react-color__gray600Hoverable:active {
  color: #5e5e5e; }

.telia-react-color_background__gray600 {
  background-color: #5e5e5e; }

.telia-react-color_background__gray600Hoverable:hover, .telia-react-color_background__gray600Hoverable:active {
  background-color: #5e5e5e; }

.telia-react-color__gray700 {
  color: #4e4e4e; }

.telia-react-color__gray700Hoverable:hover, .telia-react-color__gray700Hoverable:active {
  color: #4e4e4e; }

.telia-react-color_background__gray700 {
  background-color: #4e4e4e; }

.telia-react-color_background__gray700Hoverable:hover, .telia-react-color_background__gray700Hoverable:active {
  background-color: #4e4e4e; }

.telia-react-color__gray800 {
  color: #303030; }

.telia-react-color__gray800Hoverable:hover, .telia-react-color__gray800Hoverable:active {
  color: #303030; }

.telia-react-color_background__gray800 {
  background-color: #303030; }

.telia-react-color_background__gray800Hoverable:hover, .telia-react-color_background__gray800Hoverable:active {
  background-color: #303030; }

.telia-react-color__gray900 {
  color: #222222; }

.telia-react-color__gray900Hoverable:hover, .telia-react-color__gray900Hoverable:active {
  color: #222222; }

.telia-react-color_background__gray900 {
  background-color: #222222; }

.telia-react-color_background__gray900Hoverable:hover, .telia-react-color_background__gray900Hoverable:active {
  background-color: #222222; }

.telia-react-color__red100 {
  color: #fff4f7; }

.telia-react-color__red100Hoverable:hover, .telia-react-color__red100Hoverable:active {
  color: #fff4f7; }

.telia-react-color_background__red100 {
  background-color: #fff4f7; }

.telia-react-color_background__red100Hoverable:hover, .telia-react-color_background__red100Hoverable:active {
  background-color: #fff4f7; }

.telia-react-color__red200 {
  color: #ffccdd; }

.telia-react-color__red200Hoverable:hover, .telia-react-color__red200Hoverable:active {
  color: #ffccdd; }

.telia-react-color_background__red200 {
  background-color: #ffccdd; }

.telia-react-color_background__red200Hoverable:hover, .telia-react-color_background__red200Hoverable:active {
  background-color: #ffccdd; }

.telia-react-color__red300 {
  color: #ffa4c3; }

.telia-react-color__red300Hoverable:hover, .telia-react-color__red300Hoverable:active {
  color: #ffa4c3; }

.telia-react-color_background__red300 {
  background-color: #ffa4c3; }

.telia-react-color_background__red300Hoverable:hover, .telia-react-color_background__red300Hoverable:active {
  background-color: #ffa4c3; }

.telia-react-color__red400 {
  color: #f74f87; }

.telia-react-color__red400Hoverable:hover, .telia-react-color__red400Hoverable:active {
  color: #f74f87; }

.telia-react-color_background__red400 {
  background-color: #f74f87; }

.telia-react-color_background__red400Hoverable:hover, .telia-react-color_background__red400Hoverable:active {
  background-color: #f74f87; }

.telia-react-color__red500 {
  color: #e4175c; }

.telia-react-color__red500Hoverable:hover, .telia-react-color__red500Hoverable:active {
  color: #e4175c; }

.telia-react-color_background__red500 {
  background-color: #e4175c; }

.telia-react-color_background__red500Hoverable:hover, .telia-react-color_background__red500Hoverable:active {
  background-color: #e4175c; }

.telia-react-color__red600 {
  color: #be0040; }

.telia-react-color__red600Hoverable:hover, .telia-react-color__red600Hoverable:active {
  color: #be0040; }

.telia-react-color_background__red600 {
  background-color: #be0040; }

.telia-react-color_background__red600Hoverable:hover, .telia-react-color_background__red600Hoverable:active {
  background-color: #be0040; }

.telia-react-color__red700 {
  color: #900030; }

.telia-react-color__red700Hoverable:hover, .telia-react-color__red700Hoverable:active {
  color: #900030; }

.telia-react-color_background__red700 {
  background-color: #900030; }

.telia-react-color_background__red700Hoverable:hover, .telia-react-color_background__red700Hoverable:active {
  background-color: #900030; }

.telia-react-color__red800 {
  color: #5e0020; }

.telia-react-color__red800Hoverable:hover, .telia-react-color__red800Hoverable:active {
  color: #5e0020; }

.telia-react-color_background__red800 {
  background-color: #5e0020; }

.telia-react-color_background__red800Hoverable:hover, .telia-react-color_background__red800Hoverable:active {
  background-color: #5e0020; }

.telia-react-color__red900 {
  color: #2e000f; }

.telia-react-color__red900Hoverable:hover, .telia-react-color__red900Hoverable:active {
  color: #2e000f; }

.telia-react-color_background__red900 {
  background-color: #2e000f; }

.telia-react-color_background__red900Hoverable:hover, .telia-react-color_background__red900Hoverable:active {
  background-color: #2e000f; }

.telia-react-color__green100 {
  color: #f3fff9; }

.telia-react-color__green100Hoverable:hover, .telia-react-color__green100Hoverable:active {
  color: #f3fff9; }

.telia-react-color_background__green100 {
  background-color: #f3fff9; }

.telia-react-color_background__green100Hoverable:hover, .telia-react-color_background__green100Hoverable:active {
  background-color: #f3fff9; }

.telia-react-color__green200 {
  color: #c5f8de; }

.telia-react-color__green200Hoverable:hover, .telia-react-color__green200Hoverable:active {
  color: #c5f8de; }

.telia-react-color_background__green200 {
  background-color: #c5f8de; }

.telia-react-color_background__green200Hoverable:hover, .telia-react-color_background__green200Hoverable:active {
  background-color: #c5f8de; }

.telia-react-color__green300 {
  color: #95f4c3; }

.telia-react-color__green300Hoverable:hover, .telia-react-color__green300Hoverable:active {
  color: #95f4c3; }

.telia-react-color_background__green300 {
  background-color: #95f4c3; }

.telia-react-color_background__green300Hoverable:hover, .telia-react-color_background__green300Hoverable:active {
  background-color: #95f4c3; }

.telia-react-color__green400 {
  color: #48e494; }

.telia-react-color__green400Hoverable:hover, .telia-react-color__green400Hoverable:active {
  color: #48e494; }

.telia-react-color_background__green400 {
  background-color: #48e494; }

.telia-react-color_background__green400Hoverable:hover, .telia-react-color_background__green400Hoverable:active {
  background-color: #48e494; }

.telia-react-color__green500 {
  color: #00af56; }

.telia-react-color__green500Hoverable:hover, .telia-react-color__green500Hoverable:active {
  color: #00af56; }

.telia-react-color_background__green500 {
  background-color: #00af56; }

.telia-react-color_background__green500Hoverable:hover, .telia-react-color_background__green500Hoverable:active {
  background-color: #00af56; }

.telia-react-color__green600 {
  color: #00af56; }

.telia-react-color__green600Hoverable:hover, .telia-react-color__green600Hoverable:active {
  color: #00af56; }

.telia-react-color_background__green600 {
  background-color: #00af56; }

.telia-react-color_background__green600Hoverable:hover, .telia-react-color_background__green600Hoverable:active {
  background-color: #00af56; }

.telia-react-color__green700 {
  color: #008641; }

.telia-react-color__green700Hoverable:hover, .telia-react-color__green700Hoverable:active {
  color: #008641; }

.telia-react-color_background__green700 {
  background-color: #008641; }

.telia-react-color_background__green700Hoverable:hover, .telia-react-color_background__green700Hoverable:active {
  background-color: #008641; }

.telia-react-color__green800 {
  color: #00592b; }

.telia-react-color__green800Hoverable:hover, .telia-react-color__green800Hoverable:active {
  color: #00592b; }

.telia-react-color_background__green800 {
  background-color: #00592b; }

.telia-react-color_background__green800Hoverable:hover, .telia-react-color_background__green800Hoverable:active {
  background-color: #00592b; }

.telia-react-color__green900 {
  color: #002e16; }

.telia-react-color__green900Hoverable:hover, .telia-react-color__green900Hoverable:active {
  color: #002e16; }

.telia-react-color_background__green900 {
  background-color: #002e16; }

.telia-react-color_background__green900Hoverable:hover, .telia-react-color_background__green900Hoverable:active {
  background-color: #002e16; }

.telia-react-color__blue100 {
  color: #f4faff; }

.telia-react-color__blue100Hoverable:hover, .telia-react-color__blue100Hoverable:active {
  color: #f4faff; }

.telia-react-color_background__blue100 {
  background-color: #f4faff; }

.telia-react-color_background__blue100Hoverable:hover, .telia-react-color_background__blue100Hoverable:active {
  background-color: #f4faff; }

.telia-react-color__blue200 {
  color: #cbefff; }

.telia-react-color__blue200Hoverable:hover, .telia-react-color__blue200Hoverable:active {
  color: #cbefff; }

.telia-react-color_background__blue200 {
  background-color: #cbefff; }

.telia-react-color_background__blue200Hoverable:hover, .telia-react-color_background__blue200Hoverable:active {
  background-color: #cbefff; }

.telia-react-color__blue300 {
  color: #9fe2ff; }

.telia-react-color__blue300Hoverable:hover, .telia-react-color__blue300Hoverable:active {
  color: #9fe2ff; }

.telia-react-color_background__blue300 {
  background-color: #9fe2ff; }

.telia-react-color_background__blue300Hoverable:hover, .telia-react-color_background__blue300Hoverable:active {
  background-color: #9fe2ff; }

.telia-react-color__blue400 {
  color: #4dc0ff; }

.telia-react-color__blue400Hoverable:hover, .telia-react-color__blue400Hoverable:active {
  color: #4dc0ff; }

.telia-react-color_background__blue400 {
  background-color: #4dc0ff; }

.telia-react-color_background__blue400Hoverable:hover, .telia-react-color_background__blue400Hoverable:active {
  background-color: #4dc0ff; }

.telia-react-color__blue500 {
  color: #0099ff; }

.telia-react-color__blue500Hoverable:hover, .telia-react-color__blue500Hoverable:active {
  color: #0099ff; }

.telia-react-color_background__blue500 {
  background-color: #0099ff; }

.telia-react-color_background__blue500Hoverable:hover, .telia-react-color_background__blue500Hoverable:active {
  background-color: #0099ff; }

.telia-react-color__blue600 {
  color: #007acc; }

.telia-react-color__blue600Hoverable:hover, .telia-react-color__blue600Hoverable:active {
  color: #007acc; }

.telia-react-color_background__blue600 {
  background-color: #007acc; }

.telia-react-color_background__blue600Hoverable:hover, .telia-react-color_background__blue600Hoverable:active {
  background-color: #007acc; }

.telia-react-color__blue700 {
  color: #005f9e; }

.telia-react-color__blue700Hoverable:hover, .telia-react-color__blue700Hoverable:active {
  color: #005f9e; }

.telia-react-color_background__blue700 {
  background-color: #005f9e; }

.telia-react-color_background__blue700Hoverable:hover, .telia-react-color_background__blue700Hoverable:active {
  background-color: #005f9e; }

.telia-react-color__blue800 {
  color: #003d65; }

.telia-react-color__blue800Hoverable:hover, .telia-react-color__blue800Hoverable:active {
  color: #003d65; }

.telia-react-color_background__blue800 {
  background-color: #003d65; }

.telia-react-color_background__blue800Hoverable:hover, .telia-react-color_background__blue800Hoverable:active {
  background-color: #003d65; }

.telia-react-color__blue900 {
  color: #001c2e; }

.telia-react-color__blue900Hoverable:hover, .telia-react-color__blue900Hoverable:active {
  color: #001c2e; }

.telia-react-color_background__blue900 {
  background-color: #001c2e; }

.telia-react-color_background__blue900Hoverable:hover, .telia-react-color_background__blue900Hoverable:active {
  background-color: #001c2e; }

.telia-react-color__orange100 {
  color: #fffaf4; }

.telia-react-color__orange100Hoverable:hover, .telia-react-color__orange100Hoverable:active {
  color: #fffaf4; }

.telia-react-color_background__orange100 {
  background-color: #fffaf4; }

.telia-react-color_background__orange100Hoverable:hover, .telia-react-color_background__orange100Hoverable:active {
  background-color: #fffaf4; }

.telia-react-color__orange200 {
  color: #ffefcb; }

.telia-react-color__orange200Hoverable:hover, .telia-react-color__orange200Hoverable:active {
  color: #ffefcb; }

.telia-react-color_background__orange200 {
  background-color: #ffefcb; }

.telia-react-color_background__orange200Hoverable:hover, .telia-react-color_background__orange200Hoverable:active {
  background-color: #ffefcb; }

.telia-react-color__orange300 {
  color: #ffe39f; }

.telia-react-color__orange300Hoverable:hover, .telia-react-color__orange300Hoverable:active {
  color: #ffe39f; }

.telia-react-color_background__orange300 {
  background-color: #ffe39f; }

.telia-react-color_background__orange300Hoverable:hover, .telia-react-color_background__orange300Hoverable:active {
  background-color: #ffe39f; }

.telia-react-color__orange400 {
  color: #ffc14d; }

.telia-react-color__orange400Hoverable:hover, .telia-react-color__orange400Hoverable:active {
  color: #ffc14d; }

.telia-react-color_background__orange400 {
  background-color: #ffc14d; }

.telia-react-color_background__orange400Hoverable:hover, .telia-react-color_background__orange400Hoverable:active {
  background-color: #ffc14d; }

.telia-react-color__orange500 {
  color: #ff9b00; }

.telia-react-color__orange500Hoverable:hover, .telia-react-color__orange500Hoverable:active {
  color: #ff9b00; }

.telia-react-color_background__orange500 {
  background-color: #ff9b00; }

.telia-react-color_background__orange500Hoverable:hover, .telia-react-color_background__orange500Hoverable:active {
  background-color: #ff9b00; }

.telia-react-color__orange600 {
  color: #d27f00; }

.telia-react-color__orange600Hoverable:hover, .telia-react-color__orange600Hoverable:active {
  color: #d27f00; }

.telia-react-color_background__orange600 {
  background-color: #d27f00; }

.telia-react-color_background__orange600Hoverable:hover, .telia-react-color_background__orange600Hoverable:active {
  background-color: #d27f00; }

.telia-react-color__orange700 {
  color: #a86600; }

.telia-react-color__orange700Hoverable:hover, .telia-react-color__orange700Hoverable:active {
  color: #a86600; }

.telia-react-color_background__orange700 {
  background-color: #a86600; }

.telia-react-color_background__orange700Hoverable:hover, .telia-react-color_background__orange700Hoverable:active {
  background-color: #a86600; }

.telia-react-color__orange800 {
  color: #6b4100; }

.telia-react-color__orange800Hoverable:hover, .telia-react-color__orange800Hoverable:active {
  color: #6b4100; }

.telia-react-color_background__orange800 {
  background-color: #6b4100; }

.telia-react-color_background__orange800Hoverable:hover, .telia-react-color_background__orange800Hoverable:active {
  background-color: #6b4100; }

.telia-react-color__orange900 {
  color: #2e1c00; }

.telia-react-color__orange900Hoverable:hover, .telia-react-color__orange900Hoverable:active {
  color: #2e1c00; }

.telia-react-color_background__orange900 {
  background-color: #2e1c00; }

.telia-react-color_background__orange900Hoverable:hover, .telia-react-color_background__orange900Hoverable:active {
  background-color: #2e1c00; }

.telia-react-color__inherit {
  color: inherit; }

.telia-react-color__transparent {
  color: transparent; }

.telia-react-color__inheritHoverable:hover, .telia-react-color__inheritHoverable:active {
  color: inherit; }

.telia-react-color_background__transparent {
  background-color: transparent; }

.telia-react-element {
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  box-sizing: border-box; }
  .telia-react-element__hidden {
    display: none !important; }
  .telia-react-element__hideOverflow {
    overflow: hidden; }
  .telia-react-element__showOverflow {
    overflow: visible; }
  .telia-react-element__forceWrap {
    overflow: hidden;
    word-wrap: break-word;
    display: inline-block;
    max-width: 100%; }
  .telia-react-element__inlineBlock {
    display: inline-block; }
  .telia-react-element__block {
    display: block; }
  .telia-react-element__alignSelfCenter {
    align-self: center; }
  .telia-react-element__boxShadow__zero {
    box-shadow: unset; }
  .telia-react-element__boxShadow__sm {
    box-shadow: 0 2px 8px 0 rgba(58, 58, 58, 0.12); }
  .telia-react-element__boxShadow__md {
    box-shadow: 0 6px 24px 0 rgba(58, 58, 58, 0.12); }
  .telia-react-element__boxShadow__lg {
    box-shadow: 0 8px 32px 0 rgba(58, 58, 58, 0.18); }
  .telia-react-element_boxShadowHoverable__zero:hover {
    box-shadow: unset; }
  .telia-react-element_boxShadowHoverable__sm:hover {
    box-shadow: 0 2px 8px 0 rgba(58, 58, 58, 0.12); }
  .telia-react-element_boxShadowHoverable__md:hover {
    box-shadow: 0 6px 24px 0 rgba(58, 58, 58, 0.12); }
  .telia-react-element_boxShadowHoverable__lg:hover {
    box-shadow: 0 8px 32px 0 rgba(58, 58, 58, 0.18); }
  .telia-react-element__occupyVerticalSpace {
    height: 100%; }
  .telia-react-element__occupyHorizontalSpace {
    width: 100%; }
  .telia-react-element__alignTextToCenter {
    text-align: center; }
  .telia-react-element__alignTextToRight {
    text-align: right; }
  .telia-react-element__clickableAppearance {
    cursor: pointer; }
  .telia-react-element__alignSelfToBottom {
    align-self: flex-end; }
  .telia-react-element__fontWeight__normal {
    font-weight: normal; }
  .telia-react-element__fontWeight__medium {
    font-weight: 500; }
  .telia-react-element__fontWeight__bold {
    font-weight: bold; }

.telia-react-element_borderRadius__topLeftZero {
  border-top-left-radius: 0; }

.telia-react-element_borderRadius__topLeftXs {
  border-top-left-radius: 2px; }

.telia-react-element_borderRadius__topLeftSm {
  border-top-left-radius: 4px; }

.telia-react-element_borderRadius__topLeftMd {
  border-top-left-radius: 8px; }

.telia-react-element_borderRadius__topLeftLg {
  border-top-left-radius: 16px; }

.telia-react-element_borderRadius__topLeftRounded {
  border-top-left-radius: 999px; }

.telia-react-element_borderRadius__topRightZero {
  border-top-right-radius: 0; }

.telia-react-element_borderRadius__topRightXs {
  border-top-right-radius: 2px; }

.telia-react-element_borderRadius__topRightSm {
  border-top-right-radius: 4px; }

.telia-react-element_borderRadius__topRightMd {
  border-top-right-radius: 8px; }

.telia-react-element_borderRadius__topRightLg {
  border-top-right-radius: 16px; }

.telia-react-element_borderRadius__topRightRounded {
  border-top-right-radius: 999px; }

.telia-react-element_borderRadius__bottomRightZero {
  border-bottom-right-radius: 0; }

.telia-react-element_borderRadius__bottomRightXs {
  border-bottom-right-radius: 2px; }

.telia-react-element_borderRadius__bottomRightSm {
  border-bottom-right-radius: 4px; }

.telia-react-element_borderRadius__bottomRightMd {
  border-bottom-right-radius: 8px; }

.telia-react-element_borderRadius__bottomRightLg {
  border-bottom-right-radius: 16px; }

.telia-react-element_borderRadius__bottomRightRounded {
  border-bottom-right-radius: 999px; }

.telia-react-element_borderRadius__bottomLeftZero {
  border-bottom-left-radius: 0; }

.telia-react-element_borderRadius__bottomLeftXs {
  border-bottom-left-radius: 2px; }

.telia-react-element_borderRadius__bottomLeftSm {
  border-bottom-left-radius: 4px; }

.telia-react-element_borderRadius__bottomLeftMd {
  border-bottom-left-radius: 8px; }

.telia-react-element_borderRadius__bottomLeftLg {
  border-bottom-left-radius: 16px; }

.telia-react-element_borderRadius__bottomLeftRounded {
  border-bottom-left-radius: 999px; }

.telia-react-margin__sizeZeroLeft {
  margin-left: 0; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeZeroLeft {
      margin-left: 0; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeZeroLeft {
      margin-left: 0; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeZeroLeft {
      margin-left: 0; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeZeroLeft {
      margin-left: 0; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeZeroLeft {
      margin-left: 0; } }

.telia-react-margin__sizeZeroRight {
  margin-right: 0; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeZeroRight {
      margin-right: 0; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeZeroRight {
      margin-right: 0; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeZeroRight {
      margin-right: 0; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeZeroRight {
      margin-right: 0; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeZeroRight {
      margin-right: 0; } }

.telia-react-margin__sizeZeroTop {
  margin-top: 0; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeZeroTop {
      margin-top: 0; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeZeroTop {
      margin-top: 0; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeZeroTop {
      margin-top: 0; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeZeroTop {
      margin-top: 0; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeZeroTop {
      margin-top: 0; } }

.telia-react-margin__sizeZeroBottom {
  margin-bottom: 0; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeZeroBottom {
      margin-bottom: 0; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeZeroBottom {
      margin-bottom: 0; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeZeroBottom {
      margin-bottom: 0; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeZeroBottom {
      margin-bottom: 0; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeZeroBottom {
      margin-bottom: 0; } }

.telia-react-margin__sizeXxxsLeft {
  margin-left: 2px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxxsLeft {
      margin-left: 2px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxxsLeft {
      margin-left: 2px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxxsLeft {
      margin-left: 2px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxxsLeft {
      margin-left: 2px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxxsLeft {
      margin-left: 2px; } }

.telia-react-margin__sizeXxxsRight {
  margin-right: 2px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxxsRight {
      margin-right: 2px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxxsRight {
      margin-right: 2px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxxsRight {
      margin-right: 2px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxxsRight {
      margin-right: 2px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxxsRight {
      margin-right: 2px; } }

.telia-react-margin__sizeXxxsTop {
  margin-top: 2px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxxsTop {
      margin-top: 2px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxxsTop {
      margin-top: 2px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxxsTop {
      margin-top: 2px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxxsTop {
      margin-top: 2px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxxsTop {
      margin-top: 2px; } }

.telia-react-margin__sizeXxxsBottom {
  margin-bottom: 2px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxxsBottom {
      margin-bottom: 2px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxxsBottom {
      margin-bottom: 2px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxxsBottom {
      margin-bottom: 2px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxxsBottom {
      margin-bottom: 2px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxxsBottom {
      margin-bottom: 2px; } }

.telia-react-margin__sizeXxsLeft {
  margin-left: 4px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxsLeft {
      margin-left: 4px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxsLeft {
      margin-left: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxsLeft {
      margin-left: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxsLeft {
      margin-left: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxsLeft {
      margin-left: 4px; } }

.telia-react-margin__sizeXxsRight {
  margin-right: 4px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxsRight {
      margin-right: 4px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxsRight {
      margin-right: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxsRight {
      margin-right: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxsRight {
      margin-right: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxsRight {
      margin-right: 4px; } }

.telia-react-margin__sizeXxsTop {
  margin-top: 4px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxsTop {
      margin-top: 4px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxsTop {
      margin-top: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxsTop {
      margin-top: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxsTop {
      margin-top: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxsTop {
      margin-top: 4px; } }

.telia-react-margin__sizeXxsBottom {
  margin-bottom: 4px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxsBottom {
      margin-bottom: 4px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxsBottom {
      margin-bottom: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxsBottom {
      margin-bottom: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxsBottom {
      margin-bottom: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxsBottom {
      margin-bottom: 4px; } }

.telia-react-margin__sizeXsLeft {
  margin-left: 8px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXsLeft {
      margin-left: 8px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXsLeft {
      margin-left: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXsLeft {
      margin-left: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXsLeft {
      margin-left: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXsLeft {
      margin-left: 8px; } }

.telia-react-margin__sizeXsRight {
  margin-right: 8px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXsRight {
      margin-right: 8px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXsRight {
      margin-right: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXsRight {
      margin-right: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXsRight {
      margin-right: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXsRight {
      margin-right: 8px; } }

.telia-react-margin__sizeXsTop {
  margin-top: 8px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXsTop {
      margin-top: 8px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXsTop {
      margin-top: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXsTop {
      margin-top: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXsTop {
      margin-top: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXsTop {
      margin-top: 8px; } }

.telia-react-margin__sizeXsBottom {
  margin-bottom: 8px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXsBottom {
      margin-bottom: 8px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXsBottom {
      margin-bottom: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXsBottom {
      margin-bottom: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXsBottom {
      margin-bottom: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXsBottom {
      margin-bottom: 8px; } }

.telia-react-margin__sizeSmLeft {
  margin-left: 16px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeSmLeft {
      margin-left: 16px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeSmLeft {
      margin-left: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeSmLeft {
      margin-left: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeSmLeft {
      margin-left: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeSmLeft {
      margin-left: 16px; } }

.telia-react-margin__sizeSmRight {
  margin-right: 16px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeSmRight {
      margin-right: 16px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeSmRight {
      margin-right: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeSmRight {
      margin-right: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeSmRight {
      margin-right: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeSmRight {
      margin-right: 16px; } }

.telia-react-margin__sizeSmTop {
  margin-top: 16px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeSmTop {
      margin-top: 16px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeSmTop {
      margin-top: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeSmTop {
      margin-top: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeSmTop {
      margin-top: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeSmTop {
      margin-top: 16px; } }

.telia-react-margin__sizeSmBottom {
  margin-bottom: 16px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeSmBottom {
      margin-bottom: 16px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeSmBottom {
      margin-bottom: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeSmBottom {
      margin-bottom: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeSmBottom {
      margin-bottom: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeSmBottom {
      margin-bottom: 16px; } }

.telia-react-margin__sizeMdLeft {
  margin-left: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeMdLeft {
      margin-left: 24px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeMdLeft {
      margin-left: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeMdLeft {
      margin-left: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeMdLeft {
      margin-left: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeMdLeft {
      margin-left: 24px; } }

.telia-react-margin__sizeMdRight {
  margin-right: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeMdRight {
      margin-right: 24px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeMdRight {
      margin-right: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeMdRight {
      margin-right: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeMdRight {
      margin-right: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeMdRight {
      margin-right: 24px; } }

.telia-react-margin__sizeMdTop {
  margin-top: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeMdTop {
      margin-top: 24px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeMdTop {
      margin-top: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeMdTop {
      margin-top: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeMdTop {
      margin-top: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeMdTop {
      margin-top: 24px; } }

.telia-react-margin__sizeMdBottom {
  margin-bottom: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeMdBottom {
      margin-bottom: 24px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeMdBottom {
      margin-bottom: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeMdBottom {
      margin-bottom: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeMdBottom {
      margin-bottom: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeMdBottom {
      margin-bottom: 24px; } }

.telia-react-margin__sizeLgLeft {
  margin-left: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeLgLeft {
      margin-left: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeLgLeft {
      margin-left: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeLgLeft {
      margin-left: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeLgLeft {
      margin-left: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeLgLeft {
      margin-left: 32px; } }

.telia-react-margin__sizeLgRight {
  margin-right: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeLgRight {
      margin-right: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeLgRight {
      margin-right: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeLgRight {
      margin-right: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeLgRight {
      margin-right: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeLgRight {
      margin-right: 32px; } }

.telia-react-margin__sizeLgTop {
  margin-top: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeLgTop {
      margin-top: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeLgTop {
      margin-top: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeLgTop {
      margin-top: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeLgTop {
      margin-top: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeLgTop {
      margin-top: 32px; } }

.telia-react-margin__sizeLgBottom {
  margin-bottom: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeLgBottom {
      margin-bottom: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeLgBottom {
      margin-bottom: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeLgBottom {
      margin-bottom: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeLgBottom {
      margin-bottom: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeLgBottom {
      margin-bottom: 32px; } }

.telia-react-margin__sizeXlgLeft {
  margin-left: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXlgLeft {
      margin-left: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXlgLeft {
      margin-left: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXlgLeft {
      margin-left: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXlgLeft {
      margin-left: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXlgLeft {
      margin-left: 48px; } }

.telia-react-margin__sizeXlgRight {
  margin-right: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXlgRight {
      margin-right: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXlgRight {
      margin-right: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXlgRight {
      margin-right: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXlgRight {
      margin-right: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXlgRight {
      margin-right: 48px; } }

.telia-react-margin__sizeXlgTop {
  margin-top: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXlgTop {
      margin-top: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXlgTop {
      margin-top: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXlgTop {
      margin-top: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXlgTop {
      margin-top: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXlgTop {
      margin-top: 48px; } }

.telia-react-margin__sizeXlgBottom {
  margin-bottom: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXlgBottom {
      margin-bottom: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXlgBottom {
      margin-bottom: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXlgBottom {
      margin-bottom: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXlgBottom {
      margin-bottom: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXlgBottom {
      margin-bottom: 48px; } }

.telia-react-margin__sizeXxlgLeft {
  margin-left: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxlgLeft {
      margin-left: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxlgLeft {
      margin-left: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxlgLeft {
      margin-left: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxlgLeft {
      margin-left: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxlgLeft {
      margin-left: 96px; } }

.telia-react-margin__sizeXxlgRight {
  margin-right: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxlgRight {
      margin-right: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxlgRight {
      margin-right: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxlgRight {
      margin-right: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxlgRight {
      margin-right: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxlgRight {
      margin-right: 96px; } }

.telia-react-margin__sizeXxlgTop {
  margin-top: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxlgTop {
      margin-top: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxlgTop {
      margin-top: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxlgTop {
      margin-top: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxlgTop {
      margin-top: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxlgTop {
      margin-top: 96px; } }

.telia-react-margin__sizeXxlgBottom {
  margin-bottom: 24px; }
  @media (min-width: 601px) {
    .telia-react-margin__sizeXxlgBottom {
      margin-bottom: 32px; } }
  @media (min-width: 841px) {
    .telia-react-margin__sizeXxlgBottom {
      margin-bottom: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-margin__sizeXxlgBottom {
      margin-bottom: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-margin__sizeXxlgBottom {
      margin-bottom: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-margin__sizeXxlgBottom {
      margin-bottom: 96px; } }

.telia-react-margin__lastChildSizeZeroBottom:last-child {
  margin-bottom: 0; }

.telia-react-padding__sizeZeroLeft {
  padding-left: 0; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeZeroLeft {
      padding-left: 0; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeZeroLeft {
      padding-left: 0; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeZeroLeft {
      padding-left: 0; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeZeroLeft {
      padding-left: 0; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeZeroLeft {
      padding-left: 0; } }

.telia-react-padding__sizeZeroRight {
  padding-right: 0; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeZeroRight {
      padding-right: 0; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeZeroRight {
      padding-right: 0; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeZeroRight {
      padding-right: 0; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeZeroRight {
      padding-right: 0; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeZeroRight {
      padding-right: 0; } }

.telia-react-padding__sizeZeroTop {
  padding-top: 0; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeZeroTop {
      padding-top: 0; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeZeroTop {
      padding-top: 0; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeZeroTop {
      padding-top: 0; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeZeroTop {
      padding-top: 0; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeZeroTop {
      padding-top: 0; } }

.telia-react-padding__sizeZeroBottom {
  padding-bottom: 0; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeZeroBottom {
      padding-bottom: 0; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeZeroBottom {
      padding-bottom: 0; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeZeroBottom {
      padding-bottom: 0; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeZeroBottom {
      padding-bottom: 0; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeZeroBottom {
      padding-bottom: 0; } }

.telia-react-padding__sizeXxxsLeft {
  padding-left: 2px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxxsLeft {
      padding-left: 2px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxxsLeft {
      padding-left: 2px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxxsLeft {
      padding-left: 2px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxxsLeft {
      padding-left: 2px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxxsLeft {
      padding-left: 2px; } }

.telia-react-padding__sizeXxxsRight {
  padding-right: 2px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxxsRight {
      padding-right: 2px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxxsRight {
      padding-right: 2px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxxsRight {
      padding-right: 2px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxxsRight {
      padding-right: 2px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxxsRight {
      padding-right: 2px; } }

.telia-react-padding__sizeXxxsTop {
  padding-top: 2px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxxsTop {
      padding-top: 2px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxxsTop {
      padding-top: 2px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxxsTop {
      padding-top: 2px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxxsTop {
      padding-top: 2px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxxsTop {
      padding-top: 2px; } }

.telia-react-padding__sizeXxxsBottom {
  padding-bottom: 2px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxxsBottom {
      padding-bottom: 2px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxxsBottom {
      padding-bottom: 2px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxxsBottom {
      padding-bottom: 2px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxxsBottom {
      padding-bottom: 2px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxxsBottom {
      padding-bottom: 2px; } }

.telia-react-padding__sizeXxsLeft {
  padding-left: 4px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxsLeft {
      padding-left: 4px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxsLeft {
      padding-left: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxsLeft {
      padding-left: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxsLeft {
      padding-left: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxsLeft {
      padding-left: 4px; } }

.telia-react-padding__sizeXxsRight {
  padding-right: 4px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxsRight {
      padding-right: 4px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxsRight {
      padding-right: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxsRight {
      padding-right: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxsRight {
      padding-right: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxsRight {
      padding-right: 4px; } }

.telia-react-padding__sizeXxsTop {
  padding-top: 4px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxsTop {
      padding-top: 4px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxsTop {
      padding-top: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxsTop {
      padding-top: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxsTop {
      padding-top: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxsTop {
      padding-top: 4px; } }

.telia-react-padding__sizeXxsBottom {
  padding-bottom: 4px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxsBottom {
      padding-bottom: 4px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxsBottom {
      padding-bottom: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxsBottom {
      padding-bottom: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxsBottom {
      padding-bottom: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxsBottom {
      padding-bottom: 4px; } }

.telia-react-padding__sizeXsLeft {
  padding-left: 8px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXsLeft {
      padding-left: 8px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXsLeft {
      padding-left: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXsLeft {
      padding-left: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXsLeft {
      padding-left: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXsLeft {
      padding-left: 8px; } }

.telia-react-padding__sizeXsRight {
  padding-right: 8px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXsRight {
      padding-right: 8px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXsRight {
      padding-right: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXsRight {
      padding-right: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXsRight {
      padding-right: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXsRight {
      padding-right: 8px; } }

.telia-react-padding__sizeXsTop {
  padding-top: 8px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXsTop {
      padding-top: 8px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXsTop {
      padding-top: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXsTop {
      padding-top: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXsTop {
      padding-top: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXsTop {
      padding-top: 8px; } }

.telia-react-padding__sizeXsBottom {
  padding-bottom: 8px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXsBottom {
      padding-bottom: 8px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXsBottom {
      padding-bottom: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXsBottom {
      padding-bottom: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXsBottom {
      padding-bottom: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXsBottom {
      padding-bottom: 8px; } }

.telia-react-padding__sizeSmLeft {
  padding-left: 16px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeSmLeft {
      padding-left: 16px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeSmLeft {
      padding-left: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeSmLeft {
      padding-left: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeSmLeft {
      padding-left: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeSmLeft {
      padding-left: 16px; } }

.telia-react-padding__sizeSmRight {
  padding-right: 16px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeSmRight {
      padding-right: 16px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeSmRight {
      padding-right: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeSmRight {
      padding-right: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeSmRight {
      padding-right: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeSmRight {
      padding-right: 16px; } }

.telia-react-padding__sizeSmTop {
  padding-top: 16px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeSmTop {
      padding-top: 16px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeSmTop {
      padding-top: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeSmTop {
      padding-top: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeSmTop {
      padding-top: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeSmTop {
      padding-top: 16px; } }

.telia-react-padding__sizeSmBottom {
  padding-bottom: 16px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeSmBottom {
      padding-bottom: 16px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeSmBottom {
      padding-bottom: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeSmBottom {
      padding-bottom: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeSmBottom {
      padding-bottom: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeSmBottom {
      padding-bottom: 16px; } }

.telia-react-padding__sizeMdLeft {
  padding-left: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeMdLeft {
      padding-left: 24px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeMdLeft {
      padding-left: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeMdLeft {
      padding-left: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeMdLeft {
      padding-left: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeMdLeft {
      padding-left: 24px; } }

.telia-react-padding__sizeMdRight {
  padding-right: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeMdRight {
      padding-right: 24px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeMdRight {
      padding-right: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeMdRight {
      padding-right: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeMdRight {
      padding-right: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeMdRight {
      padding-right: 24px; } }

.telia-react-padding__sizeMdTop {
  padding-top: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeMdTop {
      padding-top: 24px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeMdTop {
      padding-top: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeMdTop {
      padding-top: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeMdTop {
      padding-top: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeMdTop {
      padding-top: 24px; } }

.telia-react-padding__sizeMdBottom {
  padding-bottom: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeMdBottom {
      padding-bottom: 24px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeMdBottom {
      padding-bottom: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeMdBottom {
      padding-bottom: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeMdBottom {
      padding-bottom: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeMdBottom {
      padding-bottom: 24px; } }

.telia-react-padding__sizeLgLeft {
  padding-left: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeLgLeft {
      padding-left: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeLgLeft {
      padding-left: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeLgLeft {
      padding-left: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeLgLeft {
      padding-left: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeLgLeft {
      padding-left: 32px; } }

.telia-react-padding__sizeLgRight {
  padding-right: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeLgRight {
      padding-right: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeLgRight {
      padding-right: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeLgRight {
      padding-right: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeLgRight {
      padding-right: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeLgRight {
      padding-right: 32px; } }

.telia-react-padding__sizeLgTop {
  padding-top: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeLgTop {
      padding-top: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeLgTop {
      padding-top: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeLgTop {
      padding-top: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeLgTop {
      padding-top: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeLgTop {
      padding-top: 32px; } }

.telia-react-padding__sizeLgBottom {
  padding-bottom: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeLgBottom {
      padding-bottom: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeLgBottom {
      padding-bottom: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeLgBottom {
      padding-bottom: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeLgBottom {
      padding-bottom: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeLgBottom {
      padding-bottom: 32px; } }

.telia-react-padding__sizeXlgLeft {
  padding-left: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXlgLeft {
      padding-left: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXlgLeft {
      padding-left: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXlgLeft {
      padding-left: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXlgLeft {
      padding-left: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXlgLeft {
      padding-left: 48px; } }

.telia-react-padding__sizeXlgRight {
  padding-right: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXlgRight {
      padding-right: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXlgRight {
      padding-right: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXlgRight {
      padding-right: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXlgRight {
      padding-right: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXlgRight {
      padding-right: 48px; } }

.telia-react-padding__sizeXlgTop {
  padding-top: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXlgTop {
      padding-top: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXlgTop {
      padding-top: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXlgTop {
      padding-top: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXlgTop {
      padding-top: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXlgTop {
      padding-top: 48px; } }

.telia-react-padding__sizeXlgBottom {
  padding-bottom: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXlgBottom {
      padding-bottom: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXlgBottom {
      padding-bottom: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXlgBottom {
      padding-bottom: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXlgBottom {
      padding-bottom: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXlgBottom {
      padding-bottom: 48px; } }

.telia-react-padding__sizeXxlgLeft {
  padding-left: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxlgLeft {
      padding-left: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxlgLeft {
      padding-left: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxlgLeft {
      padding-left: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxlgLeft {
      padding-left: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxlgLeft {
      padding-left: 96px; } }

.telia-react-padding__sizeXxlgRight {
  padding-right: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxlgRight {
      padding-right: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxlgRight {
      padding-right: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxlgRight {
      padding-right: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxlgRight {
      padding-right: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxlgRight {
      padding-right: 96px; } }

.telia-react-padding__sizeXxlgTop {
  padding-top: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxlgTop {
      padding-top: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxlgTop {
      padding-top: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxlgTop {
      padding-top: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxlgTop {
      padding-top: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxlgTop {
      padding-top: 96px; } }

.telia-react-padding__sizeXxlgBottom {
  padding-bottom: 24px; }
  @media (min-width: 601px) {
    .telia-react-padding__sizeXxlgBottom {
      padding-bottom: 32px; } }
  @media (min-width: 841px) {
    .telia-react-padding__sizeXxlgBottom {
      padding-bottom: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-padding__sizeXxlgBottom {
      padding-bottom: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-padding__sizeXxlgBottom {
      padding-bottom: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-padding__sizeXxlgBottom {
      padding-bottom: 96px; } }

.telia-react-hidden {
  display: none !important; }
  @media (max-width: 375px) {
    .telia-react-hidden__thinnerThanXxs {
      display: none !important; } }
  @media (max-width: 480px) {
    .telia-react-hidden__thinnerThanXs {
      display: none !important; } }
  @media (max-width: 600px) {
    .telia-react-hidden__thinnerThanSm {
      display: none !important; } }
  @media (max-width: 840px) {
    .telia-react-hidden__thinnerThanMd {
      display: none !important; } }
  @media (max-width: 1080px) {
    .telia-react-hidden__thinnerThanLg {
      display: none !important; } }
  @media (max-width: 1440px) {
    .telia-react-hidden__thinnerThanXlg {
      display: none !important; } }
  @media (max-width: 1920px) {
    .telia-react-hidden__thinnerThanXxlg {
      display: none !important; } }
  @media (min-width: 376px) {
    .telia-react-hidden__widerThanXxxs {
      display: none !important; } }
  @media (min-width: 481px) {
    .telia-react-hidden__widerThanXxs {
      display: none !important; } }
  @media (min-width: 601px) {
    .telia-react-hidden__widerThanXs {
      display: none !important; } }
  @media (min-width: 841px) {
    .telia-react-hidden__widerThanSm {
      display: none !important; } }
  @media (min-width: 1081px) {
    .telia-react-hidden__widerThanMd {
      display: none !important; } }
  @media (min-width: 1441px) {
    .telia-react-hidden__widerThanLg {
      display: none !important; } }
  @media (min-width: 1921px) {
    .telia-react-hidden__widerThanXlg {
      display: none !important; } }
  @media (max-width: 375px) {
    .telia-react-hidden__atXxxs {
      display: none !important; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-hidden__atXxs {
      display: none !important; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-hidden__atXs {
      display: none !important; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-hidden__atSm {
      display: none !important; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-hidden__atMd {
      display: none !important; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-hidden__atLg {
      display: none !important; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-hidden__atXlg {
      display: none !important; } }

.telia-react-visuallyHidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.telia-react-link {
  text-decoration: none;
  cursor: pointer; }
  .telia-react-link:focus {
    outline: 3px solid #0099ff;
    outline-offset: 2px; }

.telia-react-headline__size_xlg {
  font-size: 32px;
  line-height: 1.125em; }
  @media screen and (min-width: 480px) {
    .telia-react-headline__size_xlg {
      font-size: calc( 32px + 24 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-headline__size_xlg {
      font-size: 56px; } }

.telia-react-headline__size_lg {
  font-size: 24px;
  line-height: 1.125em; }
  @media screen and (min-width: 480px) {
    .telia-react-headline__size_lg {
      font-size: calc( 24px + 16 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-headline__size_lg {
      font-size: 40px; } }

.telia-react-headline__size_md {
  font-size: 18px;
  line-height: 1.125em; }
  @media screen and (min-width: 480px) {
    .telia-react-headline__size_md {
      font-size: calc( 18px + 14 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-headline__size_md {
      font-size: 32px; } }

.telia-react-headline__size_sm {
  font-size: 14px;
  line-height: 1.125em; }
  @media screen and (min-width: 480px) {
    .telia-react-headline__size_sm {
      font-size: calc( 14px + 10 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-headline__size_sm {
      font-size: 24px; } }

.telia-react-headline__size_xs {
  font-size: 14px;
  line-height: 1.125em; }
  @media screen and (min-width: 480px) {
    .telia-react-headline__size_xs {
      font-size: calc( 14px + 2 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-headline__size_xs {
      font-size: 16px; } }

/*
  Browsers default font-size is 16px. Using pixel is not a good practice
  because changing default font-size won't effect the element's font-size in hierarchy
  and it is not good user experience. So we use rem.
*/
html {
  font-size: 62.5%; }

body {
  font-size: 1.6rem;
  -webkit-text-size-adjust: 100%; }

.telia-react-typography {
  min-height: 0.0000001vw; }
  .telia-react-typography__pebble {
    font-family: 'Pebble', TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal; }
  .telia-react-typography__teliasans {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; }
  .telia-react-typography__teliasansHeading {
    font-family: TeliaSansHeading, TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .telia-react-typography__uppercase {
    text-transform: uppercase; }
  .telia-react-typography__strikeThrough {
    text-decoration: line-through; }
  .telia-react-typography__singleLine {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block; }

.telia-react-title__size_lg {
  font-size: 24px;
  line-height: 1.25em; }
  @media screen and (min-width: 480px) {
    .telia-react-title__size_lg {
      font-size: calc( 24px + 16 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-title__size_lg {
      font-size: 40px; } }

.telia-react-title__size_md {
  font-size: 18px;
  line-height: 1.25em; }
  @media screen and (min-width: 480px) {
    .telia-react-title__size_md {
      font-size: calc( 18px + 14 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-title__size_md {
      font-size: 32px; } }

.telia-react-title__size_sm {
  font-size: 16px;
  line-height: 1.25em; }
  @media screen and (min-width: 480px) {
    .telia-react-title__size_sm {
      font-size: calc( 16px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-title__size_sm {
      font-size: 24px; } }

.telia-react-title__size_xs {
  font-size: 16px;
  line-height: 1.25em; }
  @media screen and (min-width: 480px) {
    .telia-react-title__size_xs {
      font-size: calc( 16px + 4 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-title__size_xs {
      font-size: 20px; } }

.telia-react-title__size_xxs {
  font-size: 14px;
  line-height: 1.25em; }
  @media screen and (min-width: 480px) {
    .telia-react-title__size_xxs {
      font-size: calc( 14px + 2 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-title__size_xxs {
      font-size: 16px; } }

.telia-react-title__size_xxxs {
  font-size: 12px;
  line-height: 1.25em; }
  @media screen and (min-width: 480px) {
    .telia-react-title__size_xxxs {
      font-size: calc( 12px + 2 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-title__size_xxxs {
      font-size: 14px; } }

.telia-react-accordion {
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px; }
  .telia-react-accordion__header {
    cursor: pointer;
    height: 80px;
    outline: none; }
    .telia-react-accordion__header:focus {
      color: #990ae3; }
  .telia-react-accordion__image {
    height: 56px;
    width: 56px;
    padding-right: 8px; }
  .telia-react-accordion__icon {
    transition: transform 0.07s ease-in-out; }
    .telia-react-accordion__icon_rotate {
      transform: rotate(-180deg);
      transition: transform 0.07s ease-in-out; }
  .telia-react-accordion__content {
    background-color: white; }
    .telia-react-accordion__content__wrapper {
      padding: 0 0 16px 0;
      overflow: visible; }
  @media (max-width: 840px) {
    .telia-react-accordion {
      padding: 0 16px 0 16px; }
      .telia-react-accordion__image {
        height: 48px;
        width: 48px; }
      .telia-react-accordion__header {
        height: 64px; } }

.telia-react-backgroundImage {
  height: 100%; }
  .telia-react-backgroundImage_responsiveSpace {
    height: 100%; }
  .telia-react-backgroundImage_image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100%; }
  .telia-react-backgroundImage__scaleWithOriginalAspectRatio .telia-react-backgroundImage_image {
    background-size: contain; }

.telia-react-bodyText__size_md {
  font-size: 14px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-bodyText__size_md {
      font-size: calc( 14px + 2 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-bodyText__size_md {
      font-size: 16px; } }

.telia-react-bodyText__size_sm {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-bodyText__size_sm {
      font-size: calc( 12px + 2 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-bodyText__size_sm {
      font-size: 14px; } }

.telia-react-bodyText__size_xs {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-bodyText__size_xs {
      font-size: calc( 12px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-bodyText__size_xs {
      font-size: 12px; } }

.telia-react-gutter {
  min-height: 24px;
  min-width: 24px; }
  .telia-react-gutter__half {
    min-height: 12px;
    min-width: 12px; }
  .telia-react-gutter__quarter {
    min-height: 6px;
    min-width: 6px; }
  .telia-react-gutter__zero {
    min-height: 0;
    min-width: 0; }
  .telia-react-gutter__layout_1 {
    min-height: 8px;
    min-width: 8px; }
  .telia-react-gutter__layout_2 {
    min-height: 12px;
    min-width: 12px; }
  .telia-react-gutter__layout_3 {
    min-height: 16px;
    min-width: 16px; }
  .telia-react-gutter__layout_4 {
    min-height: 24px;
    min-width: 24px; }
  .telia-react-gutter__layout_5 {
    min-height: 32px;
    min-width: 32px; }
  .telia-react-gutter__layout_6 {
    min-height: 48px;
    min-width: 48px; }
  .telia-react-gutter__layout_7 {
    min-height: 64px;
    min-width: 64px; }
  .telia-react-gutter__layout_8 {
    min-height: 80px;
    min-width: 80px; }
  .telia-react-gutter__layout_9 {
    min-height: 96px;
    min-width: 96px; }

.telia-react-gutter__sizeZero {
  min-width: 0;
  min-height: 0; }
  @media (min-width: 601px) {
    .telia-react-gutter__sizeZero {
      min-width: 0;
      min-height: 0; } }
  @media (min-width: 841px) {
    .telia-react-gutter__sizeZero {
      min-width: 0;
      min-height: 0; } }
  @media (min-width: 1081px) {
    .telia-react-gutter__sizeZero {
      min-width: 0;
      min-height: 0; } }
  @media (min-width: 1441px) {
    .telia-react-gutter__sizeZero {
      min-width: 0;
      min-height: 0; } }
  @media (min-width: 1921px) {
    .telia-react-gutter__sizeZero {
      min-width: 0;
      min-height: 0; } }

.telia-react-gutter__sizeXxs {
  min-width: 4px;
  min-height: 4px; }
  @media (min-width: 601px) {
    .telia-react-gutter__sizeXxs {
      min-width: 4px;
      min-height: 4px; } }
  @media (min-width: 841px) {
    .telia-react-gutter__sizeXxs {
      min-width: 4px;
      min-height: 4px; } }
  @media (min-width: 1081px) {
    .telia-react-gutter__sizeXxs {
      min-width: 4px;
      min-height: 4px; } }
  @media (min-width: 1441px) {
    .telia-react-gutter__sizeXxs {
      min-width: 4px;
      min-height: 4px; } }
  @media (min-width: 1921px) {
    .telia-react-gutter__sizeXxs {
      min-width: 4px;
      min-height: 4px; } }

.telia-react-gutter__sizeXs {
  min-width: 8px;
  min-height: 8px; }
  @media (min-width: 601px) {
    .telia-react-gutter__sizeXs {
      min-width: 8px;
      min-height: 8px; } }
  @media (min-width: 841px) {
    .telia-react-gutter__sizeXs {
      min-width: 8px;
      min-height: 8px; } }
  @media (min-width: 1081px) {
    .telia-react-gutter__sizeXs {
      min-width: 8px;
      min-height: 8px; } }
  @media (min-width: 1441px) {
    .telia-react-gutter__sizeXs {
      min-width: 8px;
      min-height: 8px; } }
  @media (min-width: 1921px) {
    .telia-react-gutter__sizeXs {
      min-width: 8px;
      min-height: 8px; } }

.telia-react-gutter__sizeSm {
  min-width: 16px;
  min-height: 16px; }
  @media (min-width: 601px) {
    .telia-react-gutter__sizeSm {
      min-width: 16px;
      min-height: 16px; } }
  @media (min-width: 841px) {
    .telia-react-gutter__sizeSm {
      min-width: 16px;
      min-height: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-gutter__sizeSm {
      min-width: 16px;
      min-height: 16px; } }
  @media (min-width: 1441px) {
    .telia-react-gutter__sizeSm {
      min-width: 16px;
      min-height: 16px; } }
  @media (min-width: 1921px) {
    .telia-react-gutter__sizeSm {
      min-width: 16px;
      min-height: 16px; } }

.telia-react-gutter__sizeMd {
  min-width: 24px;
  min-height: 24px; }
  @media (min-width: 601px) {
    .telia-react-gutter__sizeMd {
      min-width: 24px;
      min-height: 24px; } }
  @media (min-width: 841px) {
    .telia-react-gutter__sizeMd {
      min-width: 24px;
      min-height: 24px; } }
  @media (min-width: 1081px) {
    .telia-react-gutter__sizeMd {
      min-width: 24px;
      min-height: 24px; } }
  @media (min-width: 1441px) {
    .telia-react-gutter__sizeMd {
      min-width: 24px;
      min-height: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-gutter__sizeMd {
      min-width: 24px;
      min-height: 24px; } }

.telia-react-gutter__sizeLg {
  min-width: 24px;
  min-height: 24px; }
  @media (min-width: 601px) {
    .telia-react-gutter__sizeLg {
      min-width: 32px;
      min-height: 32px; } }
  @media (min-width: 841px) {
    .telia-react-gutter__sizeLg {
      min-width: 32px;
      min-height: 32px; } }
  @media (min-width: 1081px) {
    .telia-react-gutter__sizeLg {
      min-width: 32px;
      min-height: 32px; } }
  @media (min-width: 1441px) {
    .telia-react-gutter__sizeLg {
      min-width: 32px;
      min-height: 32px; } }
  @media (min-width: 1921px) {
    .telia-react-gutter__sizeLg {
      min-width: 32px;
      min-height: 32px; } }

.telia-react-gutter__sizeXlg {
  min-width: 24px;
  min-height: 24px; }
  @media (min-width: 601px) {
    .telia-react-gutter__sizeXlg {
      min-width: 32px;
      min-height: 32px; } }
  @media (min-width: 841px) {
    .telia-react-gutter__sizeXlg {
      min-width: 48px;
      min-height: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-gutter__sizeXlg {
      min-width: 48px;
      min-height: 48px; } }
  @media (min-width: 1441px) {
    .telia-react-gutter__sizeXlg {
      min-width: 48px;
      min-height: 48px; } }
  @media (min-width: 1921px) {
    .telia-react-gutter__sizeXlg {
      min-width: 48px;
      min-height: 48px; } }

.telia-react-gutter__sizeXxlg {
  min-width: 24px;
  min-height: 24px; }
  @media (min-width: 601px) {
    .telia-react-gutter__sizeXxlg {
      min-width: 32px;
      min-height: 32px; } }
  @media (min-width: 841px) {
    .telia-react-gutter__sizeXxlg {
      min-width: 48px;
      min-height: 48px; } }
  @media (min-width: 1081px) {
    .telia-react-gutter__sizeXxlg {
      min-width: 96px;
      min-height: 96px; } }
  @media (min-width: 1441px) {
    .telia-react-gutter__sizeXxlg {
      min-width: 96px;
      min-height: 96px; } }
  @media (min-width: 1921px) {
    .telia-react-gutter__sizeXxlg {
      min-width: 96px;
      min-height: 96px; } }

.telia-react-input {
  box-sizing: border-box;
  width: 100%;
  /* IE11 focus fixes
     focus within polyfill adds :focus-within classes to the components with :focus-within styles.
     These styles cover those focus styles because IE11 doesn't support :focus-within
  */ }
  .telia-react-input_required {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold; }
  .telia-react-input:focus .telia-react-input__container,
  .telia-react-input:focus-within .telia-react-input__container {
    box-shadow: inset 0 0 0 2px #990ae3;
    transition: box-shadow 150ms ease, border 150ms ease;
    outline: none; }
  .telia-react-input:hover .telia-react-input__container_error,
  .telia-react-input:focus-within .telia-react-input__container_error {
    box-shadow: inset 0 0 0 2px #be0040;
    transition: box-shadow 150ms ease, border 150ms ease; }
    .telia-react-input:hover .telia-react-input__container_error .telia-react-input__iconWrapper__icon,
    .telia-react-input:focus-within .telia-react-input__container_error .telia-react-input__iconWrapper__icon {
      color: #be0040;
      transition: color 150ms ease; }
  .telia-react-input:focus-within .telia-react-input__iconWrapper__icon {
    color: #990ae3;
    transition: color 150ms ease; }
  .telia-react-input__iconWrapper {
    width: 24px;
    height: 24px;
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%); }
    .telia-react-input__iconWrapper_clickable {
      pointer-events: auto; }
  .telia-react-input__container {
    height: 48px;
    position: relative;
    box-shadow: inset 0px 0px 0px 1px #6e6e6e;
    transition: box-shadow 150ms ease, border 150ms ease; }
    .telia-react-input__container_error {
      box-shadow: inset 0px 0px 0px 1px #be0040;
      transition: box-shadow 150ms ease, border 150ms ease; }
    .telia-react-input__container:hover {
      box-shadow: inset 0 0 0 2px #6e6e6e;
      transition: box-shadow 150ms ease, border 150ms ease; }
    .telia-react-input__container_disabled {
      cursor: not-allowed;
      box-shadow: inset 0px 0px 0px 1px #cccccc; }
      .telia-react-input__container_disabled:hover {
        background-color: #f2f2f2;
        box-shadow: inset 0px 0px 0px 1px #cccccc; }
    .telia-react-input__container input::-ms-clear {
      width: 0;
      height: 0; }
  .telia-react-input__inputField {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 16px;
    height: 100%;
    outline: none;
    width: 100%;
    background: transparent;
    color: #222222;
    margin: 0;
    padding: 0 16px;
    border: 0;
    max-width: none;
    box-sizing: border-box;
    box-shadow: none;
    /** a way to disable the red border from invalid input. */
    min-width: 1px; }
    .telia-react-input__inputField::placeholder {
      color: #a0a0a0; }
    .telia-react-input__inputField_withIcon {
      padding: 0 40px 0 16px; }
    .telia-react-input__inputField_disabled {
      color: #a0a0a0;
      cursor: not-allowed;
      -webkit-text-fill-color: #a0a0a0;
      /* required on Safari */
      opacity: 1;
      /* required on Safari */ }
  .telia-react-input input:-webkit-autofill,
  .telia-react-input textarea:-webkit-autofill,
  .telia-react-input select:-webkit-autofill {
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    padding: 0 15px; }
    .telia-react-input input:-webkit-autofill:focus, .telia-react-input input:-webkit-autofill:hover,
    .telia-react-input textarea:-webkit-autofill:focus,
    .telia-react-input textarea:-webkit-autofill:hover,
    .telia-react-input select:-webkit-autofill:focus,
    .telia-react-input select:-webkit-autofill:hover {
      height: calc(100% - 4px);
      width: calc(100% - 4px);
      padding: 0 14px; }
  .telia-react-input .telia-react-input__container.focus-within {
    box-shadow: inset 0 0 0 2px #990ae3;
    transition: box-shadow 150ms ease, border 150ms ease;
    outline: none; }
  .telia-react-input .telia-react-input__container.focus-within
.telia-react-input__iconWrapper__icon {
    color: #990ae3;
    transition: color 150ms ease; }
  .telia-react-input .telia-react-input__container_error.focus-within {
    box-shadow: inset 0 0 0 2px #be0040;
    transition: box-shadow 150ms ease, border 150ms ease; }
    .telia-react-input .telia-react-input__container_error.focus-within .telia-react-input__iconWrapper__icon.focus-within {
      color: #be0040;
      transition: color 150ms ease; }

.telia-react-inputMessage_textContainer {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box; }

.telia-react-inputMessage_text {
  max-height: 40px;
  overflow: hidden;
  word-break: break-word; }
  @media (max-width: 840px) {
    .telia-react-inputMessage_text {
      max-height: 76px; } }

.telia-react-smallText__size_md {
  font-size: 12px;
  line-height: 18px; }

.telia-react-smallText__size_sm {
  font-size: 10px;
  line-height: 14px; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-checkbox {
  position: relative;
  /* boxSize variable is used to calculate width and heigth of checkbox */ }
  .telia-react-checkbox_label {
    display: inline-block;
    cursor: pointer; }
  .telia-react-checkbox:hover .telia-react-checkbox_label .telia-react-checkbox_fakeCheckbox {
    border: 2px solid #6e6e6e; }
    .telia-react-checkbox:hover .telia-react-checkbox_label .telia-react-checkbox_fakeCheckbox__checked {
      border: solid 1px #990ae3;
      background-color: #990ae3; }
    .telia-react-checkbox:hover .telia-react-checkbox_label .telia-react-checkbox_fakeCheckbox__disabled {
      border: solid 1px #cccccc;
      background-color: #cccccc; }
    .telia-react-checkbox:hover .telia-react-checkbox_label .telia-react-checkbox_fakeCheckbox_error {
      border: solid 2px #be0040; }
    .telia-react-checkbox:hover .telia-react-checkbox_label .telia-react-checkbox_fakeCheckbox:focus {
      border-width: 1px; }
  .telia-react-checkbox__disabled .telia-react-checkbox_label:hover .telia-react-checkbox_fakeCheckbox__checked {
    background-color: #cccccc; }
  .telia-react-checkbox_description {
    font-size: 14px; }
  .telia-react-checkbox_hiddenCheckbox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999999;
    opacity: 0; }
  .telia-react-checkbox_fakeCheckbox {
    position: relative;
    height: 24px;
    min-height: 24px;
    width: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: solid 1px #6e6e6e;
    transition: box-shadow 150ms ease;
    transition: border 150ms ease;
    cursor: pointer;
    outline: none; }
    .telia-react-checkbox_fakeCheckbox_error {
      border: solid 1px #be0040; }
    .telia-react-checkbox_fakeCheckbox:focus::before {
      transition: box-shadow 150ms ease;
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      border-radius: 6px;
      border: 2px solid #4dc0ff; }
    .telia-react-checkbox_fakeCheckbox__checked {
      background-color: #990ae3;
      border: solid 1px #990ae3; }
      .telia-react-checkbox_fakeCheckbox__checked_checkIcon {
        color: #ffffff; }
    .telia-react-checkbox_fakeCheckbox__disabled {
      background-color: #f2f2f2;
      border: solid 1px #cccccc;
      cursor: not-allowed; }
      .telia-react-checkbox_fakeCheckbox__disabled:focus {
        box-shadow: none; }
      .telia-react-checkbox_fakeCheckbox__disabled:focus::before {
        content: unset; }
  .telia-react-checkbox_checkIcon {
    opacity: 0; }
  .telia-react-checkbox__checked .telia-react-checkbox_checkIcon {
    opacity: 1; }
  .telia-react-checkbox__disabled .telia-react-checkbox_label {
    cursor: not-allowed; }
    .telia-react-checkbox__disabled .telia-react-checkbox_label:focus {
      outline: none; }
  .telia-react-checkbox__disabled.telia-react-checkbox__checked .telia-react-checkbox_fakeCheckbox {
    background-color: #cccccc;
    border: solid 1px #cccccc; }
    .telia-react-checkbox__disabled.telia-react-checkbox__checked .telia-react-checkbox_fakeCheckbox_checkIcon {
      color: #222222; }
    .telia-react-checkbox__disabled.telia-react-checkbox__checked .telia-react-checkbox_fakeCheckbox:focus {
      box-shadow: none;
      outline: none; }
  .telia-react-checkbox__loading .telia-react-checkbox_label {
    cursor: default; }
  .telia-react-checkbox_loader {
    padding: 0 2px; }

@keyframes telia-react-rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.telia-react-spinningLoaderLarge {
  width: 70px;
  height: 70px;
  animation: telia-react-rotate 1s linear infinite; }

.telia-react-spinningLoaderMedium {
  width: 40px;
  height: 40px;
  animation: telia-react-rotate 1s linear infinite; }

.telia-react-spinningLoaderSmall {
  width: 20px;
  height: 20px;
  animation: telia-react-rotate 1s linear infinite; }

.telia-react-icon, .telia-react-checkboxIcon {
  display: inline-block; }
  .telia-react-icon__sizeXlg {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px; }
  .telia-react-icon__sizeLg {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px; }
  .telia-react-icon__sizeMd {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    max-width: 24px;
    max-height: 24px; }
  .telia-react-icon__sizeSm, .telia-react-checkboxIcon {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px; }
  .telia-react-icon__sizeXs {
    min-width: 12px;
    min-height: 12px;
    width: 12px;
    height: 12px;
    max-width: 12px;
    max-height: 12px; }
  .telia-react-icon_svg, .telia-react-checkboxIcon svg {
    display: block; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-optionlist {
  position: absolute;
  width: 100%;
  max-height: 336px;
  overflow-y: auto;
  box-sizing: border-box;
  background: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(58, 58, 58, 0.12);
  border: 1px solid #6e6e6e;
  overflow-x: hidden;
  z-index: 1; }
  .telia-react-optionlist_borderlessItems {
    width: 168px !important; }
    .telia-react-optionlist_borderlessItems .telia-react-optionlist_item {
      border-bottom: none; }
  .telia-react-optionlist_item {
    padding: 16px;
    color: #222222;
    cursor: pointer;
    outline: none;
    line-height: 28px;
    border-bottom: solid 1px #cccccc;
    box-sizing: border-box; }
    .telia-react-optionlist_item > * {
      pointer-events: none; }
    .telia-react-optionlist_item:hover {
      background: #f2f2f2; }
    .telia-react-optionlist__showKeyboardFocus .telia-react-optionlist_item :active, .telia-react-optionlist__showKeyboardFocus .telia-react-optionlist_item:focus {
      box-shadow: 0px 0px 0px 4px #0099ff inset;
      transition: box-shadow 150ms ease;
      outline: none; }
    .telia-react-optionlist_item:last-child {
      border: 0; }
  .telia-react-optionlist:focus .telia-react-optionlist_item__selected {
    background: #f2f2f2;
    color: #222222;
    box-shadow: 0px 0px 0px 4px #0099ff inset;
    transition: box-shadow 150ms ease; }
  .telia-react-optionlist_item__selected {
    background: #f2f2f2;
    color: #222222; }
    .telia-react-optionlist_item__selected:hover {
      background: #f2f2f2;
      color: #222222; }
    .telia-react-optionlist__showKeyboardFocus .telia-react-optionlist_item__selected:focus {
      box-shadow: 0px 0px 0px 4px #0099ff inset;
      transition: box-shadow 150ms ease; }
  .telia-react-optionlist_item__disabled {
    background-color: #f2f2f2;
    color: #a0a0a0;
    cursor: not-allowed; }
    .telia-react-optionlist_item__disabled:hover {
      background-color: #f2f2f2; }
    .telia-react-optionlist_item__disabled .telia-react-checkbox__disabled .telia-react-checkbox_label,
    .telia-react-optionlist_item__disabled .telia-react-checkbox__disabled .telia-react-checkbox_fakeCheckbox {
      cursor: default; }
  .telia-react-optionlist_itemContent {
    pointer-events: none; }

.telia-react-autocomplete {
  position: relative;
  width: 100%; }
  .telia-react-autocomplete .telia-react-input,
  .telia-react-autocomplete .telia-react-input input {
    cursor: pointer;
    -webkit-appearance: none; }
  .telia-react-autocomplete_open .telia-react-inputMessage_textContainer {
    padding: 0; }
  .telia-react-autocomplete_open .telia-react-inputMessage_text {
    display: none; }
  .telia-react-autocomplete .telia-react-input__container_disabled,
  .telia-react-autocomplete .telia-react-input__container_disabled input {
    cursor: not-allowed; }
  .telia-react-autocomplete .telia-react-optionlist {
    margin-top: -2px; }

.telia-react-indicator {
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px; }

.telia-react-textlink__size_lg {
  font-size: 18px;
  line-height: 28px; }

.telia-react-textlink__size_md {
  font-size: 16px;
  line-height: 26px; }

.telia-react-textlink__size_sm {
  font-size: 14px;
  line-height: 24px; }

.telia-react-breadcrumb_currentPage {
  font-size: 14px;
  outline: 0; }

.telia-react-breadcrumb_optionList {
  display: inline-block;
  width: auto;
  position: absolute;
  left: -16px;
  top: 0;
  margin-top: 8px !important; }

.telia-react-breadcrumb_truncated_toggle {
  position: relative; }

.telia-react-breadcrumb_truncated_wrapper {
  position: absolute;
  left: 0;
  bottom: 0; }

.telia-react-buttonText__size_md {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.4px; }

.telia-react-buttonText__size_sm {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.34px; }

.telia-react-button {
  border: 2px solid transparent;
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s; }
  .telia-react-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 6px #0099ff; }
  .telia-react-button.telia-react-button__disabled {
    cursor: not-allowed; }
  .telia-react-button__loading {
    pointer-events: none;
    opacity: 0.5;
    filter: alpha(opacity=50);
    /* For IE8 and earlier */ }
  .telia-react-button__loader {
    top: 1px;
    font-size: 14px; }
  .telia-react-button__size__sm {
    height: 28px; }
  .telia-react-button__size__md {
    height: 36px; }
  .telia-react-button__size__lg {
    height: 40px; }
  .telia-react-button__size__xl {
    height: 44px; }
  .telia-react-button__primary {
    background-color: #990ae3;
    color: #ffffff; }
    .telia-react-button__primary:hover, .telia-react-button__primary:active {
      color: #ffffff;
      background-color: #7d00bd; }
    .telia-react-button__primary.telia-react-button__disabled {
      background-color: #a0a0a0; }
    @media (max-width: 600px) {
      .telia-react-button__primary.telia-react-button__expandWhenMobile {
        width: 100%; } }
  .telia-react-button__primaryWhite {
    background-color: #ffffff;
    color: #990ae3; }
    .telia-react-button__primaryWhite:hover, .telia-react-button__primaryWhite:active {
      color: #ffffff;
      background-color: #7d00bd; }
    .telia-react-button__primaryWhite.telia-react-button__disabled {
      color: #a0a0a0;
      background-color: #ffffff; }
    @media (max-width: 600px) {
      .telia-react-button__primaryWhite.telia-react-button__expandWhenMobile {
        width: 100%; } }
  .telia-react-button__purchase {
    background-color: #008641;
    color: #ffffff; }
    .telia-react-button__purchase:hover, .telia-react-button__purchase:active {
      background-color: #00592b; }
    .telia-react-button__purchase.telia-react-button__disabled {
      background-color: #a0a0a0; }
    @media (max-width: 600px) {
      .telia-react-button__purchase.telia-react-button__expandWhenMobile {
        width: 100%; } }
  .telia-react-button__secondary {
    background-color: transparent;
    color: #990ae3;
    border-color: #990ae3; }
    .telia-react-button__secondary:hover, .telia-react-button__secondary:active {
      background-color: #990ae3;
      color: #ffffff;
      border-color: #990ae3; }
    .telia-react-button__secondary.telia-react-button__disabled {
      color: #a0a0a0;
      border-color: #a0a0a0;
      background-color: transparent; }
    @media (max-width: 600px) {
      .telia-react-button__secondary.telia-react-button__expandWhenMobile {
        width: 100%; } }
  .telia-react-button__secondaryWhite {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff; }
    .telia-react-button__secondaryWhite:hover, .telia-react-button__secondaryWhite:active {
      background-color: #7d00bd;
      color: #ffffff;
      border-color: #7d00bd; }
    .telia-react-button__secondaryWhite.telia-react-button__disabled {
      color: #a0a0a0;
      border-color: #a0a0a0;
      background-color: transparent; }
    @media (max-width: 600px) {
      .telia-react-button__secondaryWhite.telia-react-button__expandWhenMobile {
        width: 100%; } }
  .telia-react-button__purchaseSecondary {
    background-color: transparent;
    color: #008641;
    border-color: #008641; }
    .telia-react-button__purchaseSecondary:hover, .telia-react-button__purchaseSecondary:active {
      background-color: #008641;
      border-color: #008641;
      color: #ffffff; }
    .telia-react-button__purchaseSecondary.telia-react-button__disabled {
      color: #a0a0a0;
      border-color: #a0a0a0;
      background-color: transparent; }
    @media (max-width: 600px) {
      .telia-react-button__purchaseSecondary.telia-react-button__expandWhenMobile {
        width: 100%; } }
  .telia-react-button__destructive {
    background-color: #e4175c;
    color: #ffffff; }
    .telia-react-button__destructive:hover, .telia-react-button__destructive:active {
      background-color: #be0040; }
    .telia-react-button__destructive.telia-react-button__disabled {
      background-color: #a0a0a0; }
    @media (max-width: 600px) {
      .telia-react-button__destructive.telia-react-button__expandWhenMobile {
        width: 100%; } }
  .telia-react-button__secondaryDestructive {
    background-color: transparent;
    color: #e4175c;
    border-color: #e4175c; }
    .telia-react-button__secondaryDestructive:hover, .telia-react-button__secondaryDestructive:active {
      background-color: #e4175c;
      color: #ffffff; }
    .telia-react-button__secondaryDestructive.telia-react-button__disabled {
      color: #a0a0a0;
      border-color: #a0a0a0;
      background-color: transparent; }
    @media (max-width: 600px) {
      .telia-react-button__secondaryDestructive.telia-react-button__expandWhenMobile {
        width: 100%; } }
  .telia-react-button__text {
    background: none; }
    .telia-react-button__text .telia-react-button__size__md {
      height: 100%; }

.telia-react-button::-moz-active-inner {
  border: 0; }

.telia-react-loaderLarge {
  display: inline-block;
  height: 72px;
  width: 72px;
  position: relative; }
  .telia-react-loaderLarge__iconContainer {
    position: absolute;
    left: 28px;
    height: 72px; }
    .telia-react-loaderLarge__iconContainer:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1000ms; }
    .telia-react-loaderLarge__iconContainer:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -875ms; }
    .telia-react-loaderLarge__iconContainer:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -750ms; }
    .telia-react-loaderLarge__iconContainer:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -625ms; }
    .telia-react-loaderLarge__iconContainer:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -500ms; }
    .telia-react-loaderLarge__iconContainer:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -375ms; }
    .telia-react-loaderLarge__iconContainer:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -250ms; }
    .telia-react-loaderLarge__iconContainer:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -125ms; }
  .telia-react-loaderLarge svg {
    height: 16px !important;
    width: 16px !important;
    overflow: hidden;
    animation-delay: inherit;
    animation-name: telia-react-pebbleSpinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both; }

.telia-react-loaderMedium {
  display: inline-block;
  height: 38px;
  width: 38px;
  position: relative; }
  .telia-react-loaderMedium__iconContainer {
    position: absolute;
    left: 16px;
    height: 38px; }
    .telia-react-loaderMedium__iconContainer:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1000ms; }
    .telia-react-loaderMedium__iconContainer:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -875ms; }
    .telia-react-loaderMedium__iconContainer:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -750ms; }
    .telia-react-loaderMedium__iconContainer:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -625ms; }
    .telia-react-loaderMedium__iconContainer:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -500ms; }
    .telia-react-loaderMedium__iconContainer:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -375ms; }
    .telia-react-loaderMedium__iconContainer:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -250ms; }
    .telia-react-loaderMedium__iconContainer:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -125ms; }
  .telia-react-loaderMedium svg {
    height: 6px !important;
    width: 6px !important;
    overflow: hidden;
    animation-delay: inherit;
    animation-name: telia-react-pebbleSpinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both; }

.telia-react-loaderSmall {
  display: inline-block;
  height: 16px;
  width: 16px;
  position: relative; }
  .telia-react-loaderSmall__iconContainer {
    position: absolute;
    left: 6.75px;
    height: 16px; }
    .telia-react-loaderSmall__iconContainer:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1000ms; }
    .telia-react-loaderSmall__iconContainer:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -875ms; }
    .telia-react-loaderSmall__iconContainer:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -750ms; }
    .telia-react-loaderSmall__iconContainer:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -625ms; }
    .telia-react-loaderSmall__iconContainer:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -500ms; }
    .telia-react-loaderSmall__iconContainer:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -375ms; }
    .telia-react-loaderSmall__iconContainer:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -250ms; }
    .telia-react-loaderSmall__iconContainer:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -125ms; }
  .telia-react-loaderSmall svg {
    height: 2.5px !important;
    width: 2.5px !important;
    overflow: hidden;
    animation-delay: inherit;
    animation-name: telia-react-pebbleSpinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both; }

@keyframes telia-react-pebbleSpinner {
  0% {
    opacity: 0.2;
    transform: scale(0.4); }
  10% {
    opacity: 1;
    transform: scale(1.1); }
  20% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.2;
    transform: scale(0.4); } }

.telia-react-collapsibleBlock {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc; }
  .telia-react-collapsibleBlock__purpleBackground {
    color: #ffffff; }
  .telia-react-collapsibleBlock_header {
    outline: none; }
    .telia-react-collapsibleBlock__purpleBackground .telia-react-collapsibleBlock_header:focus,
    .telia-react-collapsibleBlock__purpleBackground .telia-react-collapsibleBlock_header:hover {
      background-color: #990ae3; }
  .telia-react-collapsibleBlock_header:focus .telia-react-collapsibleBlock_label,
  .telia-react-collapsibleBlock_header:hover .telia-react-collapsibleBlock_label {
    color: #990ae3; }
    .telia-react-collapsibleBlock__purpleBackground .telia-react-collapsibleBlock_header:focus .telia-react-collapsibleBlock_label, .telia-react-collapsibleBlock__purpleBackground
    .telia-react-collapsibleBlock_header:hover .telia-react-collapsibleBlock_label {
      color: #ffffff; }
  .telia-react-collapsibleBlock__purpleBackground .telia-react-collapsibleBlock_label {
    color: #ffffff; }
  .telia-react-collapsibleBlock_icon {
    font-size: 16px; }
    .telia-react-collapsibleBlock_header:focus .telia-react-collapsibleBlock_icon,
    .telia-react-collapsibleBlock_header:hover .telia-react-collapsibleBlock_icon {
      color: #990ae3; }
      .telia-react-collapsibleBlock__purpleBackground .telia-react-collapsibleBlock_header:focus .telia-react-collapsibleBlock_icon, .telia-react-collapsibleBlock__purpleBackground
      .telia-react-collapsibleBlock_header:hover .telia-react-collapsibleBlock_icon {
        color: #ffffff; }
    .telia-react-collapsibleBlock__purpleBackground .telia-react-collapsibleBlock_icon {
      color: #ffffff; }
  .telia-react-collapsibleBlock_numberOfItems {
    width: 24px;
    height: 24px;
    border-radius: 50%; }

.telia-react-collapsibleBlockList > .telia-react-collapsibleBlock {
  margin-bottom: -1px; }
  .telia-react-collapsibleBlockList > .telia-react-collapsibleBlock:last-child {
    margin-bottom: 0; }

.telia-react-dot {
  width: 24px;
  height: 24px; }

.telia-react-dot_with_border {
  border: 1px solid #cccccc; }

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4A90E2;
  color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.telia-react-input__readonly .telia-react-input__container {
  box-shadow: none;
  background: #fbfbfb; }
  .telia-react-input__readonly .telia-react-input__container:focus {
    box-shadow: none; }
  .telia-react-input__readonly .telia-react-input__container:focus-within {
    box-shadow: none; }

.telia-react-input.telia-react-input__readonly .telia-react-input__iconWrapper__icon {
  color: #6e6e6e; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-datepicker:focus-within .telia-react-icon {
  color: #222222; }

.telia-react-datepicker__caption {
  text-align: center;
  text-transform: capitalize;
  padding: 16px; }

.telia-react-datepicker__container {
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px; }

.telia-react-datepicker__navButtonPrev, .telia-react-datepicker__navButtonNext {
  height: 40px;
  width: 8px;
  background-image: none;
  color: #222222; }

.telia-react-datepicker__navButtonPrev {
  left: 1.5rem;
  right: inherit;
  padding-right: 16px;
  margin-right: 0;
  background-image: url("data:image/svg+xml,%3Csvg width%3D%2264%22 height%3D%2264%22 viewBox%3D%220 0 64 64%22 fill%3D%22none%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath fill-rule%3D%22evenodd%22 clip-rule%3D%22evenodd%22 d%3D%22M42.1162 49.8902C42.6915 50.4081 42.9468 51.1934 42.786 51.9505C42.6252 52.7076 42.0727 53.3214 41.3366 53.5607C40.6005 53.8 39.7927 53.6284 39.2174 53.1106L17.5508 33.6106C17.0942 33.1999 16.8335 32.6146 16.8335 32.0004C16.8335 31.3863 17.0942 30.801 17.5508 30.3902L39.2174 10.8902C40.1067 10.0898 41.4766 10.1618 42.277 11.0511C43.0775 11.9403 43.0055 13.3102 42.1162 14.1106L22.2385 32.0004L42.1162 49.8902Z%22 fill%3D%22%23222222%22%2F%3E%3C%2Fsvg%3E"); }

.telia-react-datepicker__navButtonNext {
  padding-left: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width%3D%2264%22 height%3D%2264%22 viewBox%3D%220 0 64 64%22 fill%3D%22none%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath fill-rule%3D%22evenodd%22 clip-rule%3D%22evenodd%22 d%3D%22M47.1663 32.0005C47.1667 32.6147 46.9059 33.2001 46.449 33.6106L24.7824 53.1106C23.8931 53.9111 22.5233 53.8391 21.7228 52.9498C20.9223 52.0606 20.9943 50.6907 21.8836 49.8903L41.7613 32.0005L21.8836 14.1106C21.3083 13.5928 21.053 12.8075 21.2138 12.0503C21.3746 11.2932 21.9271 10.6794 22.6632 10.4402C23.3993 10.2009 24.2071 10.3724 24.7824 10.8903L46.449 30.3903C46.9059 30.8008 47.1667 31.3862 47.1663 32.0005Z%22 fill%3D%22%23222222%22%2F%3E%3C%2Fsvg%3E"); }

.telia-react-datepicker__navButtonDisabled {
  display: inherit;
  color: #a0a0a0;
  cursor: default; }
  .telia-react-datepicker__navButtonDisabled:hover {
    opacity: 1; }

.telia-react-datepicker__weekday {
  text-transform: capitalize;
  color: #6e6e6e; }

.telia-react-datepicker__day {
  padding: 8px;
  line-height: 16px; }
  .telia-react-datepicker__day span {
    border-radius: 100%;
    display: block;
    padding: 0.5rem;
    border: 2px solid transparent; }
  .telia-react-datepicker__day:not(.telia-react-datepicker__outside):not(.telia-react-datepicker__disabled):hover:hover:hover:hover {
    font-weight: bold;
    background-color: transparent; }
    .telia-react-datepicker__day:not(.telia-react-datepicker__outside):not(.telia-react-datepicker__disabled):hover:hover:hover:hover span {
      color: #990ae3;
      background: #ffffff;
      border: 2px solid #990ae3; }

.telia-react-datepicker__today {
  color: #222222; }
  .telia-react-datepicker__today span {
    background: #f2f2f2; }

.telia-react-datepicker__selected:not(.telia-react-datepicker__outside):not(.telia-react-datepicker__disabled) {
  background: transparent;
  color: #222222;
  border-radius: 0; }
  .telia-react-datepicker__selected:not(.telia-react-datepicker__outside):not(.telia-react-datepicker__disabled) span {
    border: 2px solid #990ae3;
    background: #990ae3;
    color: #ffffff;
    font-weight: bold; }

.telia-react-datepicker__outside, .telia-react-datepicker__disabled, .telia-react-datepicker__disabledSelected {
  background: transparent;
  cursor: not-allowed; }

.telia-react-datepicker__overlay {
  left: auto;
  border-radius: 3px;
  margin-top: 4px;
  box-shadow: 0 2px 8px 0 rgba(58, 58, 58, 0.12);
  z-index: 600; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-datePickerWithRange_required {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold; }

.telia-react-datePickerWithRange_inputContainer {
  border-radius: 4px;
  box-shadow: inset 0px 0px 0px 1px #6e6e6e; }
  .telia-react-datePickerWithRange_inputContainer__error {
    box-shadow: inset 0px 0px 0px 1px #be0040; }
  .telia-react-datePickerWithRange_inputContainer__disabled {
    cursor: not-allowed;
    background-color: #f2f2f2;
    box-shadow: inset 0px 0px 0px 1px #cccccc; }

.telia-react-datePickerWithRange_fromDatePicker .telia-react-input__container {
  background-color: transparent;
  box-shadow: 0px 0px 0px 0px transparent;
  border-radius: 4px 0px 0px 4px;
  border-right: 0;
  width: 128px; }
  .telia-react-datePickerWithRange_fromDatePicker .telia-react-input__container:hover {
    box-shadow: inset 0px 0px 0px 2px #6e6e6e; }
  .telia-react-datePickerWithRange_fromDatePicker .telia-react-input__container:focus-within {
    box-shadow: inset 0px 0px 0px 2px #990ae3; }
  .telia-react-datePickerWithRange_fromDatePicker .telia-react-input__container .telia-react-input__iconWrapper {
    display: none; }
  .telia-react-datePickerWithRange_inputContainer__disabled .telia-react-datePickerWithRange_fromDatePicker .telia-react-input__container,
  .telia-react-datePickerWithRange_inputContainer__error .telia-react-datePickerWithRange_fromDatePicker .telia-react-input__container {
    box-shadow: 0px 0px 0px 0px transparent; }
  .telia-react-datePickerWithRange_inputContainer__error .telia-react-datePickerWithRange_fromDatePicker .telia-react-input__container:hover {
    box-shadow: inset 0px 0px 0px 2px #be0040; }
  .telia-react-datePickerWithRange_inputContainer__error .telia-react-datePickerWithRange_fromDatePicker .telia-react-input__container:focus-within {
    box-shadow: inset 0px 0px 0px 2px #be0040; }

.telia-react-datePickerWithRange_toDatePicker .telia-react-input__container {
  background-color: transparent;
  width: 144px;
  box-shadow: 0px 0px 0px 0px transparent;
  border-radius: 0px 4px 4px 0px;
  border-left: none; }
  .telia-react-datePickerWithRange_toDatePicker .telia-react-input__container:hover {
    box-shadow: inset 0px 0px 0px 2px #6e6e6e; }
  .telia-react-datePickerWithRange_toDatePicker .telia-react-input__container:focus-within {
    box-shadow: inset 0px 0px 0px 2px #990ae3; }
    .telia-react-datePickerWithRange_toDatePicker .telia-react-input__container:focus-within .telia-react-icon {
      color: #222222; }
  .telia-react-datePickerWithRange_inputContainer__disabled .telia-react-datePickerWithRange_toDatePicker .telia-react-input__container,
  .telia-react-datePickerWithRange_inputContainer__error .telia-react-datePickerWithRange_toDatePicker .telia-react-input__container {
    box-shadow: 0px 0px 0px 0px transparent; }
  .telia-react-datePickerWithRange_inputContainer__error .telia-react-datePickerWithRange_toDatePicker .telia-react-input__container:hover {
    box-shadow: inset 0px 0px 0px 2px #be0040; }
  .telia-react-datePickerWithRange_inputContainer__error .telia-react-datePickerWithRange_toDatePicker .telia-react-input__container:focus-within {
    box-shadow: inset 0px 0px 0px 2px #be0040; }

.telia-react-datePickerWithRange_toDatePicker .telia-react-datepicker__overlay {
  transform: translate3d(-144px, 0, 0); }

.telia-react-datePickerWithRange_toDatePicker .telia-react-inputMessage_textContainer {
  display: none; }

.telia-react-datePickerWithRange .telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside) {
  border-radius: 0;
  position: relative; }
  .telia-react-datePickerWithRange .telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside):before {
    content: '';
    position: absolute;
    background: #f9eefe;
    top: 8px;
    bottom: 8px;
    left: 0;
    right: 0; }
  .telia-react-datePickerWithRange .telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside) span {
    position: relative;
    color: #222222;
    background: none;
    font-weight: 500;
    border-color: transparent; }

.telia-react-datePickerWithRange_rangeStart.telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside):before {
  left: 50%; }

.telia-react-datePickerWithRange_rangeEnd.telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside):before {
  right: 50%; }

.telia-react-datePickerWithRange_rangeStart, .telia-react-datePickerWithRange_rangeEnd, .telia-react-datePickerWithRange_rangeStart.telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside), .telia-react-datePickerWithRange_rangeEnd.telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside) {
  background: transparent;
  color: #222222;
  border-radius: 0; }
  .telia-react-datePickerWithRange_rangeStart span, .telia-react-datePickerWithRange_rangeEnd span, .telia-react-datePickerWithRange_rangeStart.telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside) span, .telia-react-datePickerWithRange_rangeEnd.telia-react-datepicker__selected:not(.telia-react-DayPicker-Day--disabled):not(.telia-react-DayPicker-Day--outside) span {
    border: 2px solid #990ae3;
    background: #990ae3;
    color: #ffffff;
    font-weight: bold;
    border-radius: 100%; }

.telia-react-datePickerWithRange_spacer {
  height: 48px;
  width: 16px; }
  .telia-react-datePickerWithRange_inputContainer__disabled .telia-react-datePickerWithRange_spacer {
    cursor: not-allowed; }

.telia-react-datePickerWithRange_overlayWrapper {
  position: static; }

@media (max-width: 1080px) {
  .telia-react-datePickerWithRange .telia-react-datePickerWithRange {
    position: relative; } }

.telia-react-dropdown {
  position: relative;
  width: 100%; }
  .telia-react-dropdown:focus-within .telia-react-input__container {
    box-shadow: inset 0 0 0 2px #990ae3;
    transition: box-shadow 150ms ease, border 150ms ease; }
  .telia-react-dropdown:focus-within .telia-react-input__container_error {
    box-shadow: inset 0 0 0 2px #be0040; }
  .telia-react-dropdown_open .telia-react-inputMessage_textContainer {
    padding: 0; }
  .telia-react-dropdown_open .telia-react-inputMessage_text {
    display: none; }
  .telia-react-dropdown .telia-react-input,
  .telia-react-dropdown .telia-react-input input {
    cursor: pointer;
    outline: none; }
  .telia-react-dropdown .telia-react-input__container_disabled,
  .telia-react-dropdown .telia-react-input__container_disabled input {
    cursor: not-allowed; }
  .telia-react-dropdown .telia-react-optionlist {
    margin-top: -1px; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-tooltip {
  border-radius: 8px;
  position: absolute;
  background: #3f005e;
  color: #ffffff;
  padding: 12px;
  z-index: 900; }
  .telia-react-tooltip-arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #3f005e;
    position: absolute; }
  .telia-react-tooltip-placement-right .telia-react-tooltip-arrow {
    top: calc(50% - 4px);
    transform: rotate(-90deg);
    left: -12px; }
  .telia-react-tooltip-placement-left .telia-react-tooltip-arrow {
    top: calc(50% - 4px);
    transform: rotate(90deg);
    right: -12px; }
  .telia-react-tooltip-placement-top .telia-react-tooltip-arrow {
    left: calc(50% - 4px);
    transform: rotate(180deg);
    bottom: -8px; }
  .telia-react-tooltip-placement-topLeft .telia-react-tooltip-arrow {
    left: 8px;
    transform: rotate(180deg);
    bottom: -8px; }
  .telia-react-tooltip-placement-topRight .telia-react-tooltip-arrow {
    right: 8px;
    transform: rotate(180deg);
    bottom: -8px; }
  .telia-react-tooltip-placement-bottom .telia-react-tooltip-arrow {
    left: calc(50% - 4px);
    transform: rotate(0deg);
    top: -8px; }
  .telia-react-tooltip-placement-bottomLeft .telia-react-tooltip-arrow {
    left: 8px;
    transform: rotate(0deg);
    top: -8px; }
  .telia-react-tooltip-placement-bottomRight .telia-react-tooltip-arrow {
    right: 8px;
    transform: rotate(0deg);
    top: -8px; }

.telia-react-amountChip {
  color: #ffffff;
  display: inline-block;
  height: 32px;
  box-sizing: border-box;
  background-color: #990ae3; }
  .telia-react-amountChip:hover {
    background-color: #c158f8;
    cursor: pointer; }
  .telia-react-amountChip:active {
    cursor: pointer; }
  .telia-react-amountChip_disabled {
    background-color: #a0a0a0; }
  .telia-react-amountChip_disabled:hover {
    background-color: #a0a0a0;
    cursor: not-allowed; }
  .telia-react-amountChip:focus {
    outline: none; }
  .telia-react-amountChip_icon {
    height: 12px;
    width: 12px; }

.telia-react-dropdownMultiselect {
  position: relative;
  width: 100%; }
  .telia-react-dropdownMultiselect__container {
    outline: none; }
    .telia-react-dropdownMultiselect__container::-moz-focus-inner {
      border: 0; }
  .telia-react-dropdownMultiselect_error .telia-react-input__container_error {
    box-shadow: inset 0 0 0 1px #be0040; }
  .telia-react-dropdownMultiselect_open .telia-react-input__container_error {
    box-shadow: inset 0 0 0 2px #be0040; }
  .telia-react-dropdownMultiselect_open .telia-react-inputMessage_textContainer {
    padding: 0; }
  .telia-react-dropdownMultiselect_open .telia-react-inputMessage_text {
    display: none; }
  .telia-react-dropdownMultiselect .telia-react-input,
  .telia-react-dropdownMultiselect .telia-react-input input {
    cursor: pointer;
    outline: none; }
  .telia-react-dropdownMultiselect .telia-react-input__container {
    padding-left: 16px; }
  .telia-react-dropdownMultiselect .telia-react-input__container_disabled,
  .telia-react-dropdownMultiselect .telia-react-input__container_disabled input {
    cursor: not-allowed; }
  .telia-react-dropdownMultiselect:focus-within .telia-react-input__container:not(.telia-react-input__container_disabled):not(.telia-react-input__container_error) {
    box-shadow: inset 0px 0px 0px 2px #990ae3;
    transition: box-shadow 150ms ease, border 150ms ease; }
  .telia-react-dropdownMultiselect:focus-within .telia-react-input__container:not(.telia-react-input__container_disabled).telia-react-input__container_error {
    box-shadow: inset 0px 0px 0px 2px #be0040; }
    .telia-react-dropdownMultiselect:focus-within .telia-react-input__container:not(.telia-react-input__container_disabled).telia-react-input__container_error .telia-react-input__iconWrapper__icon {
      color: #be0040; }
  .telia-react-dropdownMultiselect:focus-within .telia-react-input__iconWrapper__icon {
    color: #990ae3; }
  .telia-react-dropdownMultiselect .telia-react-optionlist {
    margin-top: -1px; }

.telia-react-divider {
  border-top: 1px solid #cccccc; }

.telia-react-fileUploader_input__hidden {
  display: none; }

.telia-react-file_box__error {
  border-bottom: 4px solid #be0040; }

.telia-react-filterChip {
  display: inline-block;
  height: 32px;
  box-sizing: border-box; }
  .telia-react-filterChip:hover {
    cursor: pointer; }
  .telia-react-filterChip:active {
    cursor: pointer;
    color: #6e6e6e;
    border: 1px solid #a0a0a0; }
  .telia-react-filterChip__disabled {
    background-color: #f2f2f2;
    border: 1px solid #cccccc; }
    .telia-react-filterChip__disabled:active {
      border: 1px solid #cccccc; }
  .telia-react-filterChip__disabled:hover {
    cursor: default;
    color: #a0a0a0; }
  .telia-react-filterChip:focus {
    outline: none;
    border: 1px solid #a0a0a0;
    color: #6e6e6e; }
  .telia-react-filterChip__icon {
    font-size: 6.8px; }

.telia-react-footer__links {
  padding-bottom: 16px; }

.telia-react-footer__link:hover {
  opacity: 0.7; }

.telia-react-footer__copyright {
  margin-left: 48px; }

.telia-react-footer__bottom {
  color: rgba(255, 255, 255, 0.7); }

@media (min-width: 481px) and (max-width: 840px) {
  .telia-react-footer__copyright {
    margin-left: 32px; } }

@media (min-width: 481px) and (max-width: 1080px) {
  .telia-react-footer__link {
    padding-bottom: 24px; } }

@media (max-width: 600px) {
  .telia-react-footer__bottom {
    flex-direction: column; }
  .telia-react-footer__links {
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 24px; }
  .telia-react-footer__copyright {
    margin-left: 0;
    margin-top: 16px; }
  .telia-react-footer__link {
    padding: 0 0 24px 0; } }

.telia-react-marginSpace {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 481px) {
    .telia-react-marginSpace {
      max-width: calc(100vw - 68px + (3 * 8px)); } }
  @media (min-width: 601px) {
    .telia-react-marginSpace {
      max-width: calc(100vw - (9 * 8px) + (3 * 8px)); } }
  @media (min-width: 1081px) {
    .telia-react-marginSpace {
      max-width: calc(100vw - (24 * 8px) + (3 * 8px)); } }
  @media (min-width: 1441px) {
    .telia-react-marginSpace {
      max-width: calc(100vw - (27 * 8px) + (3 * 8px)); } }
  @media (min-width: 1921px) {
    .telia-react-marginSpace {
      max-width: calc((213 * 8px) + (3 * 8px)); } }
  @media (min-width: 841px) {
    .telia-react-marginSpace__narrow {
      max-width: 600px; } }
  @media (max-width: 600px) {
    .telia-react-marginSpace__noPaddingForSmallerThanSm {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0; }
      .telia-react-marginSpace__noPaddingForSmallerThanSm .telia-react-marginSpace_panel,
      .telia-react-marginSpace__noPaddingForSmallerThanSm .telia-react-marginSpace_panel_footer {
        border-radius: 0; } }
  @media (max-width: 840px) {
    .telia-react-marginSpace__noPaddingForSmallerThanMd {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0; }
      .telia-react-marginSpace__noPaddingForSmallerThanMd .telia-react-marginSpace_panel,
      .telia-react-marginSpace__noPaddingForSmallerThanMd .telia-react-marginSpace_panel_footer {
        border-radius: 0; } }
  .telia-react-marginSpace_panel {
    border-radius: 8px; }
    .telia-react-marginSpace_panel__separateWithBorder {
      border-top: 1px solid #cccccc; }
    .telia-react-marginSpace_panel_footer {
      border-top: 1px solid #cccccc; }
      @media (min-width: 841px) {
        .telia-react-marginSpace_panel_footer {
          border-radius: 0 0 8px 8px; } }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-growlNotification {
  left: 0;
  right: 0;
  top: 0;
  z-index: 500;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1px; }
  .telia-react-growlNotification_close {
    font-size: 16px; }
  .telia-react-growlNotification_readMore {
    font-size: 22px;
    vertical-align: middle;
    line-height: 22px; }
  .telia-react-growlNotification_readMore_hover:hover, .telia-react-growlNotification_readMore_hover:focus, .telia-react-growlNotification_close:hover, .telia-react-growlNotification_close:focus {
    opacity: 0.7;
    outline: none; }
  .telia-react-growlNotification_relative {
    position: relative; }
  .telia-react-growlNotification_fixed {
    position: fixed; }
  .telia-react-growlNotification_wrapper {
    cursor: pointer; }
  .telia-react-growlNotification_notification {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    max-height: 80px; }
    @media (min-width: 841px) {
      .telia-react-growlNotification_notification {
        -webkit-line-clamp: 2;
        max-height: 60px; } }
    @media (min-width: 1081px) {
      .telia-react-growlNotification_notification {
        -webkit-line-clamp: 3;
        max-height: 80px; } }

/* prettier-ignore */
.telia-react-gridBag_contents {
  display: flex;
  flex-wrap: wrap; }

.telia-react-gridBag_item {
  flex-shrink: 0;
  flex-grow: 0; }
  @media (max-width: 375px) {
    .telia-react-gridBag_item:not(.telia-react-noHorizontalGutter):not([data-last-col~='xxxs']) {
      margin-right: 12px; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-0'] {
      display: none; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-1']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 8.33333% + -11px);
      flex-basis: calc( 8.33333% + -11px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-1'] {
      max-width: 8.33333%;
      flex-basis: 8.33333%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-2']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 16.66667% + -10px);
      flex-basis: calc( 16.66667% + -10px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-2'] {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-3']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 25% + -9px);
      flex-basis: calc( 25% + -9px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-3'] {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-4']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 33.33333% + -8px);
      flex-basis: calc( 33.33333% + -8px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-4'] {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-5']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 41.66667% + -7px);
      flex-basis: calc( 41.66667% + -7px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-5'] {
      max-width: 41.66667%;
      flex-basis: 41.66667%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-6']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 50% + -6px);
      flex-basis: calc( 50% + -6px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-6'] {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-7']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 58.33333% + -5px);
      flex-basis: calc( 58.33333% + -5px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-7'] {
      max-width: 58.33333%;
      flex-basis: 58.33333%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-8']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 66.66667% + -4px);
      flex-basis: calc( 66.66667% + -4px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-8'] {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-9']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 75% + -3px);
      flex-basis: calc( 75% + -3px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-9'] {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-10']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 83.33333% + -2px);
      flex-basis: calc( 83.33333% + -2px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-10'] {
      max-width: 83.33333%;
      flex-basis: 83.33333%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-11']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 91.66667% + -1px);
      flex-basis: calc( 91.66667% + -1px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-11'] {
      max-width: 91.66667%;
      flex-basis: 91.66667%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-12']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 100% + 0px);
      flex-basis: calc( 100% + 0px); } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-col~='xxxs-12'] {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item:not(.telia-react-noVerticalGutter):not([data-last-row~='xxxs']) {
      margin-bottom: 12px; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-last-row~='xxxs'] {
      margin-bottom: 0; } }
  @media (max-width: 375px) {
    .telia-react-gridBag_item[data-last-col~='xxxs'] {
      margin-right: 0; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item:not(.telia-react-noHorizontalGutter):not([data-last-col~='xxs']) {
      margin-right: 12px; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-0'] {
      display: none; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-1']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 8.33333% + -11px);
      flex-basis: calc( 8.33333% + -11px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-1'] {
      max-width: 8.33333%;
      flex-basis: 8.33333%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-2']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 16.66667% + -10px);
      flex-basis: calc( 16.66667% + -10px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-2'] {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-3']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 25% + -9px);
      flex-basis: calc( 25% + -9px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-3'] {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-4']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 33.33333% + -8px);
      flex-basis: calc( 33.33333% + -8px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-4'] {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-5']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 41.66667% + -7px);
      flex-basis: calc( 41.66667% + -7px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-5'] {
      max-width: 41.66667%;
      flex-basis: 41.66667%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-6']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 50% + -6px);
      flex-basis: calc( 50% + -6px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-6'] {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-7']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 58.33333% + -5px);
      flex-basis: calc( 58.33333% + -5px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-7'] {
      max-width: 58.33333%;
      flex-basis: 58.33333%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-8']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 66.66667% + -4px);
      flex-basis: calc( 66.66667% + -4px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-8'] {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-9']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 75% + -3px);
      flex-basis: calc( 75% + -3px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-9'] {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-10']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 83.33333% + -2px);
      flex-basis: calc( 83.33333% + -2px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-10'] {
      max-width: 83.33333%;
      flex-basis: 83.33333%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-11']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 91.66667% + -1px);
      flex-basis: calc( 91.66667% + -1px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-11'] {
      max-width: 91.66667%;
      flex-basis: 91.66667%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-12']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 100% + 0px);
      flex-basis: calc( 100% + 0px); } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-col~='xxs-12'] {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item:not(.telia-react-noVerticalGutter):not([data-last-row~='xxs']) {
      margin-bottom: 12px; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-last-row~='xxs'] {
      margin-bottom: 0; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gridBag_item[data-last-col~='xxs'] {
      margin-right: 0; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item:not(.telia-react-noHorizontalGutter):not([data-last-col~='xs']) {
      margin-right: 12px; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-0'] {
      display: none; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-1']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 8.33333% + -11px);
      flex-basis: calc( 8.33333% + -11px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-1'] {
      max-width: 8.33333%;
      flex-basis: 8.33333%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-2']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 16.66667% + -10px);
      flex-basis: calc( 16.66667% + -10px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-2'] {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-3']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 25% + -9px);
      flex-basis: calc( 25% + -9px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-3'] {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-4']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 33.33333% + -8px);
      flex-basis: calc( 33.33333% + -8px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-4'] {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-5']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 41.66667% + -7px);
      flex-basis: calc( 41.66667% + -7px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-5'] {
      max-width: 41.66667%;
      flex-basis: 41.66667%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-6']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 50% + -6px);
      flex-basis: calc( 50% + -6px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-6'] {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-7']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 58.33333% + -5px);
      flex-basis: calc( 58.33333% + -5px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-7'] {
      max-width: 58.33333%;
      flex-basis: 58.33333%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-8']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 66.66667% + -4px);
      flex-basis: calc( 66.66667% + -4px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-8'] {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-9']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 75% + -3px);
      flex-basis: calc( 75% + -3px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-9'] {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-10']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 83.33333% + -2px);
      flex-basis: calc( 83.33333% + -2px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-10'] {
      max-width: 83.33333%;
      flex-basis: 83.33333%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-11']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 91.66667% + -1px);
      flex-basis: calc( 91.66667% + -1px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-11'] {
      max-width: 91.66667%;
      flex-basis: 91.66667%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-12']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 100% + 0px);
      flex-basis: calc( 100% + 0px); } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-col~='xs-12'] {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item:not(.telia-react-noVerticalGutter):not([data-last-row~='xs']) {
      margin-bottom: 12px; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-last-row~='xs'] {
      margin-bottom: 0; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gridBag_item[data-last-col~='xs'] {
      margin-right: 0; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item:not(.telia-react-noHorizontalGutter):not([data-last-col~='sm']) {
      margin-right: 16px; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-0'] {
      display: none; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-1']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 8.33333% + -14.66667px);
      flex-basis: calc( 8.33333% + -14.66667px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-1'] {
      max-width: 8.33333%;
      flex-basis: 8.33333%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-2']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 16.66667% + -13.33333px);
      flex-basis: calc( 16.66667% + -13.33333px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-2'] {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-3']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 25% + -12px);
      flex-basis: calc( 25% + -12px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-3'] {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-4']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 33.33333% + -10.66667px);
      flex-basis: calc( 33.33333% + -10.66667px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-4'] {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-5']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 41.66667% + -9.33333px);
      flex-basis: calc( 41.66667% + -9.33333px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-5'] {
      max-width: 41.66667%;
      flex-basis: 41.66667%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-6']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 50% + -8px);
      flex-basis: calc( 50% + -8px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-6'] {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-7']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 58.33333% + -6.66667px);
      flex-basis: calc( 58.33333% + -6.66667px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-7'] {
      max-width: 58.33333%;
      flex-basis: 58.33333%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-8']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 66.66667% + -5.33333px);
      flex-basis: calc( 66.66667% + -5.33333px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-8'] {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-9']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 75% + -4px);
      flex-basis: calc( 75% + -4px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-9'] {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-10']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 83.33333% + -2.66667px);
      flex-basis: calc( 83.33333% + -2.66667px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-10'] {
      max-width: 83.33333%;
      flex-basis: 83.33333%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-11']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 91.66667% + -1.33333px);
      flex-basis: calc( 91.66667% + -1.33333px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-11'] {
      max-width: 91.66667%;
      flex-basis: 91.66667%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-12']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 100% + 0px);
      flex-basis: calc( 100% + 0px); } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-col~='sm-12'] {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item:not(.telia-react-noVerticalGutter):not([data-last-row~='sm']) {
      margin-bottom: 16px; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-last-row~='sm'] {
      margin-bottom: 0; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gridBag_item[data-last-col~='sm'] {
      margin-right: 0; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item:not(.telia-react-noHorizontalGutter):not([data-last-col~='md']) {
      margin-right: 16px; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-0'] {
      display: none; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-1']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 8.33333% + -14.66667px);
      flex-basis: calc( 8.33333% + -14.66667px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-1'] {
      max-width: 8.33333%;
      flex-basis: 8.33333%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-2']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 16.66667% + -13.33333px);
      flex-basis: calc( 16.66667% + -13.33333px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-2'] {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-3']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 25% + -12px);
      flex-basis: calc( 25% + -12px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-3'] {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-4']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 33.33333% + -10.66667px);
      flex-basis: calc( 33.33333% + -10.66667px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-4'] {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-5']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 41.66667% + -9.33333px);
      flex-basis: calc( 41.66667% + -9.33333px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-5'] {
      max-width: 41.66667%;
      flex-basis: 41.66667%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-6']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 50% + -8px);
      flex-basis: calc( 50% + -8px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-6'] {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-7']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 58.33333% + -6.66667px);
      flex-basis: calc( 58.33333% + -6.66667px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-7'] {
      max-width: 58.33333%;
      flex-basis: 58.33333%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-8']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 66.66667% + -5.33333px);
      flex-basis: calc( 66.66667% + -5.33333px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-8'] {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-9']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 75% + -4px);
      flex-basis: calc( 75% + -4px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-9'] {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-10']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 83.33333% + -2.66667px);
      flex-basis: calc( 83.33333% + -2.66667px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-10'] {
      max-width: 83.33333%;
      flex-basis: 83.33333%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-11']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 91.66667% + -1.33333px);
      flex-basis: calc( 91.66667% + -1.33333px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-11'] {
      max-width: 91.66667%;
      flex-basis: 91.66667%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-12']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 100% + 0px);
      flex-basis: calc( 100% + 0px); } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-col~='md-12'] {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item:not(.telia-react-noVerticalGutter):not([data-last-row~='md']) {
      margin-bottom: 16px; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-last-row~='md'] {
      margin-bottom: 0; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gridBag_item[data-last-col~='md'] {
      margin-right: 0; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item:not(.telia-react-noHorizontalGutter):not([data-last-col~='lg']) {
      margin-right: 24px; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-0'] {
      display: none; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-1']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 8.33333% + -22px);
      flex-basis: calc( 8.33333% + -22px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-1'] {
      max-width: 8.33333%;
      flex-basis: 8.33333%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-2']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 16.66667% + -20px);
      flex-basis: calc( 16.66667% + -20px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-2'] {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-3']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 25% + -18px);
      flex-basis: calc( 25% + -18px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-3'] {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-4']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 33.33333% + -16px);
      flex-basis: calc( 33.33333% + -16px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-4'] {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-5']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 41.66667% + -14px);
      flex-basis: calc( 41.66667% + -14px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-5'] {
      max-width: 41.66667%;
      flex-basis: 41.66667%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-6']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 50% + -12px);
      flex-basis: calc( 50% + -12px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-6'] {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-7']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 58.33333% + -10px);
      flex-basis: calc( 58.33333% + -10px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-7'] {
      max-width: 58.33333%;
      flex-basis: 58.33333%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-8']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 66.66667% + -8px);
      flex-basis: calc( 66.66667% + -8px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-8'] {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-9']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 75% + -6px);
      flex-basis: calc( 75% + -6px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-9'] {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-10']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 83.33333% + -4px);
      flex-basis: calc( 83.33333% + -4px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-10'] {
      max-width: 83.33333%;
      flex-basis: 83.33333%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-11']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 91.66667% + -2px);
      flex-basis: calc( 91.66667% + -2px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-11'] {
      max-width: 91.66667%;
      flex-basis: 91.66667%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-12']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 100% + 0px);
      flex-basis: calc( 100% + 0px); } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-col~='lg-12'] {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item:not(.telia-react-noVerticalGutter):not([data-last-row~='lg']) {
      margin-bottom: 24px; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-last-row~='lg'] {
      margin-bottom: 0; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gridBag_item[data-last-col~='lg'] {
      margin-right: 0; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item:not(.telia-react-noHorizontalGutter):not([data-last-col~='xlg']) {
      margin-right: 24px; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-0'] {
      display: none; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-1']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 8.33333% + -22px);
      flex-basis: calc( 8.33333% + -22px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-1'] {
      max-width: 8.33333%;
      flex-basis: 8.33333%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-2']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 16.66667% + -20px);
      flex-basis: calc( 16.66667% + -20px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-2'] {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-3']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 25% + -18px);
      flex-basis: calc( 25% + -18px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-3'] {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-4']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 33.33333% + -16px);
      flex-basis: calc( 33.33333% + -16px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-4'] {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-5']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 41.66667% + -14px);
      flex-basis: calc( 41.66667% + -14px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-5'] {
      max-width: 41.66667%;
      flex-basis: 41.66667%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-6']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 50% + -12px);
      flex-basis: calc( 50% + -12px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-6'] {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-7']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 58.33333% + -10px);
      flex-basis: calc( 58.33333% + -10px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-7'] {
      max-width: 58.33333%;
      flex-basis: 58.33333%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-8']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 66.66667% + -8px);
      flex-basis: calc( 66.66667% + -8px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-8'] {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-9']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 75% + -6px);
      flex-basis: calc( 75% + -6px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-9'] {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-10']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 83.33333% + -4px);
      flex-basis: calc( 83.33333% + -4px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-10'] {
      max-width: 83.33333%;
      flex-basis: 83.33333%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-11']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 91.66667% + -2px);
      flex-basis: calc( 91.66667% + -2px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-11'] {
      max-width: 91.66667%;
      flex-basis: 91.66667%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-12']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 100% + 0px);
      flex-basis: calc( 100% + 0px); } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-col~='xlg-12'] {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item:not(.telia-react-noVerticalGutter):not([data-last-row~='xlg']) {
      margin-bottom: 24px; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-last-row~='xlg'] {
      margin-bottom: 0; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gridBag_item[data-last-col~='xlg'] {
      margin-right: 0; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item:not(.telia-react-noHorizontalGutter):not([data-last-col~='xxlg']) {
      margin-right: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-0'] {
      display: none; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-1']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 8.33333% + -22px);
      flex-basis: calc( 8.33333% + -22px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-1'] {
      max-width: 8.33333%;
      flex-basis: 8.33333%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-2']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 16.66667% + -20px);
      flex-basis: calc( 16.66667% + -20px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-2'] {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-3']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 25% + -18px);
      flex-basis: calc( 25% + -18px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-3'] {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-4']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 33.33333% + -16px);
      flex-basis: calc( 33.33333% + -16px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-4'] {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-5']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 41.66667% + -14px);
      flex-basis: calc( 41.66667% + -14px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-5'] {
      max-width: 41.66667%;
      flex-basis: 41.66667%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-6']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 50% + -12px);
      flex-basis: calc( 50% + -12px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-6'] {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-7']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 58.33333% + -10px);
      flex-basis: calc( 58.33333% + -10px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-7'] {
      max-width: 58.33333%;
      flex-basis: 58.33333%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-8']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 66.66667% + -8px);
      flex-basis: calc( 66.66667% + -8px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-8'] {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-9']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 75% + -6px);
      flex-basis: calc( 75% + -6px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-9'] {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-10']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 83.33333% + -4px);
      flex-basis: calc( 83.33333% + -4px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-10'] {
      max-width: 83.33333%;
      flex-basis: 83.33333%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-11']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 91.66667% + -2px);
      flex-basis: calc( 91.66667% + -2px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-11'] {
      max-width: 91.66667%;
      flex-basis: 91.66667%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-12']:not(.telia-react-noHorizontalGutter) {
      max-width: calc( 100% + 0px);
      flex-basis: calc( 100% + 0px); } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-col~='xxlg-12'] {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item:not(.telia-react-noVerticalGutter):not([data-last-row~='xxlg']) {
      margin-bottom: 24px; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-last-row~='xxlg'] {
      margin-bottom: 0; } }
  @media (min-width: 1921px) {
    .telia-react-gridBag_item[data-last-col~='xxlg'] {
      margin-right: 0; } }

@media (max-width: 600px) {
  .telia-react-gridBagRuler_container {
    margin: -12px; } }

@media (min-width: 601px) and (max-width: 1080px) {
  .telia-react-gridBagRuler_container {
    margin: -16px; } }

@media (min-width: 1081px) {
  .telia-react-gridBagRuler_container {
    margin: -24px; } }

.telia-react-gridBagRuler_table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }
  @media (max-width: 600px) {
    .telia-react-gridBagRuler_table {
      border-spacing: 12px; } }
  @media (min-width: 601px) and (max-width: 1080px) {
    .telia-react-gridBagRuler_table {
      border-spacing: 16px; } }
  @media (min-width: 1081px) {
    .telia-react-gridBagRuler_table {
      border-spacing: 24px; } }

.telia-react-gridBagRuler_noGutter {
  border-spacing: 0;
  margin: 0; }

.telia-react-gridBagRuler_cell {
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  text-align: center;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  color: #cccccc; }

.telia-react-gridLines_container {
  position: absolute;
  left: -1px;
  right: 0; }

.telia-react-gridLines_innerContainerLeft {
  position: absolute;
  left: 0; }

.telia-react-gridLines_innerContainerRight {
  position: absolute;
  right: 0; }

.telia-react-gridLines_gridLine {
  position: fixed;
  top: 0;
  height: 100vh;
  border-left: 1px dotted #cccccc; }

.telia-react-gutterBag {
  margin: -8px; }
  .telia-react-gutterBag .telia-react-gutterBag_item {
    margin: 8px; }
  .telia-react-gutterBag_itemGrow {
    flex: 1 1 0px; }
  @media (max-width: 375px) {
    .telia-react-gutterBag__fullWidth_thinnerThanXxxs .telia-react-gutterBag_item {
      width: 100%; } }
  .telia-react-gutterBag__fullWidth_widerThanXxxs .telia-react-gutterBag_item {
    width: 100%; }
  @media (max-width: 375px) {
    .telia-react-gutterBag__fullWidth_atXxxs .telia-react-gutterBag_item {
      width: 100%; } }
  @media (max-width: 480px) {
    .telia-react-gutterBag__fullWidth_thinnerThanXxs .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 376px) {
    .telia-react-gutterBag__fullWidth_widerThanXxs .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 376px) and (max-width: 480px) {
    .telia-react-gutterBag__fullWidth_atXxs .telia-react-gutterBag_item {
      width: 100%; } }
  @media (max-width: 600px) {
    .telia-react-gutterBag__fullWidth_thinnerThanXs .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 481px) {
    .telia-react-gutterBag__fullWidth_widerThanXs .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 481px) and (max-width: 600px) {
    .telia-react-gutterBag__fullWidth_atXs .telia-react-gutterBag_item {
      width: 100%; } }
  @media (max-width: 840px) {
    .telia-react-gutterBag__fullWidth_thinnerThanSm .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 601px) {
    .telia-react-gutterBag__fullWidth_widerThanSm .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 601px) and (max-width: 840px) {
    .telia-react-gutterBag__fullWidth_atSm .telia-react-gutterBag_item {
      width: 100%; } }
  @media (max-width: 1080px) {
    .telia-react-gutterBag__fullWidth_thinnerThanMd .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 841px) {
    .telia-react-gutterBag__fullWidth_widerThanMd .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 841px) and (max-width: 1080px) {
    .telia-react-gutterBag__fullWidth_atMd .telia-react-gutterBag_item {
      width: 100%; } }
  @media (max-width: 1440px) {
    .telia-react-gutterBag__fullWidth_thinnerThanLg .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 1081px) {
    .telia-react-gutterBag__fullWidth_widerThanLg .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 1081px) and (max-width: 1440px) {
    .telia-react-gutterBag__fullWidth_atLg .telia-react-gutterBag_item {
      width: 100%; } }
  @media (max-width: 1920px) {
    .telia-react-gutterBag__fullWidth_thinnerThanXlg .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 1441px) {
    .telia-react-gutterBag__fullWidth_widerThanXlg .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 1441px) and (max-width: 1920px) {
    .telia-react-gutterBag__fullWidth_atXlg .telia-react-gutterBag_item {
      width: 100%; } }
  .telia-react-gutterBag__fullWidth_thinnerThanXxlg .telia-react-gutterBag_item {
    width: 100%; }
  @media (min-width: 1921px) {
    .telia-react-gutterBag__fullWidth_widerThanXxlg .telia-react-gutterBag_item {
      width: 100%; } }
  @media (min-width: 1921px) {
    .telia-react-gutterBag__fullWidth_atXxlg .telia-react-gutterBag_item {
      width: 100%; } }
  .telia-react-gutterBag__gutterZero {
    margin: 0; }
    .telia-react-gutterBag__gutterZero .telia-react-gutterBag_item {
      margin: 0; }
  .telia-react-gutterBag__horizontalGutterZero {
    margin-left: 0;
    margin-right: 0; }
    .telia-react-gutterBag__horizontalGutterZero .telia-react-gutterBag_item {
      margin-left: 0;
      margin-right: 0; }
  .telia-react-gutterBag__verticalGutterZero {
    margin-top: 0;
    margin-bottom: 0; }
    .telia-react-gutterBag__verticalGutterZero .telia-react-gutterBag_item {
      margin-top: 0;
      margin-bottom: 0; }
  .telia-react-gutterBag__gutterXxxs {
    margin: -1px; }
    .telia-react-gutterBag__gutterXxxs .telia-react-gutterBag_item {
      margin: 1px; }
  .telia-react-gutterBag__horizontalGutterXxxs {
    margin-left: -1px;
    margin-right: -1px; }
    .telia-react-gutterBag__horizontalGutterXxxs .telia-react-gutterBag_item {
      margin-left: 1px;
      margin-right: 1px; }
  .telia-react-gutterBag__verticalGutterXxxs {
    margin-top: -1px;
    margin-bottom: -1px; }
    .telia-react-gutterBag__verticalGutterXxxs .telia-react-gutterBag_item {
      margin-top: 1px;
      margin-bottom: 1px; }
  .telia-react-gutterBag__gutterXxs {
    margin: -2px; }
    .telia-react-gutterBag__gutterXxs .telia-react-gutterBag_item {
      margin: 2px; }
  .telia-react-gutterBag__horizontalGutterXxs {
    margin-left: -2px;
    margin-right: -2px; }
    .telia-react-gutterBag__horizontalGutterXxs .telia-react-gutterBag_item {
      margin-left: 2px;
      margin-right: 2px; }
  .telia-react-gutterBag__verticalGutterXxs {
    margin-top: -2px;
    margin-bottom: -2px; }
    .telia-react-gutterBag__verticalGutterXxs .telia-react-gutterBag_item {
      margin-top: 2px;
      margin-bottom: 2px; }
  .telia-react-gutterBag__gutterXs {
    margin: -4px; }
    .telia-react-gutterBag__gutterXs .telia-react-gutterBag_item {
      margin: 4px; }
  .telia-react-gutterBag__horizontalGutterXs {
    margin-left: -4px;
    margin-right: -4px; }
    .telia-react-gutterBag__horizontalGutterXs .telia-react-gutterBag_item {
      margin-left: 4px;
      margin-right: 4px; }
  .telia-react-gutterBag__verticalGutterXs {
    margin-top: -4px;
    margin-bottom: -4px; }
    .telia-react-gutterBag__verticalGutterXs .telia-react-gutterBag_item {
      margin-top: 4px;
      margin-bottom: 4px; }
  .telia-react-gutterBag__gutterSm {
    margin: -8px; }
    .telia-react-gutterBag__gutterSm .telia-react-gutterBag_item {
      margin: 8px; }
  .telia-react-gutterBag__horizontalGutterSm {
    margin-left: -8px;
    margin-right: -8px; }
    .telia-react-gutterBag__horizontalGutterSm .telia-react-gutterBag_item {
      margin-left: 8px;
      margin-right: 8px; }
  .telia-react-gutterBag__verticalGutterSm {
    margin-top: -8px;
    margin-bottom: -8px; }
    .telia-react-gutterBag__verticalGutterSm .telia-react-gutterBag_item {
      margin-top: 8px;
      margin-bottom: 8px; }
  .telia-react-gutterBag__gutterMd {
    margin: -12px; }
    .telia-react-gutterBag__gutterMd .telia-react-gutterBag_item {
      margin: 12px; }
  .telia-react-gutterBag__horizontalGutterMd {
    margin-left: -12px;
    margin-right: -12px; }
    .telia-react-gutterBag__horizontalGutterMd .telia-react-gutterBag_item {
      margin-left: 12px;
      margin-right: 12px; }
  .telia-react-gutterBag__verticalGutterMd {
    margin-top: -12px;
    margin-bottom: -12px; }
    .telia-react-gutterBag__verticalGutterMd .telia-react-gutterBag_item {
      margin-top: 12px;
      margin-bottom: 12px; }
  .telia-react-gutterBag__gutterLg {
    margin: -16px; }
    .telia-react-gutterBag__gutterLg .telia-react-gutterBag_item {
      margin: 16px; }
  .telia-react-gutterBag__horizontalGutterLg {
    margin-left: -16px;
    margin-right: -16px; }
    .telia-react-gutterBag__horizontalGutterLg .telia-react-gutterBag_item {
      margin-left: 16px;
      margin-right: 16px; }
  .telia-react-gutterBag__verticalGutterLg {
    margin-top: -16px;
    margin-bottom: -16px; }
    .telia-react-gutterBag__verticalGutterLg .telia-react-gutterBag_item {
      margin-top: 16px;
      margin-bottom: 16px; }
  .telia-react-gutterBag__gutterXlg {
    margin: -24px; }
    .telia-react-gutterBag__gutterXlg .telia-react-gutterBag_item {
      margin: 24px; }
  .telia-react-gutterBag__horizontalGutterXlg {
    margin-left: -24px;
    margin-right: -24px; }
    .telia-react-gutterBag__horizontalGutterXlg .telia-react-gutterBag_item {
      margin-left: 24px;
      margin-right: 24px; }
  .telia-react-gutterBag__verticalGutterXlg {
    margin-top: -24px;
    margin-bottom: -24px; }
    .telia-react-gutterBag__verticalGutterXlg .telia-react-gutterBag_item {
      margin-top: 24px;
      margin-bottom: 24px; }
  .telia-react-gutterBag__gutterXxlg {
    margin: -48px; }
    .telia-react-gutterBag__gutterXxlg .telia-react-gutterBag_item {
      margin: 48px; }
  .telia-react-gutterBag__horizontalGutterXxlg {
    margin-left: -48px;
    margin-right: -48px; }
    .telia-react-gutterBag__horizontalGutterXxlg .telia-react-gutterBag_item {
      margin-left: 48px;
      margin-right: 48px; }
  .telia-react-gutterBag__verticalGutterXxlg {
    margin-top: -48px;
    margin-bottom: -48px; }
    .telia-react-gutterBag__verticalGutterXxlg .telia-react-gutterBag_item {
      margin-top: 48px;
      margin-bottom: 48px; }
  .telia-react-gutterBag__gutterLayout-0 {
    margin: 0px; }
    .telia-react-gutterBag__gutterLayout-0 .telia-react-gutterBag_item {
      margin: 0px; }
  .telia-react-gutterBag__horizontalGutterLayout-0 {
    margin-left: 0px;
    margin-right: 0px; }
    .telia-react-gutterBag__horizontalGutterLayout-0 .telia-react-gutterBag_item {
      margin-left: 0px;
      margin-right: 0px; }
  .telia-react-gutterBag__verticalGutterLayout-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
    .telia-react-gutterBag__verticalGutterLayout-0 .telia-react-gutterBag_item {
      margin-top: 0px;
      margin-bottom: 0px; }
  .telia-react-gutterBag__gutterLayout-1 {
    margin: -4px; }
    .telia-react-gutterBag__gutterLayout-1 .telia-react-gutterBag_item {
      margin: 4px; }
  .telia-react-gutterBag__horizontalGutterLayout-1 {
    margin-left: -4px;
    margin-right: -4px; }
    .telia-react-gutterBag__horizontalGutterLayout-1 .telia-react-gutterBag_item {
      margin-left: 4px;
      margin-right: 4px; }
  .telia-react-gutterBag__verticalGutterLayout-1 {
    margin-top: -4px;
    margin-bottom: -4px; }
    .telia-react-gutterBag__verticalGutterLayout-1 .telia-react-gutterBag_item {
      margin-top: 4px;
      margin-bottom: 4px; }
  .telia-react-gutterBag__gutterLayout-2 {
    margin: -6px; }
    .telia-react-gutterBag__gutterLayout-2 .telia-react-gutterBag_item {
      margin: 6px; }
  .telia-react-gutterBag__horizontalGutterLayout-2 {
    margin-left: -6px;
    margin-right: -6px; }
    .telia-react-gutterBag__horizontalGutterLayout-2 .telia-react-gutterBag_item {
      margin-left: 6px;
      margin-right: 6px; }
  .telia-react-gutterBag__verticalGutterLayout-2 {
    margin-top: -6px;
    margin-bottom: -6px; }
    .telia-react-gutterBag__verticalGutterLayout-2 .telia-react-gutterBag_item {
      margin-top: 6px;
      margin-bottom: 6px; }
  .telia-react-gutterBag__gutterLayout-3 {
    margin: -8px; }
    .telia-react-gutterBag__gutterLayout-3 .telia-react-gutterBag_item {
      margin: 8px; }
  .telia-react-gutterBag__horizontalGutterLayout-3 {
    margin-left: -8px;
    margin-right: -8px; }
    .telia-react-gutterBag__horizontalGutterLayout-3 .telia-react-gutterBag_item {
      margin-left: 8px;
      margin-right: 8px; }
  .telia-react-gutterBag__verticalGutterLayout-3 {
    margin-top: -8px;
    margin-bottom: -8px; }
    .telia-react-gutterBag__verticalGutterLayout-3 .telia-react-gutterBag_item {
      margin-top: 8px;
      margin-bottom: 8px; }
  .telia-react-gutterBag__gutterLayout-4 {
    margin: -12px; }
    .telia-react-gutterBag__gutterLayout-4 .telia-react-gutterBag_item {
      margin: 12px; }
  .telia-react-gutterBag__horizontalGutterLayout-4 {
    margin-left: -12px;
    margin-right: -12px; }
    .telia-react-gutterBag__horizontalGutterLayout-4 .telia-react-gutterBag_item {
      margin-left: 12px;
      margin-right: 12px; }
  .telia-react-gutterBag__verticalGutterLayout-4 {
    margin-top: -12px;
    margin-bottom: -12px; }
    .telia-react-gutterBag__verticalGutterLayout-4 .telia-react-gutterBag_item {
      margin-top: 12px;
      margin-bottom: 12px; }
  .telia-react-gutterBag__gutterLayout-5 {
    margin: -16px; }
    .telia-react-gutterBag__gutterLayout-5 .telia-react-gutterBag_item {
      margin: 16px; }
  .telia-react-gutterBag__horizontalGutterLayout-5 {
    margin-left: -16px;
    margin-right: -16px; }
    .telia-react-gutterBag__horizontalGutterLayout-5 .telia-react-gutterBag_item {
      margin-left: 16px;
      margin-right: 16px; }
  .telia-react-gutterBag__verticalGutterLayout-5 {
    margin-top: -16px;
    margin-bottom: -16px; }
    .telia-react-gutterBag__verticalGutterLayout-5 .telia-react-gutterBag_item {
      margin-top: 16px;
      margin-bottom: 16px; }
  .telia-react-gutterBag__gutterLayout-6 {
    margin: -24px; }
    .telia-react-gutterBag__gutterLayout-6 .telia-react-gutterBag_item {
      margin: 24px; }
  .telia-react-gutterBag__horizontalGutterLayout-6 {
    margin-left: -24px;
    margin-right: -24px; }
    .telia-react-gutterBag__horizontalGutterLayout-6 .telia-react-gutterBag_item {
      margin-left: 24px;
      margin-right: 24px; }
  .telia-react-gutterBag__verticalGutterLayout-6 {
    margin-top: -24px;
    margin-bottom: -24px; }
    .telia-react-gutterBag__verticalGutterLayout-6 .telia-react-gutterBag_item {
      margin-top: 24px;
      margin-bottom: 24px; }
  .telia-react-gutterBag__gutterLayout-7 {
    margin: -32px; }
    .telia-react-gutterBag__gutterLayout-7 .telia-react-gutterBag_item {
      margin: 32px; }
  .telia-react-gutterBag__horizontalGutterLayout-7 {
    margin-left: -32px;
    margin-right: -32px; }
    .telia-react-gutterBag__horizontalGutterLayout-7 .telia-react-gutterBag_item {
      margin-left: 32px;
      margin-right: 32px; }
  .telia-react-gutterBag__verticalGutterLayout-7 {
    margin-top: -32px;
    margin-bottom: -32px; }
    .telia-react-gutterBag__verticalGutterLayout-7 .telia-react-gutterBag_item {
      margin-top: 32px;
      margin-bottom: 32px; }
  .telia-react-gutterBag__gutterLayout-8 {
    margin: -40px; }
    .telia-react-gutterBag__gutterLayout-8 .telia-react-gutterBag_item {
      margin: 40px; }
  .telia-react-gutterBag__horizontalGutterLayout-8 {
    margin-left: -40px;
    margin-right: -40px; }
    .telia-react-gutterBag__horizontalGutterLayout-8 .telia-react-gutterBag_item {
      margin-left: 40px;
      margin-right: 40px; }
  .telia-react-gutterBag__verticalGutterLayout-8 {
    margin-top: -40px;
    margin-bottom: -40px; }
    .telia-react-gutterBag__verticalGutterLayout-8 .telia-react-gutterBag_item {
      margin-top: 40px;
      margin-bottom: 40px; }
  .telia-react-gutterBag__gutterLayout-9 {
    margin: -48px; }
    .telia-react-gutterBag__gutterLayout-9 .telia-react-gutterBag_item {
      margin: 48px; }
  .telia-react-gutterBag__horizontalGutterLayout-9 {
    margin-left: -48px;
    margin-right: -48px; }
    .telia-react-gutterBag__horizontalGutterLayout-9 .telia-react-gutterBag_item {
      margin-left: 48px;
      margin-right: 48px; }
  .telia-react-gutterBag__verticalGutterLayout-9 {
    margin-top: -48px;
    margin-bottom: -48px; }
    .telia-react-gutterBag__verticalGutterLayout-9 .telia-react-gutterBag_item {
      margin-top: 48px;
      margin-bottom: 48px; }

.telia-react-heroBanner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .telia-react-heroBanner_contentContainer {
    height: 560px; }
    @media (max-width: 1080px) {
      .telia-react-heroBanner_contentContainer {
        height: 416px; } }
    @media (max-width: 840px) {
      .telia-react-heroBanner_contentContainer {
        height: 100%; } }
    .telia-react-heroBanner_contentContainer__noDescriptionText {
      min-height: 416px; }
  .telia-react-heroBanner_descriptionContainer {
    max-width: 448px; }
  .telia-react-heroBanner_image {
    height: 464px;
    max-width: 464px;
    width: 100%; }
    @media (max-width: 1080px) {
      .telia-react-heroBanner_image {
        height: 352px;
        max-width: 352px; } }
    @media (max-width: 840px) {
      .telia-react-heroBanner_image {
        height: 288px;
        max-width: 288px; } }
    @media (max-width: 600px) {
      .telia-react-heroBanner_image {
        height: 240px;
        max-width: 240px; } }

.telia-react-imageCarousel__mainImage {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  height: 520px;
  width: 100%; }
  @media screen and (max-width: 839px) {
    .telia-react-imageCarousel__mainImage {
      height: 360px; } }
  @media screen and (max-width: 479px) {
    .telia-react-imageCarousel__mainImage {
      height: 250px; } }
  .telia-react-imageCarousel__mainImage__wrapper {
    height: 100%;
    width: 100%; }
  .telia-react-imageCarousel__mainImage__imageContainer {
    display: inline-block;
    width: 100%;
    margin-top: 69px; }
    @media screen and (max-width: 839px) {
      .telia-react-imageCarousel__mainImage__imageContainer {
        margin-top: 48px; } }
    @media screen and (max-width: 479px) {
      .telia-react-imageCarousel__mainImage__imageContainer {
        margin-top: 0; } }
  .telia-react-imageCarousel__mainImage__image {
    height: 400px; }
    @media screen and (max-width: 839px) {
      .telia-react-imageCarousel__mainImage__image {
        height: 275px; } }
    @media screen and (max-width: 479px) {
      .telia-react-imageCarousel__mainImage__image {
        height: 250px; } }

.telia-react-imageCarousel__thumbnail__small {
  background-color: #cccccc;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin: 0 2px;
  padding: 0; }
  .telia-react-imageCarousel__thumbnail__small__active {
    background-color: #990ae3; }

.telia-react-image {
  display: block;
  width: 100%;
  height: auto; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-selectionButton {
  box-shadow: 0 0 0 1px #cccccc;
  transition: box-shadow 150ms ease;
  position: relative;
  border: solid #ffffff 1px;
  min-height: 47px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 73px; }
  .telia-react-selectionButton_hiddenRadioInput {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999999;
    opacity: 0; }
  .telia-react-selectionButton::-moz-focus-inner {
    border: 0; }
  .telia-react-selectionButton:focus {
    outline: none;
    box-shadow: 0 0 0 4px #0099ff; }
  .telia-react-selectionButton:hover, .telia-react-selectionButton__notAvailable:hover {
    box-shadow: 0 0 0 2px #6e6e6e; }
  .telia-react-selectionButton__selected, .telia-react-selectionButton__selected:hover, .telia-react-selectionButton__notAvailable.telia-react-selectionButton__selected {
    box-shadow: 0 0 0 2px #990ae3; }
  .telia-react-selectionButton__notAvailable {
    box-shadow: 0 0 0 1px #cccccc; }
  .telia-react-selectionButton__disabled, .telia-react-selectionButton__disabled:hover, .telia-react-selectionButton__disabled:active, .telia-react-selectionButton__disabled:focus {
    box-shadow: 0 0 0 1px #cccccc;
    cursor: not-allowed; }
  .telia-react-selectionButton_lineContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 4px; }
  .telia-react-selectionButton .telia-react-lineImage {
    position: absolute;
    left: 0; }
  .telia-react-selectionButton .telia-react-line {
    stroke: #cccccc; }

.telia-react-inlineNotification_icon {
  margin-top: 2px; }

.telia-react-inlineNotification_spacer {
  flex: 1; }

.telia-react-inlineNotification_close {
  background: none; }

.telia-react-inlineNotification_close:hover {
  cursor: pointer; }

.telia-react-linkList {
  list-style: none; }
  .telia-react-linkList_item {
    border-bottom: 1px solid #cccccc; }
    .telia-react-linkList_item:first-child {
      border-top: 1px solid #cccccc; }
    .telia-react-linkList_item:last-child {
      border-bottom: 0; }

.telia-react-list {
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; }
  .telia-react-list__ul {
    list-style: none; }
  .telia-react-list__disc {
    list-style: disc;
    list-style-position: inside; }
  .telia-react-list__ol {
    list-style-position: inside; }
  .telia-react-list_item {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; }
    .telia-react-list_item:last-child {
      margin-bottom: 0; }

.telia-react-logo__sizeXlg {
  height: 40px;
  width: 105px; }

.telia-react-logo__sizeLg {
  height: 32px;
  width: 84px; }

.telia-react-logo__sizeMd {
  height: 24px;
  width: 63px; }

.telia-react-logo__sizeSm {
  height: 16px;
  width: 42px; }

.telia-react-logo__sizeXs {
  height: 10px;
  width: 26.25px; }

.telia-react-logo_svg {
  display: block; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-modalDialog {
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  z-index: 1000; }
  .telia-react-modalDialog_hiddenFocus {
    width: 0px;
    height: 0px;
    outline: none; }
  .telia-react-modalDialog_title {
    font-size: 26px; }
  .telia-react-modalDialog_focustrap_inner_container {
    height: 100%; }
  .telia-react-modalDialog_container {
    overflow: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .telia-react-modalDialog_container_icon {
      align-self: right; }
    .telia-react-modalDialog_container_icon:focus {
      outline: 3px solid #de99ff;
      outline-offset: 1px; }
    .telia-react-modalDialog_container_content {
      overflow: auto; }
  .telia-react-modalDialog .telia-react-backdrop {
    z-index: 1000; }
  .telia-react-modalDialog_inner_container {
    display: flex;
    width: 540px;
    margin: 32px;
    flex-direction: column;
    background: #ffffff;
    position: relative;
    z-index: 1000;
    max-height: calc(100% - 64px); }
  .telia-react-modalDialog_header_section, .telia-react-modalDialog_footer_section {
    flex: 0 0 auto; }
  .telia-react-modalDialog_content_section {
    flex: 1 1 auto;
    overflow-y: auto; }
  @media (max-width: 840px) {
    .telia-react-modalDialog_title {
      font-size: 16px; } }

.telia-react-bodyscroll__disabled {
  overflow: hidden; }

.telia-react-modalDialog_inner_container_lg {
  width: 808px; }
  @media (max-width: 840px) {
    .telia-react-modalDialog_inner_container_lg {
      width: auto; } }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-backdrop {
  background: rgba(0, 0, 0, 0.23);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  transition: opacity 0.2s, height 0s ease-out 0.2s;
  opacity: 0;
  z-index: 1; }
  .telia-react-backdrop__visible {
    opacity: 1;
    height: 100%;
    transition: opacity 0.2s, height 0s; }

.telia-react-prevent_scroll {
  overflow: hidden !important; }

.telia-react-pageRow:last-of-type {
  margin-bottom: 0; }

.telia-react-ingress__size_lg {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-ingress__size_lg {
      font-size: calc( 16px + 2 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-ingress__size_lg {
      font-size: 18px; } }

.telia-react-ingress__size_md {
  font-size: 14px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-ingress__size_md {
      font-size: calc( 14px + 2 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-ingress__size_md {
      font-size: 16px; } }

.telia-react-ingress__size_sm {
  font-size: 14px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-ingress__size_sm {
      font-size: calc( 14px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-ingress__size_sm {
      font-size: 14px; } }

.telia-react-textArea {
  /** .textarea classes amin styles */
  box-sizing: border-box;
  width: 100%;
  /* IE11 focus fixes
     focus within polyfill adds :focus-within classes to the components with :focus-within styles.
     These styles cover those focus styles because IE11 doesn't support :focus-within
  */ }
  .telia-react-textArea textarea {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 16px;
    height: 100px;
    width: 100%;
    outline: none;
    resize: none;
    border: none;
    padding: 0;
    color: #222222;
    margin: 0; }
    .telia-react-textArea textarea::placeholder {
      color: #a0a0a0; }
  .telia-react-textArea__container {
    box-shadow: inset 0px 0px 0px 1px #6e6e6e;
    transition: box-shadow 150ms ease, border 150ms ease; }
    .telia-react-textArea__container:hover {
      box-shadow: inset 0px 0px 0px 2px #6e6e6e; }
    .telia-react-textArea__container:active {
      box-shadow: inset 0px 0px 0px 2px #990ae3; }
    .telia-react-textArea__container_error {
      box-shadow: inset 0px 0px 0px 1px #be0040; }
      .telia-react-textArea__container_error:hover, .telia-react-textArea__container_error:active {
        box-shadow: inset 0px 0px 0px 2px #be0040; }
      .telia-react-textArea__container_error textarea {
        height: 100px; }
    .telia-react-textArea__container_disabled {
      box-shadow: inset 0px 0px 0px 1px #cccccc; }
      .telia-react-textArea__container_disabled:hover {
        box-shadow: inset 0px 0px 0px 1px #cccccc; }
      .telia-react-textArea__container_disabled textarea {
        cursor: not-allowed;
        color: #a0a0a0;
        background-color: #f2f2f2; }
    .telia-react-textArea__container_required {
      font-size: 14px;
      line-height: 12px;
      font-weight: bold; }
  .telia-react-textArea:focus-within .telia-react-textArea__container {
    box-shadow: inset 0px 0px 0px 2px #990ae3; }
    .telia-react-textArea:focus-within .telia-react-textArea__container_error {
      box-shadow: inset 0px 0px 0px 2px #be0040; }
  .telia-react-textArea .telia-react-textArea__container.focus-within {
    box-shadow: inset 0 0 0 2px #990ae3;
    transition: box-shadow 150ms ease, border 150ms ease;
    outline: none; }
  .telia-react-textArea .telia-react-textArea__container_error.focus-within {
    box-shadow: inset 0 0 0 2px #be0040;
    transition: box-shadow 150ms ease, border 150ms ease; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
@media (max-width: 1080px) {
  .telia-react-tabs_tabsContainer {
    justify-content: space-between; } }

.telia-react-tabs_tabHeading {
  border-bottom: 3px solid transparent;
  cursor: pointer; }
  .telia-react-tabs_tabHeading:focus {
    outline: none; }
  .telia-react-tabs_tabHeading span {
    font-size: 12px !important; }
  .telia-react-tabs_tabHeading:focus span {
    outline-offset: 7px;
    outline: solid 4px #0099ff; }
  .telia-react-tabs_tabHeading::-moz-focus-inner {
    border: 0; }
  @media (max-width: 1080px) {
    .telia-react-tabs_tabHeading {
      max-width: 45%;
      vertical-align: baseline; } }
  @media (min-width: 841px) {
    .telia-react-tabs_tabHeading:hover {
      color: #222222; } }
  .telia-react-tabs_tabHeading__active {
    border-bottom: 3px solid #990ae3; }

.telia-react-tabs_tabContent:focus {
  outline: none; }

@media (max-width: 840px) {
  .telia-react-tabs_tabContent {
    width: 100%; } }

.telia-react-phoneNumberInput {
  position: relative;
  width: 100%; }
  .telia-react-phoneNumberInput .telia-react-optionlist {
    width: 224px;
    min-width: 224px; }
  .telia-react-phoneNumberInput .telia-react-countryCodeDropDown {
    width: 112px;
    min-width: 112px; }
    .telia-react-phoneNumberInput .telia-react-countryCodeDropDown .telia-react-input__container {
      border-radius: 4px 0px 0px 4px; }
      .telia-react-phoneNumberInput .telia-react-countryCodeDropDown .telia-react-input__container input {
        text-align: left; }
  .telia-react-phoneNumberInput .telia-react-phoneNumberInputBox .telia-react-input__container {
    position: relative;
    left: -1px;
    border-radius: 0px 4px 4px 0px;
    clip-path: inset(0px 0px 0px 1px); }
    .telia-react-phoneNumberInput .telia-react-phoneNumberInputBox .telia-react-input__container:focus-within {
      clip-path: inset(0px 0px 0px 0px); }

.telia-react-price_gutter {
  min-height: 10px;
  min-width: 10px; }

.telia-react-price_sm_prefix {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_sm_prefix {
      font-size: calc( 12px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_sm_prefix {
      font-size: 12px; } }

.telia-react-price_sm_value {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_sm_value {
      font-size: calc( 16px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_sm_value {
      font-size: 16px; } }

.telia-react-price_sm_unit {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_sm_unit {
      font-size: calc( 12px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_sm_unit {
      font-size: 12px; } }

.telia-react-price_sm_oldValue {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_sm_oldValue {
      font-size: calc( 12px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_sm_oldValue {
      font-size: 12px; } }

.telia-react-price_md_prefix {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_md_prefix {
      font-size: calc( 12px + 2 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_md_prefix {
      font-size: 14px; } }

.telia-react-price_md_value {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_md_value {
      font-size: calc( 16px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_md_value {
      font-size: 24px; } }

.telia-react-price_md_unit {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_md_unit {
      font-size: calc( 12px + 4 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_md_unit {
      font-size: 16px; } }

.telia-react-price_md_oldValue {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_md_oldValue {
      font-size: calc( 12px + 4 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_md_oldValue {
      font-size: 16px; } }

.telia-react-price_lg_prefix {
  font-size: 14px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_lg_prefix {
      font-size: calc( 14px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_lg_prefix {
      font-size: 14px; } }

.telia-react-price_lg_value {
  font-size: 24px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_lg_value {
      font-size: calc( 24px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_lg_value {
      font-size: 32px; } }

.telia-react-price_lg_unit {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_lg_unit {
      font-size: calc( 16px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_lg_unit {
      font-size: 24px; } }

.telia-react-price_lg_oldValue {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_lg_oldValue {
      font-size: calc( 16px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_lg_oldValue {
      font-size: 24px; } }

.telia-react-price_xlg_prefix {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_xlg_prefix {
      font-size: calc( 16px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_xlg_prefix {
      font-size: 16px; } }

.telia-react-price_xlg_value {
  font-size: 32px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_xlg_value {
      font-size: calc( 32px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_xlg_value {
      font-size: 40px; } }

.telia-react-price_xlg_unit {
  font-size: 24px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_xlg_unit {
      font-size: calc( 24px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_xlg_unit {
      font-size: 32px; } }

.telia-react-price_xlg_oldValue {
  font-size: 24px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-price_xlg_oldValue {
      font-size: calc( 24px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-price_xlg_oldValue {
      font-size: 32px; } }

.telia-react-pricingText__size_lg {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-pricingText__size_lg {
      font-size: calc( 16px + 16 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-pricingText__size_lg {
      font-size: 32px; } }

.telia-react-pricingText__size_md {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-pricingText__size_md {
      font-size: calc( 16px + 8 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-pricingText__size_md {
      font-size: 24px; } }

.telia-react-pricingText__size_sm {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-pricingText__size_sm {
      font-size: calc( 16px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-pricingText__size_sm {
      font-size: 16px; } }

.telia-react-pricingText__size_xs {
  font-size: 12px;
  line-height: 1.5em; }
  @media screen and (min-width: 480px) {
    .telia-react-pricingText__size_xs {
      font-size: calc( 12px + 0 * (100vw - 480px) / 600); } }
  @media screen and (min-width: 1080px) {
    .telia-react-pricingText__size_xs {
      font-size: 12px; } }

.telia-react-quantitySelector {
  height: 40px;
  width: 120px;
  background-color: transparent; }
  .telia-react-quantitySelector__disabled {
    cursor: not-allowed; }
  .telia-react-quantitySelector input::-webkit-outer-spin-button,
  .telia-react-quantitySelector input::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .telia-react-quantitySelector input[type='number'] {
    -moz-appearance: textfield; }
  .telia-react-quantitySelector_input {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    color: #222222;
    background-color: #ffffff;
    font-size: 16px;
    -moz-appearance: textfield;
    border: none;
    text-align: center;
    height: 38px;
    width: 38px;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
    -webkit-appearance: none;
    padding-top: 0;
    padding-bottom: 0; }
    .telia-react-quantitySelector_input__disabled {
      color: #a0a0a0;
      background-color: #f2f2f2;
      cursor: not-allowed; }
  .telia-react-quantitySelector_number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc; }
  .telia-react-quantitySelector_button {
    border: 1px solid #cccccc;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .telia-react-quantitySelector_button:focus {
      outline: none;
      border: 4px solid #0099ff; }
    .telia-react-quantitySelector_button:hover {
      outline: none;
      background-color: #f2f2f2; }
    .telia-react-quantitySelector_button__increase {
      border-radius: 0px 999px 999px 0px; }
    .telia-react-quantitySelector_button__decrease {
      border-radius: 999px 0px 0px 999px; }
  .telia-react-quantitySelector button::-moz-focus-inner {
    border: 0; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-radioButton {
  cursor: pointer;
  position: relative; }
  .telia-react-radioButton__disabled {
    cursor: not-allowed; }
  .telia-react-radioButton:hover .telia-react-radioButton_fakeButton__unchecked {
    border: 2px solid #6e6e6e; }
    .telia-react-radioButton:hover .telia-react-radioButton_fakeButton__unchecked:focus {
      border-width: 1px; }
  .telia-react-radioButton:hover .telia-react-radioButton_fakeButton__error {
    border: 2px solid #be0040; }
    .telia-react-radioButton:hover .telia-react-radioButton_fakeButton__error:focus {
      border-width: 1px; }
  .telia-react-radioButton_hiddenButton {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999999;
    opacity: 0; }
  .telia-react-radioButton_fakeButton {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid #6e6e6e; }
    .telia-react-radioButton_fakeButton__error {
      border: 1px solid #be0040; }
    .telia-react-radioButton_fakeButton__checked {
      border-color: #990ae3; }
    .telia-react-radioButton_fakeButton:focus {
      outline: none; }
    .telia-react-radioButton_fakeButton:focus::before {
      transition: box-shadow 150ms ease;
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      border-radius: 50%;
      border: 2px solid #4dc0ff; }
    .telia-react-radioButton_fakeButton__disabled {
      border: 1px solid #cccccc !important;
      background-color: #f2f2f2; }
      .telia-react-radioButton_fakeButton__disabled:focus {
        box-shadow: none; }
  .telia-react-radioButton_fakeButtonIcon {
    height: 16px;
    width: 16px; }
  .telia-react-radioButton_description {
    font-size: 14px; }

.telia-react-inputRange {
  height: 16px;
  position: relative;
  width: 100%; }
  .telia-react-inputRange__disabled {
    height: 16px;
    position: relative;
    width: 100%;
    cursor: default; }
    .telia-react-inputRange__disabled .telia-react-inputRange_track {
      cursor: default; }
      .telia-react-inputRange__disabled .telia-react-inputRange_track .telia-react-inputRange_track_activeTrack {
        background: #cccccc; }
    .telia-react-inputRange__disabled .telia-react-inputRange_sliderContainer .telia-react-inputRange_sliderContainer_slider {
      background: #cccccc;
      border-color: #cccccc;
      cursor: default; }
      .telia-react-inputRange__disabled .telia-react-inputRange_sliderContainer .telia-react-inputRange_sliderContainer_slider:hover, .telia-react-inputRange__disabled .telia-react-inputRange_sliderContainer .telia-react-inputRange_sliderContainer_slider:active {
        box-shadow: 0 0 0 0; }
    .telia-react-inputRange__disabled .telia-react-inputRange_label {
      color: #a0a0a0; }
  .telia-react-inputRange_label {
    display: none; }
  .telia-react-inputRange_track {
    left: 0;
    right: 0;
    top: 37%;
    background: #cccccc;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    height: 4px;
    position: relative; }
    .telia-react-inputRange_track_activeTrack {
      background: #990ae3;
      height: 3px;
      position: absolute;
      bottom: 0; }
  .telia-react-inputRange_sliderContainer_slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #990ae3;
    border: 1px solid #990ae3;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 12px;
    margin-left: -0.5rem;
    margin-top: -5px;
    outline: none;
    position: absolute;
    top: 50%;
    width: 12px; }
    .telia-react-inputRange_sliderContainer_slider:hover, .telia-react-inputRange_sliderContainer_slider:active {
      box-shadow: 0 0 0 8px rgba(153, 10, 227, 0.3); }

.telia-react-searchInput:focus-within .telia-react-icon {
  color: #990ae3; }

.telia-react-searchInputWithScope {
  position: relative;
  width: 100%; }
  .telia-react-searchInputWithScope_inputContainer {
    flex-direction: column; }
    @media (min-width: 376px) {
      .telia-react-searchInputWithScope_inputContainer {
        flex-direction: row; } }
  .telia-react-searchInputWithScope_scopeDropdown {
    width: 100%;
    min-width: 14rem; }
    @media (min-width: 376px) {
      .telia-react-searchInputWithScope_scopeDropdown {
        width: calc(100% / 4); } }
    .telia-react-searchInputWithScope_scopeDropdown .telia-react-input__container {
      transition: none;
      border-radius: 4px 4px 0px 0px; }
      @media (min-width: 376px) {
        .telia-react-searchInputWithScope_scopeDropdown .telia-react-input__container {
          border-radius: 4px 0px 0px 4px; } }
  .telia-react-searchInputWithScope_searchInput {
    position: relative;
    top: -1px;
    left: auto;
    clip-path: inset(1px 0px 0px 0px); }
    @media (min-width: 376px) {
      .telia-react-searchInputWithScope_searchInput {
        top: 0;
        left: -1px;
        clip-path: inset(0px 0px 0px 1px); } }
    .telia-react-searchInputWithScope_searchInput:focus-within {
      clip-path: inset(0px 0px 0px 0px); }
    .telia-react-searchInputWithScope_searchInput .telia-react-input__container {
      border-radius: 0px 0px 4px 4px;
      border-top: none; }
      @media (min-width: 376px) {
        .telia-react-searchInputWithScope_searchInput .telia-react-input__container {
          border-radius: 0px 4px 4px 0px;
          transition: none; } }

.telia-react-combobox {
  position: relative;
  width: 100%;
  /* IE11 focus fixes
     focus within polyfill adds :focus-within classes to the components with :focus-within styles.
     These styles cover those focus styles because IE11 doesn't support :focus-within
  */ }
  .telia-react-combobox .telia-react-input,
  .telia-react-combobox .telia-react-input input {
    cursor: pointer; }
  .telia-react-combobox_open .telia-react-inputMessage_textContainer {
    padding: 0; }
  .telia-react-combobox_open .telia-react-inputMessage_text {
    display: none; }
  .telia-react-combobox_open .telia-react-input__container_error {
    box-shadow: inset 0px 0px 0px 2px #be0040; }
  .telia-react-combobox .telia-react-input__container_disabled,
  .telia-react-combobox .telia-react-input__container_disabled input {
    cursor: not-allowed; }
  .telia-react-combobox:focus-within .telia-react-input__container:not(.telia-react-input__container_error) {
    box-shadow: inset 0px 0px 0px 2px #990ae3; }
  .telia-react-combobox .telia-react-optionlist {
    margin-top: -1px; }
  .telia-react-combobox .input__container.focus-within {
    box-shadow: inset 0px 0px 0px 2px #990ae3; }
  .telia-react-combobox .icon.focus-within {
    color: #990ae3; }

.telia-react-step {
  cursor: default; }
  .telia-react-step__selectable {
    cursor: pointer; }
  .telia-react-step__contentInColumn {
    flex-direction: column; }
  .telia-react-step_border {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #a0a0a0; }
    @media (min-width: 481px) {
      .telia-react-step_border {
        width: 32px;
        min-width: 32px;
        height: 32px;
        min-height: 32px; } }
    .telia-react-step_border__selectable, .telia-react-step_border__alwaysBrightCompleted {
      border-color: #990ae3;
      background-color: transparent; }
    .telia-react-step_border__active {
      border-color: #990ae3;
      background-color: #990ae3; }
  .telia-react-step_label {
    display: flex;
    align-content: center;
    font-size: 14px;
    font-weight: bold;
    max-width: 20px;
    overflow: hidden;
    text-overflow: clip;
    color: #a0a0a0; }
    .telia-react-step_label__withIcon {
      display: block; }
    .telia-react-step_label div {
      padding: 4px; }
    @media (min-width: 481px) {
      .telia-react-step_label {
        max-width: 28px;
        font-size: 18px; } }
    .telia-react-step_label__selectable, .telia-react-step_label__alwaysBrightCompleted {
      color: #990ae3; }
    .telia-react-step_label__active {
      color: #ffffff; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-switch {
  position: relative; }
  .telia-react-switch_label {
    display: block; }
  .telia-react-switch_hiddenCheckbox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999999;
    opacity: 0; }
  .telia-react-switch_track {
    position: relative;
    cursor: pointer;
    width: 72px;
    min-width: 72px;
    height: 40px;
    transition: background-color 0.2s;
    will-change: background-color; }
  .telia-react-switch_handle {
    display: block;
    position: absolute;
    top: 2px;
    width: 36px;
    height: 36px;
    transition: transform 0.2s;
    will-change: transform; }
  .telia-react-switch__on .telia-react-switch_handle {
    transform: translateX(34px); }
  .telia-react-switch__off .telia-react-switch_handle {
    transform: translateX(2px); }

.telia-react-table_table {
  width: 100%;
  border: 0;
  border-collapse: collapse;
  border-spacing: 5em; }
  .telia-react-table_table__auto {
    table-layout: auto; }
  .telia-react-table_table__fixed {
    table-layout: fixed; }

.telia-react-table_row:hover td {
  background: #f2f2f2; }

.telia-react-table_cell {
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
  color: #222222;
  padding: 20px 16px 19px 16px; }
  .telia-react-table_cell_checkbox {
    width: 56px; }
    .telia-react-table_cell_checkbox div > span {
      display: none; }

.telia-react-table_cell__breakWord {
  word-break: break-all; }

.telia-react-table_cell_header {
  background-color: #f2f2f2;
  color: #222222;
  padding: 16px; }
  .telia-react-table_cell_header_checkbox_label {
    display: none; }

.telia-react-table_cell_heading {
  display: none; }

.telia-react-table_sort_label {
  cursor: pointer; }
  .telia-react-table_sort_label div {
    position: relative;
    margin-left: 8px;
    top: 2px; }

.telia-react-table .telia-react-table_cell_checkbox + .telia-react-table_cell_header {
  display: none; }

@media (max-width: 375px) {
  .telia-react-table_table__mobileLayout_xxxs .telia-react-table_table {
    table-layout: auto;
    border: none; }
  .telia-react-table_table__mobileLayout_xxxs .telia-react-table_row {
    display: table-row;
    padding: 16px;
    border-bottom: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_xxxs .telia-react-table_row:first-child {
      border-top: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_xxxs .telia-react-table_row:hover td {
      background: none; }
  .telia-react-table_table__mobileLayout_xxxs thead {
    display: none;
    display: table-header-group; }
    .telia-react-table_table__mobileLayout_xxxs thead th:not(.telia-react-table_cell_checkbox) {
      display: none; }
    .telia-react-table_table__mobileLayout_xxxs thead .telia-react-table_row {
      padding: 16px;
      background: #f2f2f2;
      border-bottom: none; }
    .telia-react-table_table__mobileLayout_xxxs thead .telia-react-table_cell_checkbox + .telia-react-table_cell_header {
      display: table-cell; }
  .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell {
    display: block;
    text-align: left;
    border-bottom: none;
    padding: 0;
    padding-left: 16px; }
    .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell:first-child {
      padding-top: 16px; }
    .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell:last-child {
      padding-bottom: 16px; }
    .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell_checkbox {
      vertical-align: top;
      padding: 16px;
      padding-right: 0; }
      .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell_checkbox:first-child {
        display: table-cell; }
      .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell_checkbox div > span {
        display: block; }
      .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell_checkbox + th {
        margin-top: 12px; }
      .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell_checkbox ~ th,
      .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell_checkbox ~ td {
        padding-left: 0; }
    .telia-react-table_table__mobileLayout_xxxs .telia-react-table_cell:not(th) {
      padding-bottom: 8px; } }

@media (max-width: 480px) {
  .telia-react-table_table__mobileLayout_xxs .telia-react-table_table {
    table-layout: auto;
    border: none; }
  .telia-react-table_table__mobileLayout_xxs .telia-react-table_row {
    display: table-row;
    padding: 16px;
    border-bottom: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_xxs .telia-react-table_row:first-child {
      border-top: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_xxs .telia-react-table_row:hover td {
      background: none; }
  .telia-react-table_table__mobileLayout_xxs thead {
    display: none;
    display: table-header-group; }
    .telia-react-table_table__mobileLayout_xxs thead th:not(.telia-react-table_cell_checkbox) {
      display: none; }
    .telia-react-table_table__mobileLayout_xxs thead .telia-react-table_row {
      padding: 16px;
      background: #f2f2f2;
      border-bottom: none; }
    .telia-react-table_table__mobileLayout_xxs thead .telia-react-table_cell_checkbox + .telia-react-table_cell_header {
      display: table-cell; }
  .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell {
    display: block;
    text-align: left;
    border-bottom: none;
    padding: 0;
    padding-left: 16px; }
    .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell:first-child {
      padding-top: 16px; }
    .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell:last-child {
      padding-bottom: 16px; }
    .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell_checkbox {
      vertical-align: top;
      padding: 16px;
      padding-right: 0; }
      .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell_checkbox:first-child {
        display: table-cell; }
      .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell_checkbox div > span {
        display: block; }
      .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell_checkbox + th {
        margin-top: 12px; }
      .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell_checkbox ~ th,
      .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell_checkbox ~ td {
        padding-left: 0; }
    .telia-react-table_table__mobileLayout_xxs .telia-react-table_cell:not(th) {
      padding-bottom: 8px; } }

@media (max-width: 600px) {
  .telia-react-table_table__mobileLayout_xs .telia-react-table_table {
    table-layout: auto;
    border: none; }
  .telia-react-table_table__mobileLayout_xs .telia-react-table_row {
    display: table-row;
    padding: 16px;
    border-bottom: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_xs .telia-react-table_row:first-child {
      border-top: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_xs .telia-react-table_row:hover td {
      background: none; }
  .telia-react-table_table__mobileLayout_xs thead {
    display: none;
    display: table-header-group; }
    .telia-react-table_table__mobileLayout_xs thead th:not(.telia-react-table_cell_checkbox) {
      display: none; }
    .telia-react-table_table__mobileLayout_xs thead .telia-react-table_row {
      padding: 16px;
      background: #f2f2f2;
      border-bottom: none; }
    .telia-react-table_table__mobileLayout_xs thead .telia-react-table_cell_checkbox + .telia-react-table_cell_header {
      display: table-cell; }
  .telia-react-table_table__mobileLayout_xs .telia-react-table_cell {
    display: block;
    text-align: left;
    border-bottom: none;
    padding: 0;
    padding-left: 16px; }
    .telia-react-table_table__mobileLayout_xs .telia-react-table_cell:first-child {
      padding-top: 16px; }
    .telia-react-table_table__mobileLayout_xs .telia-react-table_cell:last-child {
      padding-bottom: 16px; }
    .telia-react-table_table__mobileLayout_xs .telia-react-table_cell_checkbox {
      vertical-align: top;
      padding: 16px;
      padding-right: 0; }
      .telia-react-table_table__mobileLayout_xs .telia-react-table_cell_checkbox:first-child {
        display: table-cell; }
      .telia-react-table_table__mobileLayout_xs .telia-react-table_cell_checkbox div > span {
        display: block; }
      .telia-react-table_table__mobileLayout_xs .telia-react-table_cell_checkbox + th {
        margin-top: 12px; }
      .telia-react-table_table__mobileLayout_xs .telia-react-table_cell_checkbox ~ th,
      .telia-react-table_table__mobileLayout_xs .telia-react-table_cell_checkbox ~ td {
        padding-left: 0; }
    .telia-react-table_table__mobileLayout_xs .telia-react-table_cell:not(th) {
      padding-bottom: 8px; } }

@media (max-width: 840px) {
  .telia-react-table_table__mobileLayout_sm .telia-react-table_table {
    table-layout: auto;
    border: none; }
  .telia-react-table_table__mobileLayout_sm .telia-react-table_row {
    display: table-row;
    padding: 16px;
    border-bottom: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_sm .telia-react-table_row:first-child {
      border-top: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_sm .telia-react-table_row:hover td {
      background: none; }
  .telia-react-table_table__mobileLayout_sm thead {
    display: none;
    display: table-header-group; }
    .telia-react-table_table__mobileLayout_sm thead th:not(.telia-react-table_cell_checkbox) {
      display: none; }
    .telia-react-table_table__mobileLayout_sm thead .telia-react-table_row {
      padding: 16px;
      background: #f2f2f2;
      border-bottom: none; }
    .telia-react-table_table__mobileLayout_sm thead .telia-react-table_cell_checkbox + .telia-react-table_cell_header {
      display: table-cell; }
  .telia-react-table_table__mobileLayout_sm .telia-react-table_cell {
    display: block;
    text-align: left;
    border-bottom: none;
    padding: 0;
    padding-left: 16px; }
    .telia-react-table_table__mobileLayout_sm .telia-react-table_cell:first-child {
      padding-top: 16px; }
    .telia-react-table_table__mobileLayout_sm .telia-react-table_cell:last-child {
      padding-bottom: 16px; }
    .telia-react-table_table__mobileLayout_sm .telia-react-table_cell_checkbox {
      vertical-align: top;
      padding: 16px;
      padding-right: 0; }
      .telia-react-table_table__mobileLayout_sm .telia-react-table_cell_checkbox:first-child {
        display: table-cell; }
      .telia-react-table_table__mobileLayout_sm .telia-react-table_cell_checkbox div > span {
        display: block; }
      .telia-react-table_table__mobileLayout_sm .telia-react-table_cell_checkbox + th {
        margin-top: 12px; }
      .telia-react-table_table__mobileLayout_sm .telia-react-table_cell_checkbox ~ th,
      .telia-react-table_table__mobileLayout_sm .telia-react-table_cell_checkbox ~ td {
        padding-left: 0; }
    .telia-react-table_table__mobileLayout_sm .telia-react-table_cell:not(th) {
      padding-bottom: 8px; } }

@media (max-width: 1080px) {
  .telia-react-table_table__mobileLayout_md .telia-react-table_table {
    table-layout: auto;
    border: none; }
  .telia-react-table_table__mobileLayout_md .telia-react-table_row {
    display: table-row;
    padding: 16px;
    border-bottom: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_md .telia-react-table_row:first-child {
      border-top: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_md .telia-react-table_row:hover td {
      background: none; }
  .telia-react-table_table__mobileLayout_md thead {
    display: none;
    display: table-header-group; }
    .telia-react-table_table__mobileLayout_md thead th:not(.telia-react-table_cell_checkbox) {
      display: none; }
    .telia-react-table_table__mobileLayout_md thead .telia-react-table_row {
      padding: 16px;
      background: #f2f2f2;
      border-bottom: none; }
    .telia-react-table_table__mobileLayout_md thead .telia-react-table_cell_checkbox + .telia-react-table_cell_header {
      display: table-cell; }
  .telia-react-table_table__mobileLayout_md .telia-react-table_cell {
    display: block;
    text-align: left;
    border-bottom: none;
    padding: 0;
    padding-left: 16px; }
    .telia-react-table_table__mobileLayout_md .telia-react-table_cell:first-child {
      padding-top: 16px; }
    .telia-react-table_table__mobileLayout_md .telia-react-table_cell:last-child {
      padding-bottom: 16px; }
    .telia-react-table_table__mobileLayout_md .telia-react-table_cell_checkbox {
      vertical-align: top;
      padding: 16px;
      padding-right: 0; }
      .telia-react-table_table__mobileLayout_md .telia-react-table_cell_checkbox:first-child {
        display: table-cell; }
      .telia-react-table_table__mobileLayout_md .telia-react-table_cell_checkbox div > span {
        display: block; }
      .telia-react-table_table__mobileLayout_md .telia-react-table_cell_checkbox + th {
        margin-top: 12px; }
      .telia-react-table_table__mobileLayout_md .telia-react-table_cell_checkbox ~ th,
      .telia-react-table_table__mobileLayout_md .telia-react-table_cell_checkbox ~ td {
        padding-left: 0; }
    .telia-react-table_table__mobileLayout_md .telia-react-table_cell:not(th) {
      padding-bottom: 8px; } }

@media (max-width: 1440px) {
  .telia-react-table_table__mobileLayout_lg .telia-react-table_table {
    table-layout: auto;
    border: none; }
  .telia-react-table_table__mobileLayout_lg .telia-react-table_row {
    display: table-row;
    padding: 16px;
    border-bottom: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_lg .telia-react-table_row:first-child {
      border-top: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_lg .telia-react-table_row:hover td {
      background: none; }
  .telia-react-table_table__mobileLayout_lg thead {
    display: none;
    display: table-header-group; }
    .telia-react-table_table__mobileLayout_lg thead th:not(.telia-react-table_cell_checkbox) {
      display: none; }
    .telia-react-table_table__mobileLayout_lg thead .telia-react-table_row {
      padding: 16px;
      background: #f2f2f2;
      border-bottom: none; }
    .telia-react-table_table__mobileLayout_lg thead .telia-react-table_cell_checkbox + .telia-react-table_cell_header {
      display: table-cell; }
  .telia-react-table_table__mobileLayout_lg .telia-react-table_cell {
    display: block;
    text-align: left;
    border-bottom: none;
    padding: 0;
    padding-left: 16px; }
    .telia-react-table_table__mobileLayout_lg .telia-react-table_cell:first-child {
      padding-top: 16px; }
    .telia-react-table_table__mobileLayout_lg .telia-react-table_cell:last-child {
      padding-bottom: 16px; }
    .telia-react-table_table__mobileLayout_lg .telia-react-table_cell_checkbox {
      vertical-align: top;
      padding: 16px;
      padding-right: 0; }
      .telia-react-table_table__mobileLayout_lg .telia-react-table_cell_checkbox:first-child {
        display: table-cell; }
      .telia-react-table_table__mobileLayout_lg .telia-react-table_cell_checkbox div > span {
        display: block; }
      .telia-react-table_table__mobileLayout_lg .telia-react-table_cell_checkbox + th {
        margin-top: 12px; }
      .telia-react-table_table__mobileLayout_lg .telia-react-table_cell_checkbox ~ th,
      .telia-react-table_table__mobileLayout_lg .telia-react-table_cell_checkbox ~ td {
        padding-left: 0; }
    .telia-react-table_table__mobileLayout_lg .telia-react-table_cell:not(th) {
      padding-bottom: 8px; } }

@media (max-width: 1920px) {
  .telia-react-table_table__mobileLayout_xlg .telia-react-table_table {
    table-layout: auto;
    border: none; }
  .telia-react-table_table__mobileLayout_xlg .telia-react-table_row {
    display: table-row;
    padding: 16px;
    border-bottom: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_xlg .telia-react-table_row:first-child {
      border-top: 1px solid #f2f2f2; }
    .telia-react-table_table__mobileLayout_xlg .telia-react-table_row:hover td {
      background: none; }
  .telia-react-table_table__mobileLayout_xlg thead {
    display: none;
    display: table-header-group; }
    .telia-react-table_table__mobileLayout_xlg thead th:not(.telia-react-table_cell_checkbox) {
      display: none; }
    .telia-react-table_table__mobileLayout_xlg thead .telia-react-table_row {
      padding: 16px;
      background: #f2f2f2;
      border-bottom: none; }
    .telia-react-table_table__mobileLayout_xlg thead .telia-react-table_cell_checkbox + .telia-react-table_cell_header {
      display: table-cell; }
  .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell {
    display: block;
    text-align: left;
    border-bottom: none;
    padding: 0;
    padding-left: 16px; }
    .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell:first-child {
      padding-top: 16px; }
    .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell:last-child {
      padding-bottom: 16px; }
    .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell_checkbox {
      vertical-align: top;
      padding: 16px;
      padding-right: 0; }
      .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell_checkbox:first-child {
        display: table-cell; }
      .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell_checkbox div > span {
        display: block; }
      .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell_checkbox + th {
        margin-top: 12px; }
      .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell_checkbox ~ th,
      .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell_checkbox ~ td {
        padding-left: 0; }
    .telia-react-table_table__mobileLayout_xlg .telia-react-table_cell:not(th) {
      padding-bottom: 8px; } }

.telia-react-tagText__size_md {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.34px; }

.telia-react-tagText__size_sm {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.28px; }

.telia-react-tag__text {
  margin: 1px 0 1px 0; }

.telia-react-progressBar {
  border-radius: 8px;
  height: 8px; }
  .telia-react-progressBar_background {
    border-radius: 8px;
    background-color: #cccccc; }

.telia-react-userDropdown_container__relative {
  position: relative; }

@media (max-width: 840px) {
  .telia-react-userDropdown_container__mobileResponsive {
    position: static; } }

.telia-react-userDropdown_backdrop {
  z-index: z("dropdown");
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); }

.telia-react-userDropdown_items {
  z-index: z("dropdown");
  position: absolute;
  right: 0;
  transition: height 0.2s ease-out; }
  @media (max-width: 840px) {
    .telia-react-userDropdown_items__mobileResponsive {
      width: 100vw; } }
  .telia-react-userDropdown_items__nowrap {
    white-space: nowrap; }

.telia-react-userDropdown__borderBottom {
  border-bottom: 2px solid #cccccc; }

.telia-react-selectionButtonGroup_button {
  margin: 0 8px 8px 0; }

.telia-react-pop_on_hover {
  transition: box-shadow 0.2s, transform 0.2s; }
  .telia-react-pop_on_hover:hover {
    transform: scale(1.02); }

.telia-react-skeleton {
  overflow: hidden;
  position: relative;
  background: #f2f2f2;
  width: 100%; }
  .telia-react-skeleton::after {
    display: block;
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(192, 192, 192, 0.2), transparent);
    animation: telia-react-loading 1.4s infinite; }
  .telia-react-skeleton__height__xxs {
    height: 4px; }
  .telia-react-skeleton__height__xs {
    height: 16px; }
  .telia-react-skeleton__height__sm {
    height: 24px; }
  .telia-react-skeleton__height__md {
    height: 32px; }
  .telia-react-skeleton__height__lg {
    height: 64px; }
  .telia-react-skeleton__height__xlg {
    height: 96px; }
  .telia-react-skeleton__height__xxlg {
    height: 128px; }
  .telia-react-skeleton__width__xxs {
    width: 4px; }
  .telia-react-skeleton__width__xs {
    width: 16px; }
  .telia-react-skeleton__width__sm {
    width: 24px; }
  .telia-react-skeleton__width__md {
    width: 32px; }
  .telia-react-skeleton__width__lg {
    width: 64px; }
  .telia-react-skeleton__width__xlg {
    width: 96px; }
  .telia-react-skeleton__width__xxlg {
    width: 128px; }

@keyframes telia-react-loading {
  100% {
    transform: translateX(100%); } }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-drawer {
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.15); }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-overflowMenu {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  user-select: none; }
  .telia-react-overflowMenu_iconWrapper {
    width: 32px;
    height: 32px; }
    .telia-react-overflowMenu_iconWrapper:hover {
      background-color: #cccccc; }
    .telia-react-overflowMenu_iconWrapper:focus {
      border: 4px solid #0099ff;
      outline: none; }
  .telia-react-overflowMenu_content {
    position: absolute;
    top: 40px;
    z-index: 600;
    background-color: #ffffff;
    border: 1px solid #f2f2f2; }
    .telia-react-overflowMenu_content__left {
      left: 0; }
    .telia-react-overflowMenu_content__right {
      right: 0; }

.telia-react-overflowMenuItem__disabled {
  color: #cccccc; }
  .telia-react-overflowMenuItem__disabled:hover {
    color: #cccccc;
    background-color: #ffffff;
    cursor: initial; }

.telia-react-overflowMenuItem:focus {
  outline-offset: -4px;
  outline: 4px solid #0099ff; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-cart {
  display: block;
  position: relative;
  /* This needs to be block for the focus outline to display properly in Firefox. The Link component does not provide access to this inner element. */ }
  .telia-react-cart > span {
    display: block;
    line-height: 0; }
  .telia-react-cart:focus {
    outline-offset: 3px;
    outline: solid 4px #0099ff; }
  .telia-react-cart_amount {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    line-height: 0; }
  .telia-react-cart_amountNumber {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-weight: bold;
    padding-left: 1px;
    font-size: 11px;
    margin-left: 4px;
    margin-top: -4px;
    color: #ffffff;
    z-index: 1; }

.telia-react-topBar {
  height: 38px; }

.telia-react-siteLinks_siteLink {
  border-bottom: solid 3px transparent; }
  @media (min-width: 841px) {
    .telia-react-siteLinks_siteLink {
      border-bottom: solid 2px transparent; } }
  .telia-react-siteLinks__forceDesktop .telia-react-siteLinks_siteLink {
    border-bottom: solid 2px transparent; }
  .telia-react-siteLinks_siteLink__active {
    border-bottom: solid 3px #990ae3; }
    @media (min-width: 841px) {
      .telia-react-siteLinks_siteLink__active {
        border-bottom: solid 2px #990ae3; } }
    .telia-react-siteLinks__forceDesktop .telia-react-siteLinks_siteLink__active {
      border-bottom: solid 2px #990ae3; }

.telia-react-siteLinks_link {
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
  line-height: 42px; }
  .telia-react-siteLinks_link:focus {
    outline-offset: 5px;
    outline: solid 4px #0099ff; }
  @media (min-width: 841px) {
    .telia-react-siteLinks_link {
      line-height: 23px;
      font-size: 11px; } }
  .telia-react-siteLinks__forceDesktop .telia-react-siteLinks_link {
    line-height: 23px;
    font-size: 11px; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-search ::-moz-focus-inner {
  border: 0; }

.telia-react-search_inputWrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  min-width: 0;
  width: 48px;
  background-color: #ffffff;
  margin-right: -8px; }
  @media (min-width: 841px) {
    .telia-react-search_inputWrapper {
      transition: width 0.2s, min-width 0.2s; } }
  .telia-react-search_inputWrapper__active {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    min-width: 310px;
    width: 100%;
    padding: 0 13px 0 15px;
    z-index: 100;
    height: 72px; }
    @media (min-width: 841px) {
      .telia-react-search_inputWrapper__active {
        position: relative;
        display: block;
        border-radius: 999px;
        padding: 0 40px;
        width: 310px;
        height: auto; } }

.telia-react-search_icon {
  cursor: pointer;
  background-color: white;
  left: 8px;
  position: absolute;
  top: 1px;
  line-height: 0; }
  .telia-react-search_icon > svg {
    box-sizing: content-box;
    padding: 12px; }
  .telia-react-search_icon:focus {
    outline-offset: -8px;
    outline: solid 4px #0099ff; }
  .telia-react-search_inputWrapper__active .telia-react-search_icon {
    position: relative;
    top: 0;
    left: 0; }
    .telia-react-search_inputWrapper__active .telia-react-search_icon > svg {
      padding: 5px; }
    .telia-react-search_inputWrapper__active .telia-react-search_icon:focus {
      outline-offset: -1px;
      outline: solid 4px #0099ff; }
    @media (min-width: 841px) {
      .telia-react-search_inputWrapper__active .telia-react-search_icon {
        position: absolute;
        top: 7px;
        left: 10px; } }

.telia-react-search_closeIcon {
  cursor: pointer;
  padding: 0;
  top: 2px;
  right: 6px;
  position: absolute;
  background-color: transparent;
  z-index: -999999; }
  .telia-react-search_closeIcon:focus {
    outline-offset: -6px;
    outline: solid 4px #0099ff; }
  .telia-react-search_inputWrapper__active .telia-react-search_closeIcon {
    position: relative;
    top: 0;
    right: 0; }
    @media (min-width: 841px) {
      .telia-react-search_inputWrapper__active .telia-react-search_closeIcon {
        position: absolute;
        top: 7px;
        right: 10px; } }

.telia-react-search_input {
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding: 8px;
  outline: none; }
  .telia-react-search_inputWrapper__active .telia-react-search_input {
    height: 45px; }

.telia-react-search_overlay {
  background-color: rgba(34, 34, 34, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  transition: opacity 0.2s, height 0s ease-out 0.2s;
  opacity: 0;
  z-index: 100; }
  @media (min-width: 841px) {
    .telia-react-search_overlay {
      z-index: 1; } }
  .telia-react-search_overlay__visible {
    opacity: 1;
    height: 100%;
    transition: opacity 0.2s, height 0s; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
:global #addsearch-results#addsearch-results.addsearch-d, :global #addsearch-results#addsearch-results.addsearch-t, :global #addsearch-results#addsearch-results.addsearch-m2 {
  width: 100% !important;
  left: 0 !important;
  margin-left: 0 !important;
  border: none !important;
  margin-top: 14px !important; }
  @media (min-width: 841px) {
    :global #addsearch-results#addsearch-results.addsearch-d, :global #addsearch-results#addsearch-results.addsearch-t, :global #addsearch-results#addsearch-results.addsearch-m2 {
      width: 474px !important;
      right: 0px !important;
      left: auto !important; } }
  @media (min-width: 1081px) {
    :global #addsearch-results#addsearch-results.addsearch-d, :global #addsearch-results#addsearch-results.addsearch-t, :global #addsearch-results#addsearch-results.addsearch-m2 {
      width: 494px !important;
      right: 40px !important; } }
  @media (min-width: 1441px) {
    :global #addsearch-results#addsearch-results.addsearch-d, :global #addsearch-results#addsearch-results.addsearch-t, :global #addsearch-results#addsearch-results.addsearch-m2 {
      right: 52px !important; } }
  @media (min-width: 1921px) {
    :global #addsearch-results#addsearch-results.addsearch-d, :global #addsearch-results#addsearch-results.addsearch-t, :global #addsearch-results#addsearch-results.addsearch-m2 {
      right: calc((100vw - 1920px) / 2 + 51px) !important; } }

:global #addsearch-results #addsearch-results-right {
  display: none !important; }

:global #addsearch-results #addsearch-results-mid {
  width: auto !important;
  background: none; }

:global #addsearch-results #addsearch-results-bottom {
  display: none; }

:global #addsearch-results #addsearch-fuzzy {
  width: auto !important; }

:global #addsearch-results .addsearch-result-item-sub {
  border: none !important;
  border-bottom: solid 1px #f2f2f2 !important;
  background-size: 68px auto !important;
  background-position: 24px 50% !important;
  width: auto !important;
  min-height: 128px !important; }
  :global #addsearch-results .addsearch-result-item-sub div {
    padding: 0 0 0 116px !important;
    min-height: 128px !important;
    width: auto !important; }
  :global #addsearch-results .addsearch-result-item-sub em {
    color: #6e6e6e !important;
    font-weight: normal !important; }
  :global #addsearch-results .addsearch-result-item-sub h2 {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    text-transform: none !important; }
    :global #addsearch-results .addsearch-result-item-sub h2 a {
      color: #222222 !important;
      padding: 16px 0 8px 0 !important; }
  :global #addsearch-results .addsearch-result-item-sub p {
    color: #6e6e6e !important; }
  :global #addsearch-results .addsearch-result-item-sub .addsearch_category {
    margin-bottom: 16px !important; }

:global #addsearch-results .addsearch-result-item-container {
  background: none !important; }

:global #addsearch-results #addsearch-top-close {
  display: none !important; }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-login {
  background-color: #ffffff;
  overflow-y: auto;
  max-height: 80vh; }
  .telia-react-login_heading {
    display: block;
    padding: 24px 24px 32px;
    text-transform: none; }
    @media (max-width: 1080px) {
      .telia-react-login_heading {
        font-size: 24px; } }
    @media (min-width: 841px) {
      .telia-react-login_heading {
        max-width: 532px;
        padding: 41px 0 36px;
        margin: 0 auto; } }
  @media (max-width: 1080px) {
    .telia-react-login_tabs__container {
      padding: 0 24px; } }
  @media (min-width: 841px) {
    .telia-react-login_tabs__container {
      max-width: 532px;
      margin: 0 auto; } }
  @media (max-width: 1080px) {
    .telia-react-login_additionalLinkContent {
      padding: 15px 24px; } }
  @media (min-width: 841px) {
    .telia-react-login_additionalLinkContent {
      max-width: 532px;
      margin: 0 auto;
      padding: 15px 0px; } }

.telia-react-userMenu_username {
  padding: 18px 24px 0;
  word-break: break-all; }

.telia-react-userMenu_userloginHistory {
  padding: 6px 24px 18px;
  word-break: break-all; }

.telia-react-userMenu_link {
  margin: 0 24px; }

.telia-react-userMenu_logoutButton {
  cursor: pointer;
  padding: 15px 24px; }

.telia-react-userMenu_additionalLinkContent {
  padding: 15px 24px; }

.telia-react-menu ::-moz-focus-inner {
  border: 0; }

.telia-react-menu_item > .telia-react-menu {
  overflow: hidden;
  flex-direction: column;
  display: flex;
  max-height: 0;
  transition: max-height 0.2s, transform 0s ease-out 0.2s;
  transform: scale(0); }
  @media (min-width: 841px) {
    .telia-react-menu_item > .telia-react-menu {
      overflow: visible;
      transform-origin: 0 0;
      transition: transform 0.2s;
      transform: scaleY(0); }
      .telia-react-menu_item > .telia-react-menu__level3 {
        transform: scaleX(0); } }

.telia-react-menu_item__selected > .telia-react-menu {
  max-height: 1000px;
  transform: scale(1);
  transition: max-height 0.2s, transform 0s ease-out 0s; }
  @media (min-width: 841px) {
    .telia-react-menu_item__selected > .telia-react-menu {
      overflow: visible;
      transition: transform 0.2s;
      transform: scaleY(1);
      max-height: none; }
      .telia-react-menu_item__selected > .telia-react-menu__level3 {
        transform: scaleX(1); } }

.telia-react-menu__level1 {
  flex-direction: column;
  position: relative; }
  @media (min-width: 841px) {
    .telia-react-menu__level1 {
      flex-direction: row;
      flex-wrap: wrap;
      height: 40px;
      margin-top: 5px; } }

.telia-react-menu__level2 {
  position: relative; }
  @media (min-width: 841px) {
    .telia-react-menu__level2 {
      position: absolute;
      top: 52px;
      left: -12px; } }

.telia-react-menu__level3 {
  position: relative; }
  @media (min-width: 841px) {
    .telia-react-menu__level3 {
      position: absolute;
      top: 0px;
      left: 230px; } }

.telia-react-menu_item {
  background-color: red;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  transition: background-color 0.1s ease-out 0.05s; }
  @media (min-width: 841px) {
    .telia-react-menu_item {
      transition: background-color 0s; } }
  .telia-react-menu_item__level1 {
    position: relative;
    color: #222222;
    border-bottom: solid 1px #cccccc;
    overflow: hidden;
    background-color: #f2f2f2; }
    .telia-react-menu_item__level1.telia-react-menu_item__selected {
      overflow: visible;
      background-color: #ffffff;
      transition: background-color 0s; }
    @media (min-width: 841px) {
      .telia-react-menu_item__level1 {
        height: 40px;
        border-bottom: none;
        overflow: visible;
        background-color: #ffffff; } }
  .telia-react-menu_item__level2 {
    line-height: 20px;
    padding: 0;
    background-color: #ffffff; }
    @media (max-width: 840px) {
      .telia-react-menu_item__level2:last-child {
        padding-bottom: 16px; }
        .telia-react-menu_item__level2:last-child .telia-react-menu_item:last-child {
          padding-bottom: 0px; } }
    @media (min-width: 841px) {
      .telia-react-menu_item__level2 {
        line-height: 20px;
        border-bottom: solid 1px #cccccc;
        background-color: #ffffff; }
        .telia-react-menu_item__level2.telia-react-menu_item__selected {
          background-color: #fbfbfb; }
        .telia-react-menu_item__level2:hover {
          background-color: #f2f2f2; }
        .telia-react-menu_item__level2:last-child {
          border-bottom: none; } }
  .telia-react-menu_item__level3 {
    line-height: 20px;
    padding: 0;
    background-color: #ffffff; }
    @media (max-width: 840px) {
      .telia-react-menu_item__level3:last-child {
        padding-bottom: 16px; } }
    @media (min-width: 841px) {
      .telia-react-menu_item__level3 {
        line-height: 20px;
        border-bottom: solid 1px #cccccc;
        background-color: #fbfbfb; }
        .telia-react-menu_item__level3:hover {
          background-color: #f2f2f2; }
        .telia-react-menu_item__level3:last-child {
          border-bottom: none; } }

.telia-react-menu_itemButton {
  background-color: transparent;
  width: 100%; }
  .telia-react-menu_itemButton__level1 {
    line-height: 70px; }
  .telia-react-menu_itemButton:focus {
    outline-offset: -4px;
    outline: solid 4px #0099ff; }

.telia-react-menu_itemText {
  background-color: transparent;
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  display: flex;
  box-sizing: border-box;
  align-items: baseline;
  justify-content: space-between;
  color: #222222;
  user-select: none;
  cursor: pointer;
  text-align: left; }
  .telia-react-menu_itemText:focus {
    outline-offset: -4px;
    outline: solid 4px #0099ff; }
  .telia-react-menu_itemText__level1 {
    padding: 0;
    font-size: 18px;
    font-weight: 500; }
    @media (min-width: 841px) {
      .telia-react-menu_itemText__level1 {
        padding: 0 12px;
        font-size: 16px;
        font-weight: normal;
        line-height: 35px; } }
    @media (min-width: 841px) {
      .telia-react-menu_item__level1.telia-react-menu_item__selected .telia-react-menu_itemText__level1 {
        color: #990ae3; } }
  .telia-react-menu_itemText__level2 {
    font-size: 14px;
    font-weight: 500;
    border-top: solid 1px #cccccc;
    padding: 14px 0 14px 24px; }
    @media (min-width: 841px) {
      .telia-react-menu_itemText__level2 {
        width: 230px;
        font-size: 16px;
        font-weight: normal;
        border-top: none;
        padding: 17px 18px 17px 24px; } }
  .telia-react-menu_itemText__level3 {
    font-size: 14px;
    font-weight: normal;
    padding: 10px 0 10px 48px; }
    @media (min-width: 841px) {
      .telia-react-menu_itemText__level3 {
        width: 230px;
        font-size: 16px;
        font-weight: normal;
        border-top: none;
        padding: 17px 18px 17px 24px; } }

.telia-react-menu_dropdownIcon {
  transition: transform 0.2s; }
  .telia-react-menu_dropdownIcon__level1 {
    transform: rotate(0deg);
    border-radius: 50%;
    line-height: 0px;
    font-size: 16px;
    background-color: #f2f2f2;
    margin-right: -9px;
    padding: 9px; }
    @media (min-width: 841px) {
      .telia-react-menu_dropdownIcon__level1 {
        font-size: 10px;
        background-color: transparent;
        padding: 0px;
        margin-right: 0;
        margin-left: 8px; } }
    .telia-react-menu_item__level1.telia-react-menu_item__selected .telia-react-menu_dropdownIcon__level1 {
      transform: rotate(-180deg); }
  .telia-react-menu_dropdownIcon__level2 {
    transform: rotate(0deg);
    border-radius: 50%;
    line-height: 0px;
    font-size: 10px;
    background-color: #ffffff;
    margin-right: -4px;
    padding: 4px; }
    @media (min-width: 841px) {
      .telia-react-menu_dropdownIcon__level2 {
        transition: transform 0s;
        margin-right: 0;
        transform: rotate(-90deg);
        background-color: transparent; } }
    .telia-react-menu_item__level2.telia-react-menu_item__selected .telia-react-menu_dropdownIcon__level2 {
      transform: rotate(-180deg);
      background-color: #f2f2f2; }
      @media (min-width: 841px) {
        .telia-react-menu_item__level2.telia-react-menu_item__selected .telia-react-menu_dropdownIcon__level2 {
          transform: rotate(-90deg);
          background-color: transparent; } }

.telia-react-menu_item__level1 > .telia-react-menu_item__active {
  position: relative; }
  @media (min-width: 841px) {
    .telia-react-menu_item__level1 > .telia-react-menu_item__active::after {
      content: '';
      display: block;
      height: 2px;
      bottom: 0;
      left: 12px;
      right: 12px;
      background-color: #990ae3;
      position: absolute; } }

/*
  With growing sites z-index can be a challenging experience.
  Setting up some rules for handling z-index can make things easier. 
*/
.telia-react-navigation {
  position: sticky;
  top: 0;
  z-index: 300;
  border-bottom: solid 1px #cccccc;
  transition: border-bottom 0s ease-in 0.2s; }
  @media (max-width: 840px) {
    .telia-react-navigation__mobileMenuOpen {
      border-bottom: solid 1px transparent;
      transition: border-bottom 0s; } }
  @media (min-width: 841px) {
    .telia-react-navigation {
      top: -38px; } }
  .telia-react-navigation_mainContainer {
    height: 72px; }
  .telia-react-navigation_logo:focus {
    outline-offset: 2px;
    outline: solid 4px #0099ff; }
  .telia-react-navigation_iconMenuItem {
    margin-left: 16px;
    cursor: pointer;
    background-color: #ffffff; }
    .telia-react-navigation_iconMenuItem:focus {
      outline-offset: 3px;
      outline: solid 4px #0099ff; }
    .telia-react-navigation_iconMenuItem::-moz-focus-inner {
      border: 0; }
    .telia-react-navigation_iconMenuItem > svg {
      display: block; }
  .telia-react-navigation_userInitials {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #ffffff;
    background-color: #990ae3;
    font-weight: bold;
    font-size: 12px;
    width: 26px;
    height: 26px; }
  .telia-react-navigation_hamburger {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
    height: 23px;
    width: 24px; }
    .telia-react-navigation_hamburger span {
      display: block;
      position: absolute;
      height: 2px;
      width: 22px;
      background: #222222;
      border-radius: 999px;
      opacity: 1;
      left: 1px;
      transform: rotate(0deg);
      transition: 0.2s ease-in-out; }
      .telia-react-navigation_hamburger span:nth-child(1) {
        top: 5px; }
      .telia-react-navigation_hamburger span:nth-child(2), .telia-react-navigation_hamburger span:nth-child(3) {
        top: 11px; }
      .telia-react-navigation_hamburger span:nth-child(4) {
        top: 17px; }
  .telia-react-navigation_hamburger__active {
    border-radius: 50%; }
    .telia-react-navigation_hamburger__active span {
      top: 12px; }
      .telia-react-navigation_hamburger__active span:nth-child(1) {
        width: 0;
        left: 50%; }
      .telia-react-navigation_hamburger__active span:nth-child(2) {
        width: 22px;
        transform: rotate(45deg);
        background-color: #222222; }
      .telia-react-navigation_hamburger__active span:nth-child(3) {
        width: 22px;
        transform: rotate(-45deg);
        background-color: #222222; }
      .telia-react-navigation_hamburger__active span:nth-child(4) {
        width: 0;
        left: 50%; }
  .telia-react-navigation_mobileMenuContainer {
    position: sticky;
    top: 72px;
    z-index: 200; }
  .telia-react-navigation_segmentLinksContainer {
    border-bottom: solid 1px #cccccc; }
  .telia-react-navigation_segmentLinks {
    height: 52px; }
  .telia-react-navigation_languageLinks {
    height: 70px; }
  .telia-react-navigation_loggedOutContainer {
    position: sticky;
    top: 72px;
    z-index: 200; }
  .telia-react-navigation_loggedInContainer {
    position: sticky;
    top: 72px;
    z-index: 200; }
    @media (max-width: 840px) {
      .telia-react-navigation_loggedInContainer {
        max-width: 100%; } }
  .telia-react-navigation_loggedInDrawerContainer {
    position: absolute;
    width: 260px;
    right: 0; }
  .telia-react-navigation_menuContainer {
    overflow-y: auto;
    max-height: calc( 100vh - ( 72px + 52px + 70px )); }
    @media (min-width: 841px) {
      .telia-react-navigation_menuContainer {
        transition: margin 0.1s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
        overflow: visible;
        max-height: none;
        margin-left: 24px;
        opacity: 1; }
        .telia-react-navigation_menuContainer__slideHidden {
          transition: margin 0.2s, opacity 0.2s;
          overflow: hidden;
          opacity: 0;
          margin-left: 20px; } }

.telia-react-tupasLogin_inlineNotification p {
  margin: 0; }

@media (min-width: 841px) {
  .telia-react-credentialsLogin_form {
    margin: 0 auto; } }

.telia-react-credentialsLogin_passwordContainer {
  position: relative; }

.telia-react-credentialsLogin_passwordTooltip {
  max-width: 217px; }

.telia-react-credentialsLogin_tooltipIcon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0; }

@media (max-width: 1080px) {
  .telia-react-credentialsLogin_iconUrlLinkContent {
    padding: 15px 24px; } }

@media (min-width: 841px) {
  .telia-react-credentialsLogin_iconUrlLinkContent {
    margin: 0 auto; } }

.telia-react-credentialsLogin_inlineNotification p {
  margin: 0; }

.telia-react-productCard {
  position: relative;
  display: flex;
  flex-basis: 100%; }
  .telia-react-productCard > span:first-of-type {
    display: flex;
    flex-basis: 100%; }
  .telia-react-productCard_panel {
    display: flex;
    flex-direction: column;
    padding: 8px;
    padding-top: 0; }
    @media (min-width: 481px) {
      .telia-react-productCard_panel {
        padding: 16px;
        padding-top: 0; } }
  .telia-react-productCard:focus {
    outline: unset;
    outline-offset: unset; }
  .telia-react-productCard:focus:after {
    content: '';
    display: block;
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    border-radius: 8px;
    border: 4px solid #4dc0ff; }
  .telia-react-productCard_tag {
    height: 20px;
    margin-bottom: 3px;
    display: flex;
    align-self: flex-start; }
  .telia-react-productCard_image, .telia-react-productCard_imageContainer {
    max-width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px; }
    @media (min-width: 481px) {
      .telia-react-productCard_image, .telia-react-productCard_imageContainer {
        max-width: 175px;
        height: 175px;
        margin-bottom: 16px; } }
    @media (min-width: 601px) and (max-width: 1080px) {
      .telia-react-productCard_image, .telia-react-productCard_imageContainer {
        max-width: 150px;
        height: 150px; } }
  .telia-react-productCard_imageContainer {
    position: relative;
    width: 120px; }
    @media (min-width: 481px) {
      .telia-react-productCard_imageContainer {
        width: 175px; } }
    @media (min-width: 601px) and (max-width: 1080px) {
      .telia-react-productCard_imageContainer {
        width: 150px; } }
  .telia-react-productCard_title {
    margin-bottom: 10px;
    height: 32px;
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal; }
    @media (min-width: 481px) {
      .telia-react-productCard_title {
        margin-bottom: 14px; } }
    @media (min-width: 481px) {
      .telia-react-productCard_title {
        height: 48px;
        font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal; } }
  .telia-react-productCard_rating .telia-react-smallText__size_md {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 10px;
    line-height: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal; }
    @media (min-width: 481px) {
      .telia-react-productCard_rating .telia-react-smallText__size_md {
        font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal; } }
  .telia-react-productCard_listContent, .telia-react-productCard_description.telia-react-smallText__size_md, .telia-react-productCard_description.telia-react-smallText__size_sm {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 10px;
    line-height: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal; }
    @media (min-width: 481px) {
      .telia-react-productCard_listContent, .telia-react-productCard_description.telia-react-smallText__size_md, .telia-react-productCard_description.telia-react-smallText__size_sm {
        font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-size: 12px;
        line-height: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal; } }
  .telia-react-productCard_listContent {
    color: black;
    flex-grow: 1;
    max-height: 42px;
    overflow: hidden;
    margin-bottom: 8px;
    max-width: 133px; }
    @media (min-width: 481px) {
      .telia-react-productCard_listContent {
        max-height: 54px;
        margin-bottom: 12px; } }
    @media (min-width: 481px) {
      .telia-react-productCard_listContent {
        max-width: 185px; } }
    .telia-react-productCard_listContent .telia-react-list_item {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      list-style-position: inside;
      margin-bottom: 0;
      padding-left: 1px; }
  .telia-react-productCard_description {
    flex-grow: 1;
    max-height: 42px;
    overflow: hidden; }
    @media (min-width: 481px) {
      .telia-react-productCard_description {
        max-height: 54px; } }
  .telia-react-productCard_description, .telia-react-productCard_rating {
    margin-bottom: 8px; }
    @media (min-width: 481px) {
      .telia-react-productCard_description, .telia-react-productCard_rating {
        margin-bottom: 12px; } }

.telia-react-cardPrice_prefix, .telia-react-cardPrice_priceVariation {
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 10px;
  line-height: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal; }
  @media (min-width: 481px) {
    .telia-react-cardPrice_prefix, .telia-react-cardPrice_priceVariation {
      font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
      font-size: 12px;
      line-height: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal; } }

@media (min-width: 481px) {
  .telia-react-cardPrice_prefix {
    margin-bottom: 5px; } }

.telia-react-cardPrice_priceValue {
  margin-right: 8px;
  text-transform: none;
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal; }
  @media (min-width: 481px) {
    .telia-react-cardPrice_priceValue {
      margin-bottom: 3px;
      font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
      font-size: 20px;
      line-height: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal; } }

.telia-react-cardPrice_normalPrice {
  align-self: center;
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 10px;
  line-height: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal; }
  @media (min-width: 481px) {
    .telia-react-cardPrice_normalPrice {
      font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal; } }

@media (min-width: 481px) {
  .telia-react-cardInfo .telia-react-smallText__size_sm {
    font-size: 12px; } }

.telia-react-cardInfo_productNumber {
  align-self: center; }

.telia-react-cardColorVariants_container {
  max-height: 12px;
  margin: 0 -2px; }
  .telia-react-cardColorVariants_container > * {
    margin: 0 4px; }

.telia-react-cardColorVariants_dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 1px solid transparent; }
  .telia-react-cardColorVariants_dot__withBorder {
    border-color: rgba(34, 34, 34, 0.1); }

.telia-react-cardTag {
  max-height: 14px; }
  .telia-react-cardTag_text {
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.32px; }
  @media (min-width: 481px) {
    .telia-react-cardTag {
      max-height: 18px; }
      .telia-react-cardTag_text {
        font-size: 10px;
        line-height: 1.4;
        letter-spacing: 0.4px; } }
  .telia-react-cardTag__hidden {
    opacity: 0; }

.telia-react-sideNavigationItem {
  border-left: 2px solid transparent; }
  .telia-react-sideNavigationItem__active {
    border-left: 2px solid #990ae3;
    color: #990ae3; }
  .telia-react-sideNavigationItem_subItems {
    margin-left: calc(24px + (4 * 8px)); }
    .telia-react-sideNavigationItem_subItems__withoutIcon {
      margin-left: 32px; }

.telia-react-sideNavigationSubItem {
  border-left: 2px solid transparent; }
  .telia-react-sideNavigationSubItem__active {
    border-left: 2px solid #990ae3;
    color: #990ae3; }

@use '/src/sass/foundations/voca';

.wrapper {
  display: inline-block;
  cursor: not-allowed;

  &__inline {
    display: inline;
    .wrappedChild {
      display: inline;
    }

    &_rightPadded {
      margin-right: voca.$telia-spacing-8;
    }
  }
}

.wrappedChild {
  display: inline-block;
  pointer-events: none;
}

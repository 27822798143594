@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.pukCodeSearchResults {
  background-color: voca.$telia-white;
  padding: voca.$telia-spacing-24 voca.$telia-spacing-32;
  margin-top: voca.$telia-spacing-8;
  border-radius: voca.$telia-border-radius-16;
  box-shadow: 0 voca.$telia-spacing-2 voca.$telia-spacing-8 0 rgba(58, 58, 58, 0.33);

  position: absolute;
  min-width: calc(min(78rem, 100vw));
  max-width: calc(min(80rem, 100vw));
  top: -9999px;
  left: -9999px;
  word-wrap: break-word;

  z-index: 1;

  @include gaia.to('md') {
    margin-top: voca.$telia-spacing-12;
    margin-left: voca.$telia-spacing-8;
    margin-right: voca.$telia-spacing-8;
    min-width: auto;
    width: calc(100% - 16px);
    padding: voca.$telia-spacing-24;
  }

  .pukCodeSearchResultItem {
    all: unset;
    cursor: pointer;
    display: block;
    width: 100%;
    margin: voca.$telia-spacing-8 0;

    &:hover * {
      background-color: voca.$telia-purple-100;
      color: voca.$telia-purple-500;
    }

    &:focus-visible {
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: 2px;
    }

    & > a {
      display: block;
      & > span {
        display: block;

        @include gaia.to('sm') {
          display: flex;
        }
      }
    }

    & .chevron {
      display: inline-block;
      color: voca.$telia-purple-500;
      padding-top: voca.$telia-spacing-2;
      margin-right: voca.$telia-spacing-2;
    }

    & .pukCodeResultItem {
      color: voca.$telia-gray-900;
      padding-left: voca.$telia-spacing-8;
      display: block;
      text-decoration: underline;
    }
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_borderWidth: 0.75 * gaia.$typographyBaseline;

.navigationMenuItem {
  border-bottom: $_borderWidth solid transparent;

  &__active {
    border-bottom: $_borderWidth solid voca.$telia-purple-500;
    width: max-content;
  }
}

@use '../responsive/pileVerticallyTo/mixin' as pile;
@use '../responsive/responsiveHidden/mixin' as hide;
@use '../spacing/mixin' as space;

.container {
  display: flex;
  flex-direction: row;

  &_flexItem {
    min-width: 1px;
    max-width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

    &_1 {
      flex-grow: 1;
    }

    &_2 {
      flex-grow: 2;
    }

    &_3 {
      flex-grow: 3;
    }

    &_4 {
      flex-grow: 4;
    }
  }

  &_centered {
    justify-content: center;
    align-items: center;
  }

  &_centeredHorizontally {
    justify-content: center;
  }

  &_centeredVertically {
    align-items: center;
  }

  &_alignTop {
    align-items: flex-start;
  }

  &_alignBottom {
    align-items: flex-end;
  }

  &_alignBaseline {
    align-items: baseline;
  }

  &_alignRight {
    justify-content: flex-end;
  }

  &_spaceBetween {
    justify-content: space-between;
  }

  &_fitContent {
    width: fit-content;
  }

  &_wrapContent {
    flex-wrap: wrap;
  }

  &_occupyVerticalSpace {
    height: 100%;
  }

  &_occupyHorizontalSpace {
    width: 100%;
  }

  &_stretchToSameHeight {
    align-items: stretch;
  }

  &_directionRow {
    flex-direction: row;

    &_reverse {
      flex-direction: row-reverse;
    }
  }

  &_directionColumn {
    flex-direction: column;

    &_reverse {
      flex-direction: column-reverse;
    }
  }
}

@include pile.pileVerticallyTo();
@include hide.responsiveHidden();
@include space.padding();
@include space.margin();

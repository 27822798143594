@use '/src/sass/foundations/voca';

.link {
  text-decoration: none;
  color: voca.$telia-black;

  &__disabled,
  &__disabled:hover {
    cursor: not-allowed;
    color: voca.$telia-gray-400;
  }

  &__disabled > *,
  &__disabled > *:hover,
  &__disabled > * > *,
  &__disabled > * > *:hover {
    color: voca.$telia-gray-400;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
  }

  &_block {
    display: block;
  }

  &_selectable {
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
    user-select: text;
  }
}

@use '/src/sass/foundations/voca';

:where(.iconButton) {
  all: unset; // :where selector has a specifity of 0, meaning subclasses always properly override unset props
  border-radius: 2px;
  line-height: 1rem;
  color: voca.$telia-black;
  cursor: pointer;
  transition: outline-width voca.$telia-duration-50;

  &[aria-disabled='true'] {
    color: voca.$telia-gray-500;
    cursor: not-allowed;
  }

  &:not([aria-disabled='true']):hover svg {
    color: voca.$telia-purple-500;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
  }
}

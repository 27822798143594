@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.panel {
  display: flex;

  &_content {
    width: 100%;
  }
}

.collapsibleBlock {
  border: 0;

  :focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -2px;
  }
}

.listItem {
  &_linkText {
    text-decoration: underline;
  }

  &_linkIcon {
    padding-top: gaia.$xxxs;
  }

  :focus-visible {
    outline-offset: 1px;
  }
}

@use '/src/sass/foundations/voca';

$size: 8px;

.title {
  margin-bottom: voca.$telia-spacing-16;

  &_text {
    padding-right: voca.$telia-spacing-8;
    font-weight: bold;
  }

  svg {
    fill: voca.$telia-blue-600;
  }
}

.progressBar {
  &_background {
    border-radius: $size;
    background-color: voca.$telia-gray-200;
  }

  &_bar {
    border-radius: $size;
    height: $size;
    background-color: voca.$telia-purple-700;
  }

  &_bar__animated {
    animation: fillBar voca.$telia-ease-in-out voca.$telia-duration-1500;
    animation-delay: voca.$telia-duration-100;
    animation-fill-mode: backwards;
  }

  @keyframes fillBar {
    from {
      transform: translateX(0);
      width: 0%;
    }
    to {
      transform: translateX(1);
      // use inherent width to properly animate usage
    }
  }
}

.labels {
  margin-top: voca.$telia-spacing-8;

  b {
    font-weight: 500;
  }
}

@use '/src/sass/foundations/voca';

.bodyTextClickable {
  outline: 0px solid voca.$telia-blue-500;
  outline-offset: 0px;
  transition: outline-width 0.1s, outline-offset 0.1s;

  &:focus-visible {
    outline-width: 3px;
    outline-offset: 2px;
  }

  &__inline {
    display: inline-block;
    font-size: inherit;
  }

  &__voca {
    color: voca.$telia-purple-600;
    font-weight: normal;
    text-decoration: underline;

    &:hover {
      color: voca.$telia-purple-700;
      background-color: voca.$telia-purple-100;
    }
  }
}

/*
 * Fixes full page loaders and other flex elements occupying vertical space with
 * `height: 100%;` used within the page if the page contains other visible
 * elements. Such elements interpret `height: 100%` as "parent block container's
 * full height", so the solution is to declare the block container a flex
 * container instead, which will result in the above interpreted as "rest of the
 * parent flex container's available height".
 */
.page {
  display: flex;
  flex-direction: column;

  /*
   * Gaia's page rows define a max width, but without a width, components
   * narrower than the page's full width within a `display: flex;` container
   * are aligned in the centre.
   */
  :global(.telia-react-pageRow) {
    width: 100%;
  }
}

@use '/src/sass/foundations/voca';

.divider {
  border-top: 1px solid voca.$telia-gray-200;

  &_borderless {
    border-color: transparent;
  }

  &_light {
    border-color: voca.$telia-gray-100;
  }
}

@use '/src/sass/foundations/voca';

.barCode {
  all: unset;
  outline: none;
  display: flex;
  flex-direction: row;
  vertical-align: baseline;
  color: var(--color-basic);

  &:hover {
    color: var(--color-hover);
  }

  &__copy {
    --color-basic: #{voca.$telia-purple-500};
    --color-hover: #{voca.$telia-purple-400};
    cursor: pointer;
  }

  &__copied {
    --color-basic: #{voca.$telia-green-600};
    --color-hover: #{voca.$telia-green-600};
    cursor: auto;
  }

  &[disabled] {
    --color-basic: #{voca.$telia-gray-500};
    --color-hover: #{voca.$telia-gray-500};
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
  }
}

@use '/src/sass/foundations/gaia';

.drawer {
  @include gaia.from('lg') {
    // 60% of viewport width, but no more than 72rem to support a 200%
    // text-only zoom level.
    width: clamp(min(100vw, 60rem), 60vw, 72rem) !important;
  }

  @include gaia.to('md') {
    width: 80vw !important;
  }

  @include gaia.to('sm') {
    width: 100vw !important;
  }

  &_hiddenFocus {
    width: 0;
    height: 0;
    outline: none;
    position: fixed;
  }

  &_overlay {
    content: '';
    z-index: 649;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.23);
    height: 100vh;
    width: 100vw;
  }

  &_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    border-bottom: 1px solid var(--purpur-color-transparent-black-150);
    padding: var(--purpur-spacing-400) var(--purpur-spacing-600);

    @include gaia.to('sm') {
      padding: var(--purpur-spacing-300);
    }

    @include gaia.to('xxs') {
      padding: var(--purpur-spacing-200) var(--purpur-spacing-300);
    }
  }

  &_content {
    overflow-y: auto;
    flex-grow: 1;
    padding: var(--purpur-spacing-400) var(--purpur-spacing-600);

    @include gaia.to('sm') {
      padding: var(--purpur-spacing-300);
    }
  }

  &_tag {
    margin-left: var(--purpur-spacing-100);
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.row {
  position: relative;
  display: block;

  --row-bg-color: #{voca.$telia-white};
  --row-fg-color-title: #{voca.$telia-transparent-black-850};
  --row-fg-color-label: #{voca.$telia-gray-600};
  --row-fg-color-value: #{voca.$telia-functional-black};
  --row-cursor: pointer;

  & > span {
    display: block;
  }

  &_ribbon {
    pointer-events: none;
    position: absolute;
    display: block;
    width: max-content;
    padding: 3px 6px;
    border-radius: 8px 0;
    background: voca.$telia-blue-600;

    color: voca.$telia-white;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;

    @include gaia.to('xs') {
      border-top-left-radius: 0;
    }
  }

  & > :global(.telia-react-element__clickableAppearance),
  & > * > :global(.telia-react-element__clickableAppearance) {
    background-color: var(--row-bg-color);
    color: var(--row-fg-color-value);
    cursor: var(--row-cursor);
  }

  &:focus {
    outline: 0;

    & > :global(.telia-react-element__clickableAppearance),
    & > * > :global(.telia-react-element__clickableAppearance) {
      outline: 0;
    }
  }

  &:focus-visible {
    outline: 0;

    & > :global(.telia-react-element__clickableAppearance),
    & > * > :global(.telia-react-element__clickableAppearance) {
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: 2px;
    }
  }

  &[aria-disabled] {
    $text-color: voca.$telia-gray-500;

    --row-bg-color: #{voca.$telia-white};
    --row-fg-color-title: #{$text-color};
    --row-fg-color-label: #{$text-color};
    --row-fg-color-value: #{$text-color};
    --row-cursor: not-allowed;
  }

  &:not([aria-disabled]):has(:hover) {
    $text-color: voca.$telia-purple-700;

    --row-bg-color: #{rgba(voca.$telia-purple-100, 0.33)};
    --row-fg-color-title: #{$text-color};
    --row-fg-color-label: #{$text-color};
    --row-fg-color-value: #{$text-color};
    --row-cursor: pointer;
  }
}

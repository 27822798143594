@use '/src/sass/foundations/voca';

$_iconSize: 1.3em;

.activeFilters {
  :global {
    .telia-react-filterChip {
      background-color: voca.$telia-purple-850;
      transition: background-color voca.$telia-duration-250;
      color: voca.$telia-white;
      font-size: 16px;
      height: 40px;
      border-radius: 8px;

      .telia-react-flex {
        flex-direction: row-reverse;
      }

      .telia-react-typography {
        font-size: 16px;
      }

      .telia_icon__close {
        min-width: $_iconSize;
        min-height: $_iconSize;
        width: $_iconSize;
        height: $_iconSize;
        max-width: $_iconSize;
        max-height: $_iconSize;
      }

      .telia-react-filterChip__icon {
        font-size: unset;
      }

      &:hover {
        cursor: pointer;
        background-color: voca.$telia-purple-700;
      }

      &:focus {
        border: unset;
      }

      &:focus-visible {
        outline: 3px solid voca.$telia-blue-500;
        outline-offset: 3px;
      }
    }
  }
}

.implicitFilter {
  :global {
    .telia-react-filterChip {
      .telia-react-gutter {
        display: none;
      }

      .telia_icon__close {
        display: none;
      }
    }
  }
}

/* stylelint-disable */

/* Heading */
@font-face {
  font-display: swap;
  font-family: 'TeliaSansHeading';
  src: url('./WOFF2/TeliaSansHeading-Heading.woff2') format('woff2');
}

/* Normal aka Regular aka 400 */
@font-face {
  font-display: swap;
  font-family: 'TeliaSans';
  src: url('./WOFF2/TeliaSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'TeliaSans';
  src: url('./WOFF2/TeliaSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* Medium aka 500 */
@font-face {
  font-display: swap;
  font-family: 'TeliaSans';
  src: url('./WOFF2/TeliaSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

/* Bold aka 700 */
@font-face {
  font-display: swap;
  font-family: 'TeliaSans';
  src: url('./WOFF2/TeliaSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'TeliaSans';
  src: url('./WOFF2/TeliaSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

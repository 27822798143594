@use '/src/sass/foundations/voca';

.counter {
  min-width: 3rem;
  border-radius: 1.2rem;
  display: inline-block;
  padding: 0.5rem 0.75rem;
  margin-left: 1rem;
  vertical-align: baseline;

  background-color: var(--purpur-color-purple-100);
  color: voca.$telia-purple-700;

  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1em;
  text-align: center;
  pointer-events: none;
}

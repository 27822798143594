@use '/src/sass/foundations/voca';

.backdrop {
  z-index: 400;

  &_intro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.23);
  }

  &_stopClip {
    position: absolute;
    top: 0;
    left: 0;
    width: unset;
    height: unset;
    background: rgba(0, 0, 0, 0.23);
    clip-path: none;
  }

  &_stopTransparency {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
  }
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  transform: rotate(0deg);

  &_topStart {
    // Don't keep the arrow pointing exactly its height away from the dialog.
    // Otherwise, a zoomed-in browser view may render an empty row of pixels
    // between the arrow and dialog due to rounding.
    bottom: -11px;
    left: 20px;
    border-width: 12px;
    border-style: solid;
    border-color: voca.$telia-purple-800 transparent transparent transparent;
    border-bottom: none;
  }

  &_topCenter {
    bottom: -11px;
    left: calc(50% - 12px);
    border-width: 12px;
    border-style: solid;
    border-color: voca.$telia-purple-800 transparent transparent transparent;
    border-bottom: none;
  }

  &_topEnd {
    bottom: -11px;
    right: 20px;
    border-width: 12px;
    border-style: solid;
    border-color: voca.$telia-purple-800 transparent transparent transparent;
    border-bottom: none;
  }

  &_bottomStart {
    top: -11px;
    left: 20px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent voca.$telia-purple-800 transparent;
    border-top: none;
  }

  &_bottomCenter {
    top: -11px;
    left: calc(50% - 12px);
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent voca.$telia-purple-800 transparent;
    border-top: none;
  }

  &_bottomEnd {
    top: -11px;
    right: 20px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent voca.$telia-purple-800 transparent;
    border-top: none;
  }
}

.dialogContainer {
  z-index: 401;
}

.dialog {
  min-width: 250px;
  max-width: min(100vw, 600px);
  border-radius: 12px;
  background-color: voca.$telia-purple-800;
  padding: 16px 24px;
  color: voca.$telia-white;
  a:not(:hover, [aria-disabled]) {
    color: voca.$telia-white;
  }
}

.tourProgressContainer {
  padding-bottom: voca.$telia-spacing-8;
}

.tourProgress {
  float: right;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 4px;
  cursor: default;
}

.tourProgressBefore {
  color: voca.$telia-purple-600;
}

.tourProgressAt {
  color: voca.$telia-purple-200;
}

.tourProgressAfter {
  color: voca.$telia-purple-600;
}

.tourProgressClear {
  clear: right;
}

.stopIcon {
  float: right;
  padding-left: voca.$telia-spacing-16;
  color: voca.$telia-white;
}

.stopTitle {
  padding-bottom: voca.$telia-spacing-8;
  color: voca.$telia-white !important;
}

.stopDescription {
  padding-bottom: voca.$telia-spacing-16;
}

.stopEmptyFocus {
  width: 0;
  height: 0;
  outline: none;
}

.secondaryActionButton {
  button {
    color: voca.$telia-purple-200;
  }
}

.extraHighlight {
  $sizePadding: 0.2rem;
  $sizeBorder: 0.3rem;
  $sizeMargin: $sizePadding + $sizeBorder;
  --animation-duration: #{voca.$telia-duration-500};

  position: absolute;
  box-sizing: content-box;
  display: block;
  width: 0;
  height: 0;
  border: $sizeBorder solid voca.$telia-blue-500;
  border-radius: 8px;
  padding: $sizePadding;
  margin-top: -$sizeMargin;
  margin-left: -$sizeMargin;
  animation: pulse var(--animation-duration) linear infinite alternate;

  @media (prefers-reduced-motion: reduce) {
    --animation-duration: #{voca.$telia-duration-1500};
  }

  @keyframes pulse {
    0%,
    50% {
      box-shadow: 0 0 0 0 voca.$telia-blue-500;
    }
    100% {
      box-shadow: 0 0 0.75rem 0 voca.$telia-blue-500;
    }
  }
}

.content {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  gap: 24px;

  &_noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    &_icon {
      $size: 100px;
      color: var(--purpur-color-gray-300);
      min-width: $size;
      min-height: $size;
      width: $size;
      height: $size;
      max-width: $size;
      max-height: $size;
      transform: scaleX(-1);
    }

    &_label {
      font-weight: bold;
      font-size: 2.4rem;
      text-align: center;
      color: var(--purpur-color-gray-400);
    }
  }
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

@use 'sass:math';
@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_minWidthForUsername: gaia.$gaia-breakpoint-lg-min;
$_maxWidthForUsername: gaia.$gaia-breakpoint-lg-min +
  math.div(gaia.$gaia-breakpoint-lg-max - gaia.$gaia-breakpoint-lg-min, 4 / 3);

.userDropdown {
  &_menuToggle {
    --close-icon-color: #{voca.$telia-black};
    all: unset;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      color: voca.$telia-purple-500;
      --close-icon-color: #{voca.$telia-purple-500};
    }

    &:focus-visible {
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: 2px;
    }
  }

  &_container {
    position: relative;

    @include gaia.to('xxs') {
      height: 2.4rem;
    }

    @media ((min-width: $_minWidthForUsername) and (max-width: $_maxWidthForUsername)) {
      height: 2.4rem;
    }

    @include gaia.to('sm') {
      position: static;
    }
  }

  &_nameAndCompany {
    margin-right: gaia.$gaia-margin-xs;

    @include gaia.to('sm') {
      display: none;
    }

    @media ((min-width: $_minWidthForUsername) and (max-width: $_maxWidthForUsername)) {
      display: none;
    }
  }

  &_nameAndCompany_name {
    font-weight: bold;
  }

  &_nameAndCompany_company {
    font-weight: normal;
  }

  &_nameAndCompany_name,
  &_nameAndCompany_company {
    max-width: 15rem;

    @include gaia.from('md') {
      max-width: 20rem;
    }

    @include gaia.from('xxlg') {
      max-width: 25rem;
    }
  }

  &_userInitials {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    width: 2.4rem;
    height: 2.4rem;

    font-size: 1.2rem;
    font-weight: 500;

    color: voca.$telia-white;
    background-color: voca.$telia-purple-500;

    &__open {
      color: var(--close-icon-color);
      background-color: transparent;
    }
  }

  &_itemContainer {
    position: absolute;
    right: 0;
    min-width: -webkit-max-content;
    margin-top: 8px;
    padding-top: voca.$telia-spacing-8;
    background-color: voca.$telia-white;

    @include gaia.to('sm') {
      width: 100vw;
    }
  }
}

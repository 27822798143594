@use '/src/sass/foundations/voca';

.cell {
  padding-right: voca.$telia-spacing-20;
}

.heading {
  color: voca.$telia-gray-600;
  font-weight: 500;
  padding-top: voca.$telia-spacing-8;
  padding-bottom: voca.$telia-spacing-8;
  overflow-wrap: anywhere;
  hyphens: auto;
  hyphenate-limit-chars: auto auto 6;

  &_clickable:hover {
    background-color: voca.$telia-purple-100;
    color: voca.$telia-purple-700;
    cursor: pointer;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
  }
}

.wrappedAnywhere {
  word-wrap: anywhere;
}

.wrappedByBreakingWord {
  word-wrap: break-word;
}

.singleLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.twoLines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

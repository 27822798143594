@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.skipLink {
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: 8px;
  display: inline-flex;
  justify-content: center;
  border: unset;
  border-radius: 999px;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.6rem;
  outline: 0;
  padding: voca.$telia-spacing-12 voca.$telia-spacing-24;
  transition: background-color voca.$telia-duration-150 ease;
  z-index: gaia.z('growl-notification');
  text-transform: uppercase;

  background-color: voca.$telia-purple-500;
  color: voca.$telia-white;

  &:hover {
    background-color: voca.$telia-purple-600;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
  }
}

.visibleToKeyboard {
  opacity: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
  transition: opacity 0.2s;
  pointer-events: none;

  &:focus-visible {
    opacity: 1;
    clip: auto;
    height: auto;
    width: auto;
    overflow: hidden;
    pointer-events: unset;
  }
}

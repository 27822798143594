@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

%sidebar_background {
  background-size: 2px 100%;
  background-position-x: 11px;
  background-position-y: 0;
  background-repeat: no-repeat;
  background-image: linear-gradient(voca.$telia-purple-700, voca.$telia-purple-700);
}

.activityLog {
  max-width: 100%;

  @include gaia.from('lg') {
    max-width: 70%;
  }

  & .activityLogItem:not(:last-child) {
    @extend %sidebar_background;
  }

  & .activityLogItem:last-child {
    @extend %sidebar_background;
    background-size: 2px 8px;
  }

  & .activityLogItem:first-child {
    @extend %sidebar_background;
    background-position-y: 10px;
  }

  & .activityLogItem_title {
    @extend %sidebar_background;
    background-position-y: 20px;
  }

  & .activityLogItem_titleText {
    margin-top: -4px;
  }

  .history {
    background-position-x: 11px;
  }

  .bullet {
    margin-left: 4px;
    margin-top: 4px;

    &_large {
      margin-left: 0;
      margin-top: -1px;
    }

    &_last {
      margin-top: 4px;
    }

    &:not(&_large):not(&_last) {
      svg {
        fill: voca.$telia-gray-100;
        stroke: voca.$telia-purple-700;
        stroke-width: 6px;
      }
    }
  }
}

.activityCardContent {
  border-radius: 8px;
  word-break: break-word;
  hyphens: auto;
  hyphenate-limit-chars: auto auto 6;

  &:hover &__hoverPurple {
    color: voca.$telia-purple-500;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -3px;
  }
}

.heightInheritedContainer {
  height: inherit;
}

.maxWidthInheritedContainer {
  max-width: inherit;
}

.inlineBlock {
  display: inline;
}

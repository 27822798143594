@use '/src/sass/foundations/voca';

@mixin iconAndBorder($type, $colour) {
  &_icon {
    &__#{$type} {
      color: $colour;
    }
  }

  &_leftBorder {
    &__#{$type} {
      border-left: 6px solid $colour;
    }
  }
}

.confirmationPanel {
  @include iconAndBorder('success', voca.$telia-green-700);
  @include iconAndBorder('failure', voca.$telia-red-600);
  @include iconAndBorder('partial', voca.$telia-orange-500);
}

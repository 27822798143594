@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

:global(.slick-slider) {
  margin-bottom: voca.$telia-spacing-24;

  :global(.slick-list) {
    border-radius: 4px;
    margin-bottom: 12px;
  }
}

.panelContent {
  padding: 24px 0;
}

.content {
  padding: 0 24px;

  &:focus,
  &:focus-within {
    outline: none;
  }
}

.promotions {
  position: relative;

  &:hover {
    .autoplay {
      opacity: 1;
    }
  }

  &_slider {
    margin-bottom: 0;
  }
}

.promotion {
  background: voca.$telia-white;
  text-align: initial !important;

  @include gaia.to('sm') {
    max-height: 400px;
  }
}

.imageContainer {
  position: relative;

  & > picture {
    width: 100%;
  }

  @include gaia.to('sm') {
    overflow: hidden;
    height: 180px;
  }
}

.autoplay {
  top: voca.$telia-spacing-8;
  right: voca.$telia-spacing-8;
  position: absolute;
  background-color: voca.$telia-purple-800;
  color: voca.$telia-white;
  border-radius: 50%;
  border: 1px solid currentColor;
  width: voca.$telia-spacing-32;
  height: voca.$telia-spacing-32;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  opacity: 0;

  @include gaia.to('md') {
    display: flex;
  }

  &_icon {
    max-width: voca.$telia-spacing-16;
    max-height: voca.$telia-spacing-16;
    min-width: voca.$telia-spacing-16;
    min-height: voca.$telia-spacing-16;
    width: voca.$telia-spacing-16;
    height: voca.$telia-spacing-16;
  }

  & svg {
    color: currentColor !important;
  }

  &:hover {
    background-color: voca.$telia-purple-700;
    color: voca.$telia-white;
  }

  &:focus-visible {
    opacity: 1;
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 320px;

  @include gaia.to('sm') {
    height: 200px;
  }
}

.message {
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;

  &__active {
    z-index: 1;
    overflow-x: visible;
  }
}

.readMore {
  position: relative;
  display: block;
  visibility: hidden;

  &__active {
    visibility: visible;
    z-index: 1;
  }

  &_icon {
    position: relative;
    top: 1px;
  }
}

.dotsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dots {
  border-radius: 8px;
  display: flex;
  flex-grow: 0;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 voca.$telia-spacing-20;
  gap: voca.$telia-spacing-16;

  &:has(button:focus-visible) {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 8px;
  }

  button {
    all: unset;
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: voca.$telia-gray-300;

    transition: background-color 0.1s linear;

    &:hover {
      background-color: voca.$telia-gray-400;
    }

    &:global(.slick-active) {
      background-color: voca.$telia-purple-500;
    }
  }
}

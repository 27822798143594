@use '/src/sass/foundations/voca';

$_textSizeLite: 1.6rem;
$_textSizeCompact: 1.4rem;

$_iconSizeLite: $_textSizeLite * 1.5;
$_iconSizeCompact: $_textSizeCompact * 1.5;

@mixin iconSize($size) {
  min-width: $size;
  min-height: $size;
  width: $size;
  height: $size;
  max-width: $size;
  max-height: $size;
}

.flex {
  align-items: center;
  margin: voca.$telia-spacing-16 0;
  vertical-align: middle;

  &_compact {
    align-items: flex-start;
    margin: voca.$telia-spacing-2 0;
  }

  &_unpadded {
    align-items: flex-start;
    margin: 0 0 voca.$telia-spacing-4 0;
  }
}

.icon {
  margin-right: voca.$telia-spacing-16;
  @include iconSize($_iconSizeLite);

  &_compact {
    margin-right: voca.$telia-spacing-8;
    @include iconSize($_iconSizeCompact);
  }
}

.text {
  font-size: $_textSizeLite;

  &_compact {
    font-size: $_textSizeCompact;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include gaia.to('xxxs') {
    flex-direction: column-reverse;
  }

  > *:not(:last-of-type) {
    margin-right: voca.$telia-spacing-24;

    @include gaia.to('xxxs') {
      margin-right: 0;
      margin-top: voca.$telia-spacing-12;
    }
  }
}

@use '/src/sass/foundations/voca';

.regularCursor input {
  cursor: text !important;
}

.roundedSearchBar {
  label,
  label ~ :global(.telia-react-gutter) {
    display: none;
  }

  :global(.telia-react-input__container) {
    border-radius: voca.$telia-spacing-24;
    display: flex;
    flex-direction: row-reverse;
  }

  :global(.telia-react-input__iconWrapper) {
    position: unset;
    top: unset;
    right: unset;
    height: 100%;
    transform: unset;
    margin-left: voca.$telia-spacing-20;
  }

  $icon-size: 24px;
  :global(.telia-react-input__iconWrapper__icon) {
    min-width: $icon-size;
    min-height: $icon-size;
    width: $icon-size;
    height: $icon-size;
    max-width: $icon-size;
    max-height: $icon-size;
  }
}

.helperText {
  --helper-text-height: 0;
  --helper-text-overflow: hidden;

  transition: max-height voca.$telia-duration-150 ease-in-out;

  max-height: var(--helper-text-height);
  overflow: var(--helper-text-overflow);

  &_enterActive,
  &_enterDone {
    --helper-text-height: 10rem;
    --helper-text-overflow: unset;
  }

  &_exitActive,
  &_exitDone {
    --helper-text-height: 0;
    --helper-text-overflow: hidden;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

@use './sass/foundations/gaia';
@use './sass/foundations/voca';
@use './sass/globals/highlight' as highlight;

@import './fonts/TeliaSans.css';
@import './sass/globals/_@gaiads.css';
@import '~@purpurds/tokens/index.css'; // installed as a subdependency of @teliafi/fi-ds, should not be installed directly in order to be in sync with fi-ds

:root {
  --purpur-rescale: 1.6;
}

body,
html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --voca-rem-multiplier: 1;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;
}

body > div#root {
  height: 100%;
}

.telia-react-marginSpace_panel:focus-visible {
  outline: 3px solid voca.$telia-blue-500;
  outline-offset: 2px;
}

.telia-react-modalDialog_container {
  text-align: left;
}

button[class^='telia-react-element'][class*='telia-react-button'],
button[class^='_purpur-button']:not([class*='_purpur-button--icon-only']) {
  @include gaia.to('xxs') {
    width: 100%;
  }
}

#maincontent {
  flex-grow: 1;
}

#text-resize-detector {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 1rem;
  height: 1rem;
  visibility: hidden;
}

#onetrust-consent-sdk {
  display: none;
  position: relative;
  z-index: 1000;
  opacity: 0;

  &.active {
    display: block;
    animation: onetrustConsentFadeIn 300ms ease forwards;
    animation-delay: 300ms;
  }

  @keyframes onetrustConsentFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@use '/src/sass/foundations/voca';

.toggle {
  all: unset;
  display: flex;
  width: 44px;
  height: 44px;
  padding: 6px;
  margin: -6px;
  justify-content: center;
  align-items: center;
  outline: none;

  --toggle-bg-color: unset;
  --toggle-fg-color: #{voca.$telia-gray-900};
  --toggle-outline: none;

  &_open {
    --toggle-bg-color: #{voca.$telia-gray-100};
  }

  &:hover {
    --toggle-bg-color: #{voca.$telia-purple-200};
    --toggle-fg-color: #{voca.$telia-purple-700};
  }

  &:focus-visible {
    --toggle-outline: 3px solid #{voca.$telia-blue-500};
  }
}

.toggleZone {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;

  background-color: var(--toggle-bg-color);
  color: var(--toggle-fg-color);
  outline: var(--toggle-outline);
}

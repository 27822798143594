.meter {
  border-radius: 50%;
  max-width: 12rem;
  max-height: 12rem;
  margin-left: 1rem;

  &_bar :global(.CircularProgressbar-trail) {
    stroke-linecap: butt;
    stroke-width: 8;
    stroke: var(--bar-trail-color);
  }

  &_bar :global(.CircularProgressbar-path) {
    stroke-linecap: butt;
    stroke-width: 8;
    stroke: var(--bar-path-color);
  }

  &_bar__positive {
    --bar-trail-color: var(--purpur-color-red-100);
    --bar-path-color: var(--purpur-color-green-300);
  }

  &_bar__negative {
    --bar-trail-color: var(--purpur-color-red-100);
    --bar-path-color: var(--purpur-color-red-400);
  }

  &_text {
    font-size: 20px;
    font-weight: 400;
    color: var(--meter-text-color);
  }

  &_text__positive {
    --meter-text-color: var(--purpur-color-green-500);
  }

  &_text__negative {
    --meter-text-color: var(--purpur-color-red-500);
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_borderWidth: 0.75 * gaia.$typographyBaseline;
$_verticalItemPadding: 4 * gaia.$typographyBaseline;

.navigationMenu {
  position: relative;

  &_menuButtonContainer {
    border-radius: 6px;

    &:hover {
      color: voca.$telia-purple-500;
    }

    &:focus-visible {
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: -3px;
    }
  }

  &_menuButton {
    border-bottom: $_borderWidth solid transparent;
    padding: $_verticalItemPadding voca.$telia-spacing-8 ($_verticalItemPadding - $_borderWidth) 0;

    &__active {
      border-bottom-color: voca.$telia-purple-500;
    }
  }

  &_itemContainer {
    position: absolute;
    background-color: voca.$telia-white;
    z-index: 1;

    width: 100%;
    min-width: max-content;

    @include gaia.to('md') {
      position: relative;

      width: 100vw;
    }

    &_item {
      border-bottom: 1px solid voca.$telia-gray-300;
    }

    &_item a {
      display: block;
    }
  }
}

@use '/src/sass/foundations/voca';

$_accentBase: rgba(voca.$telia-purple-500, 0.12);
$_accentMin: rgba(voca.$telia-purple-500, 0.7);
$_accentMax: rgba(voca.$telia-purple-500, 0);

.highlight:not(input[type='checkbox'], textarea, telia-button, button),
input[type='checkbox'].highlight + div {
  animation-name: pulse;
  animation-duration: voca.$telia-duration-1500;
  animation-iteration-count: 3;
}

textarea.highlight {
  animation-name: pulse-textarea;
  animation-duration: voca.$telia-duration-1500;
  animation-iteration-count: 3;
}

telia-button.highlight button,
button.highlight {
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 999px;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: pulse;
    animation-duration: voca.$telia-duration-1500;
    animation-iteration-count: 3;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 2px 8px 0 $_accentBase, 0 0 0 0 $_accentMin;
  }
  100% {
    box-shadow: 0 2px 8px 0 $_accentMax, 0 0 0 8px $_accentMax;
  }
}

@keyframes pulse-textarea {
  0% {
    box-shadow: 0 0 0 13px voca.$telia-white, 1px 1px 0 16px $_accentBase,
      1px 1px 0 16px $_accentMin;
  }
  100% {
    box-shadow: 0 0 0 13px voca.$telia-white, 1px 1px 0 16px $_accentMax, 1px 1px 0 24px $_accentMax;
  }
}

@use '/src/sass/foundations/voca';

.datePicker {
  width: 100%;
}

.datetimePicker {
  width: 100%;
}

.monthPicker {
  width: 100%;

  :global {
    .DayPicker-wrapper {
      padding-bottom: 0;
    }

    .DayPicker-NavButton:hover {
      color: voca.$telia-purple-500;
    }

    .DayPicker-Caption {
      min-width: min(75vw, 30rem);
    }

    .DayPicker-Caption div {
      border-radius: 999px;
      cursor: pointer;
      padding: voca.$telia-spacing-8;
      margin: calc(-1 * voca.$telia-spacing-8) voca.$telia-spacing-16 0 voca.$telia-spacing-16;
      background-color: transparent;
      color: voca.$telia-purple-500;
      transition: all voca.$telia-duration-100 voca.$telia-ease-in;
    }

    .DayPicker-Caption div:hover {
      background-color: voca.$telia-purple-500;
      color: voca.$telia-white;
    }

    .DayPicker-Body,
    .DayPicker-Weekdays,
    .DayPicker-Week,
    .DayPicker-Day {
      display: none;
      pointer-events: none;
    }
  }
}

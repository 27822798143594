@use '/src/sass/foundations/voca';

.block {
  display: block;
  & > span {
    display: block;
  }

  &:focus {
    outline-width: 0;
  }

  &:focus-visible {
    outline-width: 3px;
  }
}

.inline {
  display: inline-block;
  & > span {
    // Component library's link renders a span with {display: block},
    // which inconveniently breaks focus outlines in Chrome.
    display: inline;
  }

  &:focus {
    outline-width: 0;
  }

  &:focus-visible {
    outline-width: 3px;
  }
}

.iconLeft {
  vertical-align: text-bottom;
}

.label {
  display: block;
}

.iconRight {
  vertical-align: baseline;
  margin-left: voca.$telia-spacing-4;
}

.disabled {
  cursor: not-allowed;
}

.selectable {
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}

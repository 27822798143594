@use '/src/sass/foundations/voca';

.mobileNavigation {
  position: absolute;
  width: 100%;
  left: 0;

  &_topBar {
    overflow-x: scroll;
    scrollbar-width: none;
    &:focus-visible {
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: -3px;
    }
  }

  &_item {
    width: 100%;

    border-top: 1px solid voca.$telia-gray-300;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

@mixin justifyWhenVerticalTo($size) {
  @include gaia.to($size) {
    align-self: unset;
    text-align: justify;
  }
}

.definitionList {
  word-break: break-word;

  .item:not(:first-child) {
    padding-top: voca.$telia-spacing-4;
  }

  .item:not(:last-child) {
    padding-bottom: voca.$telia-spacing-4;
  }

  &_withDividers {
    .item:not(:first-child) {
      padding-top: voca.$telia-spacing-8;
    }

    .item:not(:last-child) {
      padding-bottom: voca.$telia-spacing-8;
      border-bottom: 1px solid voca.$telia-gray-200;
    }
  }

  &_withLeadingDivider {
    padding-top: voca.$telia-spacing-8;
    border-top: 1px solid voca.$telia-gray-200;
  }

  &_withTrailingDivider {
    padding-bottom: voca.$telia-spacing-8;
    border-bottom: 1px solid voca.$telia-gray-200;
  }
}

.item {
  &_baseline {
    display: flex;
  }

  &_baseline > * {
    margin-top: auto;
  }

  &_alignRight {
    display: inherit;
    flex-direction: column;
  }

  &_alignRight > * {
    align-self: end;
    text-align: right;
  }

  &_verticalToXxxs > * {
    @include justifyWhenVerticalTo('xxxs');
  }

  &_verticalToXxs > * {
    @include justifyWhenVerticalTo('xxs');
  }

  &_verticalToXs > * {
    @include justifyWhenVerticalTo('xs');
  }

  &_verticalToSm > * {
    @include justifyWhenVerticalTo('sm');
  }

  &_verticalToMd > * {
    @include justifyWhenVerticalTo('md');
  }

  &_verticalToLg > * {
    @include justifyWhenVerticalTo('lg');
  }

  &_verticalToXlg > * {
    @include justifyWhenVerticalTo('xlg');
  }
}

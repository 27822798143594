@use '/src/sass/foundations/voca';

.mobileNavigationItem {
  &_border {
    border-bottom: 1px solid voca.$telia-gray-300;
  }

  &_menuItem:hover,
  &_menuItem:focus-visible {
    color: voca.$telia-purple-500;
  }

  &_menuItem:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
  }
}

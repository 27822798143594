@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.hero {
  color: voca.$telia-white;
  text-align: left;
  padding-top: voca.$telia-spacing-16;
  padding-bottom: voca.$telia-spacing-48;
  margin-bottom: 0;
  min-height: 120px;

  &_backgroundImage {
    background-color: voca.$telia-purple-850;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &_content {
    @include gaia.from('sm') {
      min-height: 75px;
    }
  }

  &_heading {
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    color: voca.$telia-white !important;

    @include gaia.to('md') {
      margin-bottom: 2rem;
    }
  }

  &_askForHelpField,
  &_activeCompanySelector {
    @include gaia.from('md') {
      max-width: 50rem;
    }
  }
}

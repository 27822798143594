.heading__price {
  $multiplier: var(--voca-rem-multiplier);
  font-family: TeliaSans, Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: bold;
  font-size: calc(2.4rem * #{$multiplier});
  line-height: 1.25;

  @media (min-width: 37.5rem) and (max-width: 90rem) {
    font-size: calc((2.4rem * #{$multiplier}) + 0.8 * (100vw - calc(60rem * #{$multiplier})) / 84);
  }

  @media (min-width: 90rem) {
    font-size: calc(3.2rem * #{$multiplier});
  }
}

@use 'sass:math';
@use '/src/sass/foundations/gaia';

$_maxWidthForUsername: gaia.$gaia-breakpoint-lg-min +
  math.div(gaia.$gaia-breakpoint-lg-max - gaia.$gaia-breakpoint-lg-min, 2);

.userMenu {
  width: 100%;
  min-width: max-content;

  @media (min-width: gaia.$gaia-breakpoint-xs-min) and (max-width: gaia.$gaia-breakpoint-sm-max) {
    padding: 0 gaia.$gaia-padding-md 0 gaia.$gaia-padding-md;
  }

  &_nameAndCompany {
    @media (min-width: gaia.$gaia-breakpoint-xs-min) and (max-width: gaia.$gaia-breakpoint-md-max) {
      display: none;
    }

    @media (min-width: $_maxWidthForUsername) {
      display: none;
    }
  }

  &_nameAndCompany_name {
    font-weight: bold;
  }

  &_nameAndCompany_company {
    font-weight: normal;
  }

  &_nameAndCompany_name,
  &_nameAndCompany_company {
    max-width: calc(100vw - 4rem);
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    @include gaia.from('sm') {
      max-width: 30rem;
    }
  }

  &_link,
  &_link span {
    display: block;

    &:focus {
      outline-width: 0px;
    }

    &:focus-visible {
      outline-width: 3px;
    }
  }
}

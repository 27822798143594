.container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100vh;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

.footer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

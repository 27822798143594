@use '/src/sass/foundations/voca';

.row {
  display: block;
  & > span {
    display: block;
  }

  &:hover {
    background-color: voca.$telia-gray-100;
    color: voca.$telia-purple-500;
  }

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 0;
  }
}

.icon {
  color: voca.$telia-purple-500;
}

.icon_invisible {
  color: voca.$telia-functional-transparent;
}

.invisibleIcon {
  color: transparent;
}

@keyframes pulse {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.ratingButton {
  padding: 1rem;
  border-radius: 2.2rem;
  overflow: visible;

  &_active {
    background-color: var(--purpur-color-purple-100);
    color: var(--purpur-color-purple-700);
    animation: 0.6s ease forwards pulse;
  }
}

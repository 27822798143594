@use '/src/sass/foundations/gaia';

$_items: 24;

.dashboardItems {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: gaia.$gaia-spacing-layout-4;
  row-gap: calc(gaia.$gaia-spacing-layout-1 / 2);

  .column {
    width: 50%;
  }

  .dashboardItem {
    @for $item from 0 through $_items {
      &_index_#{$item} {
        order: $item;
      }
    }
  }

  @include gaia.to('sm') {
    flex-direction: column;

    .column {
      display: contents;
      width: 100%;
    }
  }
}

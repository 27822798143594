@use '/src/sass/foundations/voca';

.lineBreak {
  height: 0;
  width: 100%;
}

.link {
  padding-right: voca.$telia-spacing-24;

  & > a,
  & > [aria-disabled] {
    display: inline;
    margin-right: voca.$telia-spacing-8;
  }
}

.tooltip {
  display: inline-block;
  margin-top: voca.$telia-spacing-8;
  color: voca.$telia-blue-600;
  vertical-align: bottom;

  & :focus-visible {
    border-radius: voca.$telia-border-radius-16;
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 1px;
  }
}

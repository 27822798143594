@use '/src/sass/foundations/voca';

.productCard {
  & > div {
    width: 100%;
    height: 100%;
  }

  & > span:first-of-type {
    height: 100%;
  }

  a:global(.telia-react-productCard) {
    height: 100%;

    &:focus-visible article {
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: 2px;
    }

    &:focus:after {
      border: 0;
    }

    & > span {
      height: 100%;
    }
  }

  :global(.telia-react-productCard_title) {
    height: auto;
  }

  :global(.telia-react-productCard_description) {
    display: none;
  }

  :global(.telia-react-cardPrice_priceValue) {
    font-size: 18px;
    margin-bottom: 6px;
  }

  :global(.telia-react-productCard_panel) {
    height: 100%;
    transition: outline-width voca.$telia-duration-50;
  }

  footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  footer > span > span:last-child {
    color: voca.$telia-gray-600;
  }
}

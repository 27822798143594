@use '/src/sass/foundations/voca';

.entry {
  margin-top: voca.$telia-spacing-8;

  &_csvInput {
    label,
    label ~ :global(.telia-react-gutter) {
      display: none;
    }
  }

  &_button {
    position: relative;
    top: voca.$telia-spacing-12;
  }
}

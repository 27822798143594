@use '/src/sass/foundations/voca';

.answer {
  &_paragraph {
    margin-bottom: voca.$telia-spacing-8;
    text-rendering: optimizeLegibility;
    line-height: 1.5;
    &:first-child {
      margin-top: 0;
    }
  }

  & a:hover {
    color: voca.$telia-purple-700;
    background-color: voca.$telia-purple-100;
  }

  & a:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 1px;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

/**
 * When a breadcrumb is explicitly hidden by a route, the rest of the page
 * still expects the breadcrumb to be there and renders a xlg-sized gutter
 * between breadcrumb and page content. Hence, counter the excessive
 * xlg-sized gutter with equal negative space between the top navigation
 * and page content.
 */
.absentBreadcrumb {
  margin-bottom: calc(-1 * voca.$telia-spacing-24);

  @include gaia.from('sm') {
    margin-bottom: calc(-1 * voca.$telia-spacing-32);
  }

  @include gaia.from('md') {
    margin-bottom: calc(-1 * voca.$telia-spacing-48);
  }
}

.breadcrumbContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: calc(-1 * voca.$telia-spacing-12);

  & a:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;

    span,
    div {
      color: voca.$telia-black;
      fill: voca.$telia-black;
    }
  }

  & a:hover span,
  & a:hover div {
    background-color: voca.$telia-purple-100;
    color: voca.$telia-purple-700;
    fill: voca.$telia-purple-700;
  }
}

@use '/src/sass/foundations/purpur';

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: purpur.$purpur-breakpoint-md) {
    flex-direction: column;
  }
}

.reply_now_btn {
  @media screen and (max-width: purpur.$purpur-breakpoint-md) {
    width: 100%;
  }
}

.sorter {
  @media screen and (max-width: purpur.$purpur-breakpoint-md) {
    margin-top: purpur.$purpur-spacing-200;
  }
}

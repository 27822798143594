@use '/src/sass/foundations/voca' as *;
@use '../mixin' as *;

@mixin pileVerticallyTo() {
  .pileVerticallyTo {
    &_sm {
      @include telia-max-breakpoint('md') {
        display: block;
        flex: none;
      }
    }

    &_md {
      @include telia-max-breakpoint('lg') {
        display: block;
        flex: none;
      }
    }

    &_lg {
      @include telia-max-breakpoint('xl') {
        display: block;
        flex: none;
      }
    }

    &_xl {
      @include telia-breakpoint('xl') {
        display: block;
        flex: none;
      }
    }
  }
}

@use '/src/sass/foundations/gaia';

.card {
  position: relative;
  border: 1px solid var(--purpur-color-border-weak);
  border-radius: var(--purpur-border-radius-md);
  // Using custom shadow since Purpur's `lg` is barely visible
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.03);
  padding: 20px 24px;

  @include gaia.to('xs') {
    border-radius: 0;
  }

  &_badge {
    pointer-events: none;
    margin-left: var(--purpur-spacing-200);
    float: right;
    clear: after;
  }

  &_title {
    color: var(--purpur-color-black);
    &__unseen {
      color: var(--purpur-color-purple-600);
    }
  }

  &_image {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 400px;
    margin-top: 16px;

    @include gaia.to('sm') {
      max-height: 200px;
    }
  }

  &_clampedText {
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &_ctaContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &:not(:has(a)) {
      justify-content: flex-end;
    }

    @include gaia.to('xxs') {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem;
    }
  }

  @for $i from 1 through 10 {
    &:nth-child(10n + #{$i}) {
      --card-index: #{$i - 1};
    }
  }

  &_ctaButton {
    @include gaia.to('xxs') {
      width: 100%;
    }
  }

  &_expandButton {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
    margin-bottom: -0.8rem;
  }

  &__slideIn {
    opacity: 0;
    transform: translateY(-20px);
    animation: 0.6s ease forwards slideIn;
    animation-delay: calc(var(--card-index, 0) * 0.2s);

    @keyframes slideIn {
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

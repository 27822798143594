@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.articleSearchBar {
  --search-icon-pointer-events: unset;
  --search-icon-color: #{voca.$telia-gray-600};
  --search-icon-color-hover: #{voca.$telia-purple-500};

  padding: 0 voca.$telia-spacing-12;
  margin: auto voca.$telia-spacing-8 auto voca.$telia-spacing-12;

  @include gaia.to('sm') {
    margin-left: voca.$telia-spacing-4;
  }

  @include gaia.to('xs') {
    margin-left: voca.$telia-spacing-8;
    margin-right: voca.$telia-spacing-8;
  }

  &.expanded {
    --search-icon-pointer-events: none;
    --search-icon-color: #{voca.$telia-gray-600};
    --search-icon-color-hover: #{voca.$telia-gray-600};
    --search-border-color: #{voca.$telia-gray-400};
    --search-border-shadow: transparent;

    max-width: 50rem;
    background-color: voca.$telia-white;
    border: 1px solid var(--search-border-color);
    border-radius: voca.$telia-spacing-24;
    box-shadow: inset 0 0 0 1px var(--search-border-shadow);

    @include gaia.to('xs') {
      max-width: 100%;
    }

    &:hover {
      --search-icon-color: #{voca.$telia-gray-600};
      --search-icon-color-hover: #{voca.$telia-gray-600};
      --search-border-color: #{voca.$telia-gray-600};
      --search-border-shadow: var(--search-border-color);
    }

    &:focus-within {
      --search-icon-color: #{voca.$telia-purple-500};
      --search-icon-color-hover: #{voca.$telia-purple-500};
      --search-border-color: #{voca.$telia-purple-500};
      --search-border-shadow: var(--search-border-color);
    }
  }
}

.articleSearchIcon {
  color: var(--search-icon-color);
  pointer-events: var(--search-icon-pointer-events);

  &:hover {
    color: var(--search-icon-color-hover);
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
  }
}

.articleSearchCloseButton {
  color: voca.$telia-gray-600;
}

.articleSearchField {
  & > :global(.telia-react-input__container) {
    box-shadow: none !important;
    height: 4.2rem;
    margin-top: 3px;
    margin-bottom: 3px;

    & > :global(.telia-react-input__iconWrapper) {
      display: none;
    }

    & > input {
      padding-right: 16px;
    }
  }
}

.articleSearchResults {
  background-color: voca.$telia-white;
  padding: voca.$telia-spacing-24 voca.$telia-spacing-32;
  margin-top: voca.$telia-spacing-12;
  border-radius: voca.$telia-border-radius-16;
  box-shadow: 0 voca.$telia-spacing-2 voca.$telia-spacing-8 0 rgba(58, 58, 58, 0.12);

  position: absolute;
  min-width: calc(min(80rem, 100vw));
  max-width: calc(min(80rem, 100vw));
  top: -9999px;
  left: -9999px;
  word-wrap: break-word;

  &_dashboard {
    z-index: 1;
    margin-top: voca.$telia-spacing-8;
    min-width: calc(min(78rem, 100vw));
    max-width: calc(min(78rem, 100vw));
    box-shadow: 0 voca.$telia-spacing-2 voca.$telia-spacing-8 0 rgba(58, 58, 58, 0.33);
  }

  @include gaia.to('md') {
    margin-top: voca.$telia-spacing-8;
    margin-left: voca.$telia-spacing-8;
    margin-right: voca.$telia-spacing-8;
    min-width: auto;
    width: calc(100% - 16px);
    padding: voca.$telia-spacing-24;
  }

  .suggestionsIcon {
    color: voca.$telia-gray-400;
    margin-top: voca.$telia-spacing-2;
    margin-right: voca.$telia-spacing-4;
  }

  .suggestions {
    display: block;

    & > li {
      display: inline-block;
    }

    & .suggestion {
      all: unset;
      display: inline-block;
      padding: 0 voca.$telia-spacing-8;
      color: voca.$telia-gray-600;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        color: voca.$telia-purple-500;
      }

      &:focus {
        outline: 3px solid voca.$telia-blue-500;
        outline-width: 2px;
      }
    }
  }

  .sectionTitle {
    margin-bottom: voca.$telia-spacing-8;
  }

  .articleSearchResultItem {
    margin: voca.$telia-spacing-4 0;

    &:hover * {
      background-color: voca.$telia-purple-100;
      color: voca.$telia-purple-700;
    }

    & .chevron {
      display: inline-block;
      color: voca.$telia-purple-500;
      margin-top: 6px;
      margin-right: voca.$telia-spacing-2;
    }

    &__disabled *,
    &__disabled:hover * {
      background-color: unset;
      color: voca.$telia-gray-400;
      text-decoration: none;
    }
  }
}

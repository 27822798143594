@use '/src/sass/foundations/voca';

.breadcrumbItem {
  color: voca.$telia-functional-black;
  padding: 4px 0;
  vertical-align: text-bottom;
  display: flex;
  gap: 8px;

  &_navigable {
    color: voca.$telia-gray-600;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.settingsIcon {
  &_topTiles {
    height: 2.4rem;
  }

  &_otherServices {
    margin-left: voca.$telia-spacing-16;
    height: 4rem;
    width: 4rem;
    text-align: center;
  }
}

.dashboard {
  &_topTilesContainer {
    width: 100%;
  }

  &_container {
    width: 100%;

    @include gaia.to('xs') {
      margin-top: calc(-1 * voca.$telia-spacing-24);
      margin-bottom: voca.$telia-spacing-32;
    }

    @include gaia.from('sm') {
      margin-top: calc(-1 * voca.$telia-spacing-24);
      margin-bottom: voca.$telia-spacing-32;
    }

    @include gaia.from('md') {
      margin-top: calc(-1 * voca.$telia-spacing-24);
      margin-bottom: voca.$telia-spacing-48;
    }
  }

  &_otherServicesContainer {
    width: 100%;

    @include gaia.to('md') {
      margin-bottom: voca.$telia-spacing-32;
    }

    @include gaia.from('md') {
      margin-bottom: voca.$telia-spacing-48;
    }
  }
}

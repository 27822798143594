@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  @include gaia.to('md') {
    padding: 20px 20px 20px 24px;
  }

  &__highlighted {
    box-shadow: 6px 0 0 0 voca.$telia-purple-500 inset;
  }
}

// Allow multi-line text for wide views to partially overflow to
// padding area (72 px), so up to 2 lines of such text would not
// enlargen the list view row and unnecessarily require extra
// vertical scrolling from the end-user.
//
// Particularly useful for list views with a lot of columns where any
// column can contain lengthy text, like case subjects, and midpoint
// views between mobile and desktop where horizontal space is limited.
.contentInner {
  @include gaia.from('lg') {
    min-height: 40px;
  }
}

.icon {
  @include gaia.to('md') {
    margin-left: 40px;
  }

  @include gaia.to('sm') {
    margin-left: 32px;
  }

  @include gaia.to('xs') {
    margin-left: 24px;
  }

  &__quicklinkSpacing {
    width: 32px;
    margin-top: -4px;
    margin-bottom: -4px;
  }
}

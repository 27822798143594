@use '/src/sass/foundations/voca';

.searchField {
  & input {
    padding-right: calc(1.6rem + 24px);
  }

  & label {
    color: voca.$telia-white;
    font-weight: 500;
  }
}

.searchIcon {
  position: relative;
  top: 1.4rem;
  margin-left: calc(-16px - 1.6rem);

  &_disabled {
    pointer-events: none;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_typographyBaseline: gaia.$typographyBaseline;
$_borderWidth: 0.75 * $_typographyBaseline;

.subnavigation {
  overflow-y: hidden;
  overflow-x: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;

  border-top: 1px solid voca.$telia-gray-200;

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -3px;
  }

  &_link {
    padding: 2.75 * $_typographyBaseline 0 2.75 * $_typographyBaseline - $_borderWidth;

    border-bottom: $_borderWidth solid transparent;

    &__active {
      color: voca.$telia-black;
      border-bottom: $_borderWidth solid voca.$telia-purple-500;
    }

    &__active:hover {
      color: voca.$telia-purple-500;
    }

    & > a,
    & > a span {
      display: block;
    }
  }

  &_icon {
    border-bottom: $_borderWidth solid transparent;

    &__active {
      color: voca.$telia-black;
      border-bottom: $_borderWidth solid voca.$telia-purple-500;
    }

    &__active:hover {
      color: voca.$telia-purple-500;
    }
  }
}

.subnavigation::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

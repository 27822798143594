@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_borderRadius: gaia.$xs;

.menu {
  position: absolute;
  min-width: calc(min(100%, 300px));
  max-width: calc(max(300px, min(75vw, 480px)));
  top: -9999px;
  left: -9999px;
  z-index: gaia.z('dropdown');
  background-color: voca.$telia-white;
  border: 1px solid voca.$telia-gray-200;
  box-shadow: 0 2px 8px 0 rgba(58, 58, 58, 0.12);
  border-radius: $_borderRadius;
  overflow: hidden;
}

.menuItem {
  background-color: voca.$telia-white;
  color: voca.$telia-black;
  cursor: pointer;
  padding: gaia.$xs gaia.$sm gaia.$xs gaia.$sm;
  border-bottom: 1px solid voca.$telia-gray-200;

  &:first-child {
    border-top-left-radius: $_borderRadius;
    border-top-right-radius: $_borderRadius;
  }

  &:last-child {
    border-bottom-left-radius: $_borderRadius;
    border-bottom-right-radius: $_borderRadius;
    border-bottom: 0px;
  }

  &:hover {
    background-color: voca.$telia-gray-50;
    color: voca.$telia-purple-500;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -3px;
  }
}

.menuItem_disabled {
  background-color: voca.$telia-white;
  color: voca.$telia-gray-400;
  cursor: not-allowed;

  &:hover {
    background-color: voca.$telia-white;
    color: voca.$telia-gray-400;
  }

  &:focus-visible {
    outline-width: 0px;
  }
}

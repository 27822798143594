@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_typographyBaseline: gaia.$typographyBaseline;
$_lightColor: voca.$telia-gray-100;
$_darkColor: voca.$telia-gray-300;

@keyframes pulse {
  0% {
    background-color: $_lightColor;
  }
  50% {
    background-color: $_darkColor;
  }
  100% {
    background-color: $_lightColor;
  }
}

@mixin pulsating-color() {
  animation: pulse 2s ease-in-out infinite;
}

.skeleton {
  &__box {
    @include pulsating-color();
  }

  &__accordion {
    @include gaia.to('xs') {
      border-radius: 0;
    }
  }

  &__panel {
    @include gaia.to('xs') {
      border-radius: 0;
    }
  }

  &__input_field {
    @include gaia.to('md') {
      height: 10 * $_typographyBaseline;
      margin-bottom: 3 * $_typographyBaseline;
    }

    @include gaia.from('md') {
      height: 12 * $_typographyBaseline;
      margin-bottom: 4 * $_typographyBaseline;
    }
  }

  &__text_cell {
    @include gaia.to('md') {
      height: 4.5 * $_typographyBaseline;
      margin-bottom: 3px;
    }

    @include gaia.from('md') {
      height: 5.5 * $_typographyBaseline;
      margin-bottom: 3px;
    }
  }

  &__text_row {
    @include gaia.to('md') {
      height: 5 * $_typographyBaseline;
      margin-bottom: 1px;
    }

    @include gaia.from('md') {
      height: 7 * $_typographyBaseline;
      margin-bottom: 2px;
    }
  }

  &__heading {
    @include gaia.to('sm') {
      height: 5 * $_typographyBaseline;
      margin-bottom: 1px;
    }

    @include gaia.from('sm') {
      height: 7 * $_typographyBaseline;
      margin-bottom: 1px;
    }

    @include gaia.from('md') {
      height: 8 * $_typographyBaseline;
      margin-bottom: 2px;
    }
  }

  &__button {
    @include gaia.to('md') {
      height: 9 * $_typographyBaseline;
      margin-bottom: 1px;
    }

    @include gaia.from('md') {
      height: 9 * $_typographyBaseline;
      margin-bottom: 2px;
    }
  }
}

.block {
  display: block;
  & > span {
    display: block;
  }
}

.inline {
  display: inline-block;
  & > span {
    // Component library's link renders a span with {display: block},
    // which inconveniently breaks focus outlines in Chrome.
    display: inline;
  }
}

.disabled {
  cursor: not-allowed;
}

@use '/src/sass/foundations/voca';

@mixin spacing($size, $spacing, $sides...) {
  @each $side in $sides {
    #{$spacing}-#{$side}: $size;
  }
}

@mixin allSpacings($size, $spacing) {
  &_size {
    @include spacing($size, $spacing, 'top', 'bottom', 'left', 'right');
  }

  &_vertical {
    @include spacing($size, $spacing, 'top', 'bottom');
  }

  &_horizontal {
    @include spacing($size, $spacing, 'left', 'right');
  }

  &_left {
    @include spacing($size, $spacing, 'left');
  }

  &_right {
    @include spacing($size, $spacing, 'right');
  }

  &_top {
    @include spacing($size, $spacing, 'top');
  }

  &_bottom {
    @include spacing($size, $spacing, 'bottom');
  }
}

@mixin padding() {
  .padding {
    &_zero {
      @include allSpacings(voca.$telia-spacing-0, 'padding');
    }

    &_xxxs {
      @include allSpacings(voca.$telia-spacing-2, 'padding');
    }

    &_xxs {
      @include allSpacings(voca.$telia-spacing-4, 'padding');
    }

    &_xs {
      @include allSpacings(voca.$telia-spacing-8, 'padding');
    }

    &_sm {
      @include allSpacings(voca.$telia-spacing-16, 'padding');
    }

    &_md {
      @include allSpacings(voca.$telia-spacing-24, 'padding');
    }

    &_lg {
      @include allSpacings(voca.$telia-spacing-32, 'padding');
    }

    &_xlg {
      @include allSpacings(voca.$telia-spacing-48, 'padding');
    }

    &_xxlg {
      @include allSpacings(voca.$telia-spacing-96, 'padding');
    }
  }
}

@mixin margin() {
  .margin {
    &_zero {
      @include allSpacings(voca.$telia-spacing-0, 'margin');
    }

    &_xxxs {
      @include allSpacings(voca.$telia-spacing-2, 'margin');
    }

    &_xxs {
      @include allSpacings(voca.$telia-spacing-4, 'margin');
    }

    &_xs {
      @include allSpacings(voca.$telia-spacing-8, 'margin');
    }

    &_sm {
      @include allSpacings(voca.$telia-spacing-16, 'margin');
    }

    &_md {
      @include allSpacings(voca.$telia-spacing-24, 'margin');
    }

    &_lg {
      @include allSpacings(voca.$telia-spacing-32, 'margin');
    }

    &_xlg {
      @include allSpacings(voca.$telia-spacing-48, 'margin');
    }

    &_xxlg {
      @include allSpacings(voca.$telia-spacing-96, 'margin');
    }
  }
}

@use 'sass:math';
@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_colorBorder: voca.$telia-gray-300;
$_spacing: 10px;
$_spacingHalf: math.div($_spacing, 2);
$_spacingHalfWithoutBorder: $_spacingHalf - 1px;

@mixin right-border {
  &::before {
    content: '';
    position: absolute;
    inline-size: 1px;
    block-size: calc(100% - $_spacing);
    inset-inline-start: calc(100% + $_spacingHalfWithoutBorder);
    inset-block-start: $_spacingHalf;
    border-right: 1px solid $_colorBorder;
  }
}

@mixin bottom-border {
  &::after {
    content: '';
    position: absolute;
    inline-size: calc(100% - $_spacing);
    block-size: 1px;
    inset-inline-start: $_spacingHalf;
    inset-block-start: calc(100% + $_spacingHalfWithoutBorder);
    border-bottom: 1px solid $_colorBorder;
  }
}

.grid {
  --col-count: 4;
  display: grid;
  grid-template-columns: repeat(var(--col-count), 1fr);
  grid-gap: $_spacing;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;

  .gridItem {
    position: relative;
    padding: $_spacingHalf;
    @include right-border;
    @include bottom-border;
  }

  .gridItemSkeleton {
    height: 100%;
    width: 100%;
    position: relative;
  }

  @include gaia.from('md') {
    &_desk1cols {
      --col-count: 1;
    }
    &_desk2cols {
      --col-count: 2;
    }
    &_desk3cols {
      --col-count: 3;
    }
    &_desk4cols {
      --col-count: 4;
    }
  }

  @include gaia.to('sm') {
    &_mob1cols {
      --col-count: 1;
    }
    &_mob2cols {
      --col-count: 2;
    }
    &_mob3cols {
      --col-count: 3;
    }
    &_mob4cols {
      --col-count: 4;
    }
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_zIndexBelowModalsForBackdrop: gaia.z('overlay') - 2;
$_zIndexBelowModalsForDialog: gaia.z('overlay') - 1;

.modalDialogBackdrop {
  z-index: $_zIndexBelowModalsForBackdrop;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  cursor: pointer;
  @include gaia.to('sm') {
    background-color: rgba(0, 0, 0, 0.23);
  }
}

.modalDialog {
  @include gaia.to('sm') {
    z-index: $_zIndexBelowModalsForBackdrop;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    height: 100vh;
    width: 100vw;
  }

  &_panel {
    z-index: $_zIndexBelowModalsForDialog;
    margin: calc(max(40px, min(80px, 10vh)));
    max-height: calc(100% - calc(2 * max(40px, min(80px, 10vh))));
    overflow-y: visible;

    @include gaia.from('md') {
      margin: 0;
      position: absolute;
      top: -9999px;
      left: -9999px;
      background-color: transparent;
    }

    @include gaia.to('sm') {
      position: relative;
      top: 0px !important;
      left: 0px !important;
    }
  }

  &_panelFlex {
    @include gaia.from('md') {
      display: block;
    }
  }

  &_hiddenFocus {
    width: 0px;
    height: 0px;
    outline: none;
  }

  &_container {
    min-width: min(calc(100vw - 32px), 35rem);
    max-width: min(calc(100vw - 32px), 800px);
    right: auto;
    max-height: none;
    position: relative;
    margin: auto;
    background-color: voca.$telia-white;

    &_content {
      max-height: none;
      max-width: 800px;
      margin: auto;
    }
  }

  label {
    font-weight: 500;
  }

  @include gaia.to('sm') {
    &_title {
      font-size: 16px;
    }

    &_panel {
      margin: 24px;
      height: calc(100% - calc(2 * 24px));
    }

    &_container {
      width: 100%;
    }
  }

  @include gaia.to('xxs') {
    &_panel {
      margin: 16px;
      height: calc(100% - calc(2 * 16px));
    }
  }
}

@use '/src/sass/foundations/voca';

.label {
  padding-top: voca.$telia-spacing-12;
  padding-bottom: voca.$telia-spacing-4;
}

.links {
  margin-bottom: 0;
}

@use '/src/sass/foundations/voca';

.feedbackContainer {
  position: relative;

  &:focus-within {
    .sendButton {
      &:not(:disabled) {
        color: var(--purpur-color-purple-600);
      }
    }
  }
}

.feedbackInput {
  input {
    padding-right: 48px;
  }
}

.sendButton {
  position: absolute;
  width: 40px;
  height: 48px;
  bottom: 0;
  right: 4px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--purpur-color-purple-500);
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -3px;
  }
}

@use '/src/sass/foundations/voca';

.bankRadioButton {
  display: block;
  padding-top: voca.$telia-spacing-2;
  padding-bottom: voca.$telia-spacing-2;

  & > div > div:last-child {
    width: 100%;
  }

  &:has(:focus-visible) {
    outline: 2px solid voca.$telia-blue-500;
    outline-offset: 3px;
    :global(.telia-react-radioButton_fakeButton)::before {
      border: unset;
      outline: unset;
    }
  }

  &:hover:not(:global(.telia-react-radioButton_fakeButton)) {
    background-color: voca.$telia-purple-100;
    color: voca.$telia-purple-700;
  }
}

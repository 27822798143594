@use '/src/sass/foundations/voca';

.standaloneLink {
  :focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -2px;
  }

  a:focus {
    outline: none;
  }

  a:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -2px;
  }

  .link_background:hover {
    color: voca.$telia-purple-500;
  }
}

.standaloneLink [aria-disabled] {
  cursor: not-allowed;

  .link_background {
    color: unset;
  }

  .link_icon {
    color: unset;
  }
}

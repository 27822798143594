@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.requiredFieldsLabel {
  color: voca.$telia-blue-600;
  text-align: right;

  @include gaia.to('xs') {
    margin-bottom: voca.$telia-spacing-16;
    text-align: left;
  }

  &_float {
    position: absolute;
    right: 0;

    @include gaia.to('xs') {
      position: relative;
      right: unset;
    }
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.mobileNetworkQualityItem {
  max-width: 12rem;
  margin: 0 gaia.$gaia-margin-xs;

  @include gaia.to('xs') {
    margin: 0 gaia.$gaia-margin-xxs;
  }

  @include gaia.to('xxs') {
    margin: 0 gaia.$gaia-margin-xxxs;
  }
}

.circularMeter {
  border-radius: 50%;

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.newsNotification {
  background: voca.$telia-white;
  text-align: initial !important;

  &_textWrapper {
    padding: gaia.$gaia-padding-sm;

    @include gaia.to('sm') {
      padding: gaia.$gaia-padding-xs;
    }
  }

  &_text {
    display: -webkit-box;
    line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include gaia.to('sm') {
      line-clamp: 3;
    }
  }

  &_enter {
    opacity: 0;
  }

  &_enterActive {
    opacity: 1;
    transition: all 500ms ease-out;
  }

  &_exit {
    opacity: 1;
  }

  &_exitActive {
    opacity: 0;
    transition: all 500ms ease-out;
  }
}

.card_container {
  width: 100%;
  margin-top: var(--purpur-spacing-400);

  &_chat {
    width: 90%;
  }
}

.card_customer_container {
  align-self: flex-end;
}

.card {
  border-radius: var(--purpur-spacing-200);
  box-shadow: none;
}

.card_customer {
  background-color: var(--purpur-color-gray-100);
  border-top-right-radius: 0;
}

.card_agent {
  background-color: var(--purpur-color-purple-100);
  border-top-left-radius: 0;
}

.meta {
  font-size: var(--purpur-typography-scale-75);
  color: var(--purpur-color-gray-700);
  margin-bottom: var(--purpur-spacing-50);
}

.agentIcon {
  width: 20px;
  margin-right: var(--purpur-spacing-50);
}

// Support indents for SR/WO/TT rich text comments
.message {
  &_container {
    width: 100%;
    container-type: inline-size;
    container-name: message-content;
  }

  &_richText {
    --indent-size: 1.5em;
    @container message-content (max-width: 360px) {
      --indent-size: 1em;
    }

    @for $i from 1 through 9 {
      :global(.ql-indent-#{$i}) {
        padding-left: calc(var(--indent-size) * #{$i});
      }
    }
  }
}

@use '/src/sass/foundations/voca';

.tooltipContainer {
  display: flex;
}

.tooltip {
  all: unset;
  display: inline-block;
  position: relative;
  --size: 20px;

  border-radius: 50%;
  color: voca.$telia-blue-600;
  cursor: pointer;
  vertical-align: middle;

  height: var(--size);
  min-width: var(--size);
  width: var(--size);

  &_sizeSm {
    --size: 20px;
  }

  &_sizeMd {
    --size: 24px;
  }

  &_sizeLg {
    --size: 28px;
  }

  &_spacingXs {
    margin-left: voca.$telia-spacing-4;
  }

  &_spacingSm {
    margin-left: voca.$telia-spacing-8;
  }

  &_spacingMd {
    margin-left: voca.$telia-spacing-16;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 1px;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_iconSize: gaia.$gaia-spacing-layout-4;
$_iconMargin: gaia.$gaia-spacing-layout-3;
$_iconSpace: $_iconSize + $_iconMargin;

.blockHeader {
  --block-header-color: #{voca.$telia-black};
  all: unset;
  color: var(--block-header-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;

  h4,
  h5 {
    color: var(--block-header-color);
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -2px;
  }

  &:hover {
    --block-header-color: #{voca.$telia-purple-500};
  }
}

.blockTitleChevron {
  color: var(--block-header-color);
  transform: rotate(0deg);
  transition: transform voca.$telia-duration-150 voca.$telia-ease-in-out;

  &__rotate {
    transform: rotate(-180deg);
  }
}

.blockWithIcon_icon {
  color: var(--block-header-color);
  width: $_iconSize;
  height: $_iconSize;
  margin-right: $_iconMargin;
}

.blockWithIcon_expanded {
  margin-left: $_iconSpace;
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.errorPage {
  position: relative;
  padding-top: voca.$telia-spacing-32;
  padding-bottom: voca.$telia-spacing-80;

  @include gaia.to('md') {
    padding-bottom: voca.$telia-spacing-64;
  }

  @include gaia.to('xs') {
    padding-top: voca.$telia-spacing-24;
  }
}

.errorRow {
  margin-top: voca.$telia-spacing-48;

  @include gaia.to('sm') {
    margin-top: voca.$telia-spacing-32;
  }
}

.container {
  align-items: initial;
  justify-content: center;

  @include gaia.to('sm') {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  /**
   * Only on tall screens in desktop mode, position the error container in the
   * middle of the error page based on the error image size. This accommodates
   * error page variants with or without breadcrumb and active company dropdown,
   * which would then no longer affect the positioning of the error container.
   */
  @include gaia.from('md') {
    @media (min-height: 840px) {
      position: absolute;
      top: calc(max(160px, calc(50% - 8px * 12)));
      left: 0;
      width: 100%;
      height: 8px * 24;
    }
  }
}

.image {
  max-width: 8px * 24;

  @include gaia.to('xs') {
    max-width: 8px * 16;
  }
}

.content {
  width: 100%;
  max-width: 42rem;

  @include gaia.to('md') {
    margin-top: voca.$telia-spacing-24;
  }

  @include gaia.from('md') {
    margin-left: voca.$telia-spacing-24;
  }
}

.contentText {
  margin-top: 24px;

  @include gaia.to('sm') {
    margin-top: voca.$telia-spacing-16;
  }

  /**
   * Use the DOM context for contained links' font size, so links
   * would be uniform in font size with the rest of the content text
   * in mobile and desktop views.
   */
  a,
  a span {
    font-size: inherit;
  }
}

@use '/src/sass/foundations/gaia';

.ratingContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include gaia.to('xxs') {
    margin-left: auto;
    margin-right: auto;
    gap: 1rem;
  }
}

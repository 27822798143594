@use '/src/sass/foundations/voca';

.container {
  position: relative;
}

.notification {
  // Address conflicting styling for close button in DS
  & > div:first-child > button {
    position: absolute;
  }

  // Support for custom icons
  &__customIcon > div:first-child > div:first-child svg {
    visibility: hidden;
  }

  // Titleless variant is not implemented in Purpur
  &__titleless > div:last-child {
    margin-top: -3.4rem;
    position: relative;
    padding-left: var(--purpur-spacing-400);
    padding-right: voca.$telia-spacing-32;
  }
}

/*
 * Support for custom icons by drawing over the existing icon
 * (design requirement for offhour call and new user tour notices
 * ever since 2023).
 */
.customIcon {
  $iconSize: 2rem;
  position: absolute;
  top: calc(var(--purpur-spacing-200) + 0.3rem);
  left: calc(var(--purpur-spacing-200) + 1.2rem);
  width: $iconSize !important;
  height: $iconSize !important;
}

a[aria-disabled] {
  cursor: not-allowed;
  color: var(--purpur-color-gray-400);
  &:hover {
    background-color: unset;
    color: var(--purpur-color-gray-400);
  }
}

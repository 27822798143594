@use '/src/sass/foundations/voca';

.items {
  list-style: none;
  padding: 0;
  margin: 0;

  li:not(:last-child) {
    border-bottom: 1px solid voca.$telia-gray-200;
  }
}

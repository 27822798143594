@use '/src/sass/foundations/voca' as *;
@use '../mixin' as *;

@mixin responsiveHidden() {
  .thinnerThan {
    &_md {
      @include telia-max-breakpoint('md') {
        display: none !important;
      }
    }

    &_lg {
      @include telia-max-breakpoint('lg') {
        display: none !important;
      }
    }

    &_xl {
      @include telia-max-breakpoint('xl') {
        display: none !important;
      }
    }
  }

  .widerThan {
    &_md {
      @include telia-breakpoint('md') {
        display: none !important;
      }
    }

    &_lg {
      @include telia-breakpoint('lg') {
        display: none !important;
      }
    }

    &_xl {
      @include telia-breakpoint('xl') {
        display: none !important;
      }
    }
  }
}

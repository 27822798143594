@use '/src/sass/foundations/voca';

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: voca.$telia-spacing-4 0;
  vertical-align: top;

  &__link:hover {
    background-color: voca.$telia-purple-100;
  }

  &__error {
    cursor: not-allowed;
  }
}

.count {
  display: flex;
  font-weight: 500;
  font-size: 1.6rem;
  border-radius: 1.4rem;
  min-width: 4.2rem;
  height: 3.2rem;
  padding: 0 voca.$telia-spacing-4;
  align-items: center;
  justify-content: center;
  margin-right: voca.$telia-spacing-16;

  &__link {
    background-color: voca.$telia-purple-700;
    color: voca.$telia-white;
  }

  &__error {
    border: 1px solid voca.$telia-gray-500;
    font-weight: 500;
    color: voca.$telia-gray-600;
  }
}

.errorIcon {
  height: 20px;
  width: 20px;
}

.label {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;

  &__link {
    color: voca.$telia-purple-700;
  }

  &__error {
    color: voca.$telia-gray-500;
  }
}

.tooltipWrapper {
  height: 18px;
  width: 18px;
  vertical-align: text-bottom;
  margin-left: voca.$telia-spacing-4;
}

@use '/src/sass/foundations/voca';

.link {
  width: 100%;

  a {
    display: block;
    outline-offset: -3px;
  }

  .link_background:hover {
    background-color: voca.$telia-purple-100;
    color: voca.$telia-purple-700;
  }

  .link_icon {
    color: voca.$telia-purple-500;
  }
}

.link__disabled {
  cursor: not-allowed;

  .link_background:hover {
    background-color: unset;
    color: unset;
  }

  .link_icon {
    color: unset;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.button {
  position: relative;
  padding: var(--purpur-spacing-200) 0;
  width: 50%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  align-items: center;
  border: none;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  font-size: var(--var-purpur-typography-scale-100);
  cursor: pointer;

  &:hover {
    background-color: var(--purpur-color-purple-50);
    color: var(--purpur-color-purple-700);
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: -3px;
  }

  &_active {
    color: var(--purpur-color-purple-500);
    border-bottom-color: var(--purpur-color-purple-500);
  }

  @include gaia.to('sm') {
    font-size: var(--purpur-typography-scale-75);
  }
}

.label {
  font-weight: 500;
  margin-top: var(--purpur-spacing-100);
  hyphens: auto;
}

$size: 32px;

.loader {
  display: block;
  min-height: $size;
  min-width: $size;
  height: $size;
  width: $size;
  max-height: $size;
  max-width: $size;
}

.showMoreButton {
  min-height: $size;
  padding: 4px 0 !important;
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';
.button {
  @include gaia.to('xxs') {
    margin-bottom: voca.$telia-spacing-16;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include gaia.from('xs') {
    margin-right: voca.$telia-spacing-16;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

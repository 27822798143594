.titleAbsent {
  color: var(--purpur-color-gray-400);
}

.titleError {
  color: var(--purpur-color-red-500);
}

.subtitle {
  color: var(--purpur-color-gray-500) !important;
  margin-top: var(--purpur-spacing-50) !important;
}

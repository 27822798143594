@use '/src/sass/foundations/voca';

.newsBell {
  &_container {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: calc(voca.$telia-spacing-12 + voca.$telia-spacing-8);
  }

  &_bell {
    position: relative;
  }

  &_bubble {
    position: absolute;
    top: -0.8rem;
    left: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    background-color: var(--purpur-color-red-500);
    color: voca.$telia-white;

    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    pointer-events: none;

    &__large {
      top: -1.2rem;
      width: 2rem;
      height: 2rem;
    }

    &__xlarge {
      top: -1.4rem;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

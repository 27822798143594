@use '/src/sass/foundations/voca';

.item {
  --label-color: #{voca.$telia-black};
  --label-color-hover: #{voca.$telia-purple-500};
  width: 100%;

  & > label {
    width: 100%;
  }

  & > label > :global(.telia-react-element) {
    display: flex;
    flex-direction: row-reverse;
  }

  & > label span {
    color: var(--label-color);
  }

  & > label > :global(.telia-react-element) > :last-child {
    flex-grow: 1;
    &:hover span {
      color: var(--label-color-hover);
    }
  }
}

.item__disabled {
  --label-color: #{voca.$telia-gray-500};
  --label-color-hover: #{voca.$telia-gray-500};

  & > label {
    cursor: inherit !important;
  }

  // Keyboard users should still be able to focus a sortable checkbox
  // item to be able to move it around, so we must imitate a disabled
  // checkbox if an item is required or cannot otherwise be toggled.
  & :global(.telia-react-checkbox_fakeCheckbox) {
    cursor: not-allowed;
    background-color: voca.$telia-gray-100 !important;
    border-color: voca.$telia-gray-200 !important;
    border-width: 1px !important;
    svg {
      color: voca.$telia-gray-500 !important;
    }
  }
}

.dragHandle {
  cursor: move;
  &.isDragging {
    opacity: 0.3;
  }

  &:has(:focus-visible) {
    outline: 2px solid voca.$telia-blue-500;
    outline-offset: 2px;
    :global(.telia-react-checkbox_fakeCheckbox):focus-visible::before {
      border: unset;
    }
  }
}

.dragIcon {
  // D&D goblins galore: there's no "drag" icon in Voca or Purpur at
  // time of writing required in Figma designs, so clip the closest
  // icon "services" to match our needs.
  clip-path: polygon(0 0, 66% 0, 66% 100%, 0 100%);
  color: voca.$telia-gray-400;
  margin-right: voca.$telia-spacing-12;
}

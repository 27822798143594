@use '/src/sass/foundations/gaia';

.pageRow {
  margin-bottom: 0;
  padding-bottom: gaia.$gaia-padding-sm;
}

.filterWrapper {
  // matching filterChip height to prevent height flickering when toggling filters
  min-height: 40px;
}

@use '/src/sass/foundations/voca';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: calc(voca.$telia-spacing-12 + voca.$telia-spacing-8);
}

.icon {
  svg {
    animation: shake voca.$telia-duration-500 voca.$telia-ease-out 0ms;
    animation-iteration-count: 0;
    transform-origin: 50% 15%;
  }

  &__open svg {
    animation-iteration-count: 1;
    animation-direction: normal;
  }

  &__close svg {
    animation-iteration-count: 1;
    animation-direction: reverse;
  }

  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.menuAnchor {
  width: 100%;
}

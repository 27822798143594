@use '/src/sass/foundations/voca';

.copyButton {
  --color-basic: #{voca.$telia-purple-500};
  --color-hover: #{voca.$telia-purple-400};
  --cursor: pointer;

  color: var(--color-basic);
  margin-top: voca.$telia-spacing-4;
  margin-bottom: voca.$telia-spacing-4;

  &:hover,
  &:hover svg {
    color: var(--color-hover);
    cursor: var(--cursor);
  }

  &__copied {
    --color-basic: #{voca.$telia-green-600};
    --color-hover: #{voca.$telia-green-600};
    --cursor: default;
  }
}

.copiedLabel {
  color: voca.$telia-green-600;
  font-weight: 500;
  margin-top: voca.$telia-spacing-4;
  margin-left: voca.$telia-spacing-4;
  vertical-align: text-bottom;
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.fixedBar {
  position: relative;
  box-shadow: none;
  margin-bottom: 0;
  border-bottom: 1px solid voca.$telia-white;
  z-index: gaia.z('header');
}

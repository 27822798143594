@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

/*
 * At the time of writing, Voca doesn't support a date range picker adhering to
 * our needs, and the unmaintained Gaia component library's date range picker
 * and its calendar need heavy adjustments to cater to actual UI/UX needs. Hence,
 * there be dragons below and necessary hacks galore.
 */
.dateFilter {
  display: block;
  position: relative;

  :global {
    .telia-react-datePickerWithRange_inputContainer {
      background: voca.$telia-white;

      @include gaia.from('md') {
        max-width: 30rem;
      }
    }

    .telia-react-datePickerWithRange_fromDatePicker {
      @include gaia.to('sm') {
        width: calc(50% - 24px);
      }

      .telia-react-input__container {
        min-width: 110px;
        width: 100%;
      }

      .telia-react-input__inputField {
        padding-right: 10px;
      }
    }

    .telia-react-datePickerWithRange_toDatePicker {
      @include gaia.to('sm') {
        width: calc(50% + 8px);
      }

      .telia-react-input__container {
        min-width: 134px;
        width: 100%;
      }
    }

    // Gaia DS from date datepicker width fix for narrow views
    .telia-react-datePickerWithRange_overlayWrapper {
      position: relative;
    }

    // Gaia DS day picker spacing fix for very narrow mobile views.
    // Removes second month from calendar (as agreed with designers) when
    // there is no space for horizontal month layout.
    .DayPicker-Month:last-child {
      display: none;
    }

    // Gaia DS day picker spacing fix for very narrow mobile views
    .DayPicker-Day {
      @include gaia.to('xxxs') {
        padding: gaia.$gaia-padding-xxs;
      }
    }
  }
}

.dateFilter__spacious {
  :global {
    @include gaia.from('md') {
      // Gaia DS to date datepicker positioning fix
      .telia-react-datePickerWithRange_overlayWrapper {
        position: absolute;
        left: 0;
      }

      // Gaia DS to date datepicker positioning fix
      .telia-react-datepicker__overlay {
        transform: translate3d(0, 0, 0);
      }

      // Enable horizontal month layout for Gaia DS in narrow modals/dialog windows
      .DayPicker-Months {
        flex-wrap: unset;
      }

      .DayPicker-Month:last-child {
        display: table;
      }
    }
  }
}

@use '/src/sass/foundations/gaia';

.accordion {
  --accordion-border-radius: 8px;
  border: unset;
  border-radius: var(--accordion-border-radius);

  & :global(.telia-react-collapsibleBlock_header) {
    border-radius: var(--accordion-border-radius);
    &:focus-visible {
      outline-offset: -3px;
    }
  }

  @include gaia.to('xs') {
    --accordion-border-radius: 0;
  }
}

@use '/src/sass/foundations/voca';

.footer {
  background-color: voca.$telia-purple-850;
}

.fixThoseBlockElementsInsideInlineElements {
  a > span {
    // Component library's footer renders a > span > p for footer links, i.e.
    // a {display: block} element inside a {display: inline} element, breaking
    // bounding box calculations in Chrome for our inconvenience, and breaking
    // any chance for E2E tests to try to click said footer links.
    display: inline-block;
  }

  a:focus {
    outline-width: 0px;
  }

  a:focus-visible {
    outline-width: 3px;
  }
}

@use '/src/sass/foundations/voca';

.wrapper {
  --link-wrapper-display: block;
  --link-content-display: unset;
  --link-outline-offset: 0;
  --link-button-width: 100%;
  --content-color: #{voca.$telia-black};
  --content-color-hover-fg: #{voca.$telia-purple-700};
  --content-color-hover-bg: #{voca.$telia-purple-100};
  --content-font-size: inherit;
  --content-font-weight: inherit;
  --content-vertical-padding: 0;

  display: var(--link-wrapper-display);
  text-justify: none;

  :where(button.link) {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: inline;
    width: var(--link-button-width);
  }

  .link {
    display: var(--link-content-display, inherit);
    outline-offset: var(--link-outline-offset, #{voca.$telia-spacing-2});
    text-decoration: none;
  }

  .linkContent {
    display: var(--link-content-display);
  }

  &__text {
    --link-wrapper-display: inline;
    --link-outline-offset: 0;
    --link-button-width: inherit;
    --content-font-size: inherit;
    --content-font-weight: inherit;
    --content-vertical-padding: 0;
    padding: 0;
  }

  &__standalone {
    --link-content-display: inline;
    --link-button-width: unset;
    --content-color: #{voca.$telia-purple-600};
    --content-vertical-padding: #{voca.$telia-spacing-4};
    padding: voca.$telia-spacing-4 0;
  }

  &__standaloneActionMenu {
    --link-content-display: inline;
    --link-button-width: inherit;
    --content-vertical-padding: #{voca.$telia-spacing-4};
    padding: voca.$telia-spacing-8 0;
  }

  &__standaloneContentSearch {
    --link-content-display: block;
    --link-outline-offset: 0;
    --content-color: #{voca.$telia-gray-800};
    --content-vertical-padding: #{voca.$telia-spacing-2};
    padding: 0 0 0 #{voca.$telia-spacing-8};
    width: 100%;
  }

  &__standaloneDownload {
    --link-content-display: block;
    --content-color: #{voca.$telia-purple-600};
    --content-vertical-padding: #{voca.$telia-spacing-8};
    padding: voca.$telia-spacing-4 0;
    width: 100%;
  }

  &__standaloneSpacious {
    --link-content-display: block;
    --content-color: #{voca.$telia-purple-600};
    --content-vertical-padding: #{voca.$telia-spacing-8};
    padding: voca.$telia-spacing-8 0;
  }

  &__standaloneTopBar {
    --link-wrapper-display: inline-block;
    --link-outline-offset: #{voca.$telia-spacing-2};
    --content-color: #{voca.$telia-gray-600};
    --content-color-hover-fg: #{voca.$telia-gray-800};
    --content-color-hover-bg: transparent;
    --content-font-size: 11px;
    --content-font-weight: 500;
    --content-letter-spacing: 0.1rem;
    --content-vertical-padding: #{voca.$telia-spacing-4};
    --content-text-decoration: none;
    --content-outline-offset: 3;
    padding: voca.$telia-spacing-4 0;
    margin: voca.$telia-spacing-2 0;
    white-space: nowrap;
    .link {
      border-radius: 2px;
      padding-bottom: voca.$telia-spacing-4;
    }
    .linkContent {
      border-radius: 3px;
      border-bottom: 0.2rem solid transparent;
    }
  }

  &__standaloneTopBarActive {
    --link-wrapper-display: inline-block;
    --link-outline-offset: #{voca.$telia-spacing-2};
    --content-color: #{voca.$telia-gray-800};
    --content-color-hover-fg: #{voca.$telia-gray-800};
    --content-color-hover-bg: transparent;
    --content-font-size: 11px;
    --content-font-weight: 500;
    --content-letter-spacing: 0.1rem;
    --content-vertical-padding: #{voca.$telia-spacing-4};
    --content-text-decoration: none;
    --content-outline-offset: 3;
    padding: voca.$telia-spacing-4 0;
    margin: voca.$telia-spacing-2 0;
    white-space: nowrap;
    .link {
      border-radius: 2px;
      padding-bottom: voca.$telia-spacing-4;
    }
    .linkContent {
      border-bottom: 0.2rem solid voca.$telia-purple-500;
    }
  }
}

.link {
  display: inherit;
  outline: 0 solid voca.$telia-blue-500;
  transition: outline-width voca.$telia-duration-50, outline-offset voca.$telia-duration-50;

  &:focus-visible {
    outline-width: 3px;
  }
}

.linkContent {
  display: inherit;
  cursor: pointer;
  color: var(--content-color, #{voca.$telia-black});
  font-size: var(--content-font-size, 16px);
  font-weight: var(--content-font-weight, normal);
  letter-spacing: var(--content-letter-spacing);
  line-height: 1.5;
  padding: var(--content-vertical-padding) 0;
  outline-offset: var(--content-outline-offset);
  container-type: inline-size;
  container-name: link-content;

  &:hover {
    color: var(--content-color-hover-fg);
    background-color: var(--content-color-hover-bg);
  }

  &__disabled,
  &__disabled:hover {
    cursor: not-allowed;
    color: voca.$telia-gray-500;
    background-color: unset;
  }

  &__loading,
  &__loading:hover {
    cursor: not-allowed;
    color: voca.$telia-gray-500;
    background-color: unset;
    text-decoration: none;
  }

  &_iconLeft {
    display: inline-block;
    line-height: 1;
    margin-right: voca.$telia-spacing-8;
    vertical-align: text-bottom;
  }

  &_label {
    display: inline;
    overflow-wrap: normal;
    text-decoration: var(--content-text-decoration, underline);
  }

  &_auxiliary {
    display: block;
    color: voca.$telia-gray-600;
    text-decoration: none;
    font-size: 12px;
    padding-top: voca.$telia-spacing-4;

    @container link-content (min-width: 480px) {
      display: inline;
      float: right;
      text-align: right;
      margin-left: voca.$telia-spacing-8;
      max-width: 50%;
    }
  }

  &_iconRight {
    display: inline-block;
    line-height: 1;
    margin-left: voca.$telia-spacing-4;
    vertical-align: text-bottom;
    width: 0;

    &[data-icon-name='external'] {
      color: voca.$telia-gray-500;
      margin-left: voca.$telia-spacing-8;
    }
  }

  &__disabled &_iconRight[data-icon-name='external'] {
    color: voca.$telia-gray-400;
  }

  &_loader {
    margin-left: voca.$telia-spacing-12;
    vertical-align: middle;
    width: 2.4rem;
  }
}

.tooltip {
  display: inline-block;
  margin-left: voca.$telia-spacing-8;
}

$colorPopup: #{var(--purpur-color-background-tone-on-tone-primary)};
$colorText: #{var(--purpur-white)};

.tooltip {
  &_anchor {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
  }

  &_popup {
    position: absolute;
    cursor: text;
    max-width: calc(max(min(90vw, 30rem), min(40vw, 40rem)));
    box-sizing: border-box;
    border-radius: var(--purpur-border-radius-md);
    padding: var(--purpur-spacing-150);
    user-select: text;
    animation-duration: 0;
    background-color: $colorPopup;
    color: $colorText !important;
    z-index: 1000;
  }

  &_arrow {
    position: absolute;
    width: 0;
    height: 0;
    transform: rotate(0deg);

    &__topStart {
      // Don't keep the arrow pointing exactly its height away from the dialog.
      // Otherwise, a zoomed-in browser view may render an empty row of pixels
      // between the arrow and dialog due to rounding.
      bottom: -11px;
      left: 20px;
      border-width: 12px;
      border-style: solid;
      border-color: $colorPopup transparent transparent transparent;
      border-bottom: none;
    }

    &__topCenter {
      bottom: -11px;
      left: calc(50% - 12px);
      border-width: 12px;
      border-style: solid;
      border-color: $colorPopup transparent transparent transparent;
      border-bottom: none;
    }

    &__topEnd {
      bottom: -11px;
      right: 20px;
      border-width: 12px;
      border-style: solid;
      border-color: $colorPopup transparent transparent transparent;
      border-bottom: none;
    }

    &__bottomStart {
      top: -11px;
      left: 20px;
      border-width: 12px;
      border-style: solid;
      border-color: transparent transparent $colorPopup transparent;
      border-top: none;
    }

    &__bottomCenter {
      top: -11px;
      left: calc(50% - 12px);
      border-width: 12px;
      border-style: solid;
      border-color: transparent transparent $colorPopup transparent;
      border-top: none;
    }

    &__bottomEnd {
      top: -11px;
      right: 20px;
      border-width: 12px;
      border-style: solid;
      border-color: transparent transparent $colorPopup transparent;
      border-top: none;
    }
  }
}

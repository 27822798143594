@use '/src/sass/foundations/gaia';

$_bgWidth: 489px;
$_bgWidthSm: $_bgWidth * 0.6;
$_bgXMargin: 8 * gaia.$baseline;
$_bgYMargin: -4 * gaia.$baseline;

.content {
  height: 100%;
  background-size: initial;
  background-repeat: no-repeat;
  background-position-x: calc(100vw - #{$_bgWidth} - #{$_bgXMargin});
  background-position-y: $_bgYMargin;

  @include gaia.to('sm') {
    background-size: 60%;
    background-position-x: calc(100vw - #{$_bgWidthSm} - #{$_bgXMargin});
    background-position-y: $_bgYMargin;
  }

  @include gaia.to('xs') {
    background: none !important;
  }

  &__verticallyCentered {
    @include gaia.from('md') {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }
}

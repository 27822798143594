@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_hoverColor: voca.$telia-gray-50;
$_activeColor: voca.$telia-gray-100;

.tileButton {
  height: 100%;
  width: 100%;
  position: relative;

  a {
    display: block;
  }
}

.link {
  height: 100%;
  width: 100%;
  border-radius: voca.$telia-border-radius-2;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 0;
  }

  &:hover {
    background-color: $_hoverColor;
  }

  &:active {
    background-color: $_activeColor;
  }

  .icon {
    color: voca.$telia-black;
    margin-top: voca.$telia-spacing-8;
    margin-bottom: voca.$telia-spacing-4;
  }

  .textContainer {
    min-height: 2.5em;
  }

  .text {
    color: voca.$telia-black;
    font-weight: 500;
    text-align: center;
    margin: 0;

    /* Only use hyphenation in narrow mobile views */
    @include gaia.to('xxxs') {
      hyphens: auto;
    }

    @include gaia.to('xs') {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    @include gaia.from('xs') {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  .textLastWord {
    white-space: nowrap;
  }

  .textLinkExternalIcon {
    margin-left: 0.3rem;
    vertical-align: text-bottom;
    height: calc(1.5rem * var(--voca-rem-multiplier));
    min-width: calc(1.4rem * var(--voca-rem-multiplier));
    width: calc(1.4rem * var(--voca-rem-multiplier));
  }
}

.link[disabled],
.link[aria-disabled] {
  cursor: not-allowed;

  &:focus-visible {
    outline: none;
    outline-offset: 0;
  }

  &:hover {
    background-color: unset;
  }

  &:active {
    background-color: unset;
  }

  .icon {
    color: voca.$telia-gray-400;
  }

  .text {
    color: voca.$telia-gray-400;
  }

  .textExternalLinkIcon {
    color: voca.$telia-gray-400;
  }
}

.tooltipOverlay {
  display: block;
  position: absolute;
  top: 0;
  right: voca.$telia-spacing-4;
  color: voca.$telia-blue-500;

  & :focus-visible {
    border-radius: voca.$telia-border-radius-16;
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 1px;
  }
}

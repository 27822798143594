@use '/src/sass/foundations/gaia';

.drawer_buttons {
  position: relative;
  height: auto;
  border-top: 1px solid var(--purpur-color-transparent-black-150);
  padding: var(--purpur-spacing-400) var(--purpur-spacing-600);

  @include gaia.to('sm') {
    padding: var(--purpur-spacing-300);
  }

  @include gaia.to('xxs') {
    padding: var(--purpur-spacing-200) var(--purpur-spacing-300);
  }
}

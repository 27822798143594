@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.systemNotification {
  position: relative;
  box-shadow: none;
  margin-bottom: 0;
  border-bottom: 1px solid voca.$telia-white;
  overflow-y: hidden;
  z-index: gaia.z('header');

  &_enter {
    max-height: 0;
  }

  &_enterActive {
    max-height: 80px;
    transition: all 500ms ease-out;
  }

  &_exit {
    max-height: 80px;
  }

  &_exitActive {
    max-height: 0;
    transition: all 500ms ease-out;
  }
}

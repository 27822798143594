@use '/src/sass/foundations/voca';

.inlineHtmlContainer a {
  text-decoration: underline;
  color: voca.$telia-black;

  &:hover {
    color: voca.$telia-purple-700;
    background-color: voca.$telia-purple-100;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 1px;
  }
}

.inlineHtmlContainer {
  & p {
    padding: 0;
    margin: 0.75em 0;
  }

  & ol,
  & ul {
    padding-left: 1.5em;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

@keyframes fadeTextIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loadable {
  position: relative;
}

.loaderOverlay {
  z-index: gaia.z('mask');
  position: absolute;
  height: 100%;
  width: 100%;
  background: voca.$telia-white;
  transition: opacity 0.25s ease;

  &_inactive {
    opacity: 0;
    pointer-events: none;
  }

  &_active {
    opacity: 0.9;
    pointer-events: auto;
  }
}

.loaderContainer {
  z-index: gaia.z('mask');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: calc(100% - 2 * gaia.$gaia-padding-xs);
}

.textContainer {
  position: relative;
  display: block;
  cursor: default;

  opacity: 0;
  animation: fadeTextIn 200ms linear;
  animation-delay: 1500ms;
  animation-fill-mode: forwards;
}

.textContainerContent {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  margin-top: gaia.$gaia-margin-xs;
  width: 100%;
  text-align: center;

  background-color: #{rgba(voca.$telia-white, 0.8)};
  box-shadow: 0 0 16px voca.$telia-white;
  border-radius: 999px;
  padding: gaia.$gaia-padding-xs;
}

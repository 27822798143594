@use '/src/sass/foundations/voca';

.notification {
  max-height: 0;
  margin-bottom: 0;
  overflow: hidden;
  transition: max-height 0.8s ease, margin-bottom 0.2s ease;

  &_hidden {
    visibility: hidden;
  }

  &_visible {
    max-height: 32rem;
    margin-bottom: voca.$telia-spacing-16;
    &:empty {
      display: none;
    }
  }
}

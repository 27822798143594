@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.buttonLink {
  &:focus-visible {
    outline: none;
    & .button {
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: 2px;
    }
  }

  &_icon {
    margin-left: voca.$telia-spacing-2;
  }
}

.button {
  display: inline-block;
  @include gaia.to('xxs') {
    width: 100%;
  }
}

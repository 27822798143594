@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.navigation {
  background-color: voca.$telia-white;

  &_menuBar {
    height: max(72px, 7.2rem);
    @include gaia.to('md') {
      height: max(60px, 6rem);
    }
  }

  &_logoLinkContainer {
    padding-right: voca.$telia-spacing-8;
  }

  &_logoLink {
    display: block;
    border-radius: 4px;
    color: voca.$telia-purple-500;
    padding: voca.$telia-spacing-4 voca.$telia-spacing-4 voca.$telia-spacing-4 0;
    margin: voca.$telia-spacing-4 voca.$telia-spacing-8 voca.$telia-spacing-4 0;

    &:hover {
      color: voca.$telia-purple-400;
    }

    &:focus-visible {
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: 1px;
    }

    @include gaia.to('md') {
      margin-right: 0;
    }

    @include gaia.to('xxxs') {
      padding-right: 0;
      margin-right: -52px;
      width: 100%;
      svg {
        clip-path: polygon(0 0, 32px 0, 32px 100%, 0 100%);
      }
    }
  }

  &_hamburgerMenu {
    margin-left: 20px;
  }

  &_mobileContainer {
    position: absolute;

    width: 100vw;
    left: 0;
  }
}

.navigation::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

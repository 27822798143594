@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.searchfield {
  @include gaia.from('md') {
    min-width: 32rem;
  }

  :global {
    .telia-react-dropdown {
      width: 100%;
      max-width: 25rem;

      @include gaia.to('xxs') {
        max-width: unset;
      }
    }

    .telia-react-optionlist_item:focus-visible {
      box-shadow: none;
      outline: 3px solid voca.$telia-blue-500;
      outline-offset: -3px;
    }
  }
}

.label {
  display: block;
  padding-bottom: 8px;
  font-size: 1.2rem;
  line-height: 1.5em;
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.itemsCount {
  background-color: voca.$telia-purple-700;
  border-radius: voca.$telia-border-radius-16;
  color: voca.$telia-white;
  font-weight: bold;
  padding: voca.$telia-spacing-8 voca.$telia-spacing-16;
  margin-right: voca.$telia-spacing-8;
}

.searchBar {
  max-width: 50%;
  margin: 0 auto;

  @include gaia.to('lg') {
    max-width: 100%;
  }
}

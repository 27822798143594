@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$_zIndexBelowModalsForDialog: gaia.z('overlay') - 1;

.moreActionsDialogWrapper {
  z-index: $_zIndexBelowModalsForDialog;
  position: absolute;
}

.moreActionsDialog {
  border: voca.$telia-border-width-1 solid voca.$telia-gray-300;
  border-radius: voca.$telia-border-radius-8;
  background-color: voca.$telia-white;
  padding: voca.$telia-spacing-8 voca.$telia-spacing-16;
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.modalDialog {
  z-index: gaia.z('overlay');
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.23);
  height: 100vh;
  width: 100vw;

  &_panel {
    position: relative;
    overflow-y: visible;
  }

  &_panelFlex {
    padding: calc(max(40px, min(80px, 10vh, 10vw)));
    min-height: 100vh;
  }

  &_hiddenFocus {
    width: 0px;
    height: 0px;
    outline: none;
  }

  &_container {
    max-width: 800px;
    background-color: voca.$telia-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 19vh;

    &_content {
      max-height: none;
      margin: auto;
    }

    &_narrow {
      max-width: 600px;
    }
  }

  @include gaia.to('sm') {
    &_title {
      font-size: 16px;
    }

    &_panelFlex {
      padding: 40px;
    }

    &_container {
      width: 100%;
    }
  }

  @include gaia.to('xs') {
    &_panelFlex {
      padding: 24px;
    }
  }

  @include gaia.to('xxs') {
    &_panelFlex {
      padding: 16px;
    }
  }
}

@use 'sass:map';
@use '/src/sass/foundations/voca';

@mixin telia-max-breakpoint($name) {
  @if map.has-key(voca.$breakpoints, $name) {
    $breakpoint: map.get(voca.$breakpoints, $name);

    @media screen and (max-width: calc(#{$breakpoint} - 1px)) {
      @content;
    }
  } @else {
    @error 'Unknown breakpoint name "#{$name}" in mediaquery';
  }
}

@use 'sass:math';
@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

$buttonSize: gaia.$gaia-margin-md;

.backToTop {
  position: fixed;
  display: block;
  z-index: gaia.z('sticky');
  background-color: voca.$telia-purple-500;
  box-shadow: 0 gaia.$gaia-spacing-component-1 gaia.$gaia-spacing-component-3 0
    rgba(58, 58, 58, 0.4);
  text-transform: uppercase;

  &_hidden {
    display: none;
  }

  @include gaia.to('md') {
    bottom: 3 * gaia.$baseline;
    right: 3 * gaia.$baseline;
  }

  @include gaia.from('md') {
    bottom: 5 * gaia.$baseline;
    right: 2%;
  }

  @include gaia.from('xxlg') {
    right: unset;
    left: 85vw;
  }

  @include gaia.to('xs') {
    bottom: 1 * gaia.$baseline;
    left: calc(50% - $buttonSize);
    right: unset;
  }

  &:focus-visible {
    outline: 3px solid voca.$telia-blue-500;
    outline-offset: 2px;
    transition: outline-offset 0s;
    &::before {
      content: unset;
    }
  }

  &:hover {
    background-color: voca.$telia-purple-600;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.dataItem {
  display: block;
  margin-bottom: voca.$telia-spacing-4;
  width: 100%;

  &_label {
    display: inline;
    margin-right: voca.$telia-spacing-16;
    color: var(--row-fg-color-label);
    font-size: 1.4rem;
    font-weight: 500;

    @include gaia.to('xxxs') {
      display: block;
    }
  }

  &_value {
    display: inline;
    color: var(--row-fg-color-value);

    @include gaia.to('xxxs') {
      display: block;
    }
  }
}

.noData {
  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_icon {
    $size: 100px;
    color: var(--purpur-color-gray-300);
    min-width: $size;
    min-height: $size;
    width: $size;
    height: $size;
    max-width: $size;
    max-height: $size;
    transform: scaleX(-1);
  }

  &_label {
    font-weight: bold;
    font-size: 2.4rem;
    text-align: center;
    color: var(--purpur-color-gray-400);
  }
}

.block > button {
  &:focus-visible {
    outline-offset: 0px;
  }

  & > div {
    padding-left: 0;
    padding-right: 0;
  }
}

@use '/src/sass/foundations/gaia';
@use '/src/sass/foundations/voca';

.dataPanel {
  &__fullWidth {
    @include gaia.to('xs') {
      border-radius: 0;
    }
  }

  &__fullHeight {
    min-height: 100vh;
  }
}

.dataPanelImage {
  float: right;
  max-width: 100px;
  max-height: 150px;
  margin-left: voca.$telia-spacing-16;
  margin-bottom: voca.$telia-spacing-16;

  @include gaia.to('xxs') {
    display: none;
  }
}

.subtitle {
  margin-top: voca.$telia-spacing-8;
}
